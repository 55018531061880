import { LegendPosition } from '@swimlane/ngx-charts';
export class ChartConfigModel {
  view?: number[];
  legend: boolean;
  showLabels: boolean;
  animations: boolean;
  xAxis: boolean;
  yAxis: boolean;
  timeline: boolean;
  legendTitle: boolean;
  legendPosition: string;
  yScaleMax: number;
  yScaleMin: number;
  colorScheme: any;

  /**
   * Constructor
   *
   * @param data
   */
  constructor(data: ChartConfigModel) {
    this.view = data.view;
    this.legend = data.legend;
    this.showLabels = data.showLabels;
    this.animations = data.animations;
    this.xAxis = data.xAxis;
    this.yAxis = data.yAxis;
    this.timeline = data.timeline;
    this.legendTitle = data.legendTitle;
    this.legendPosition = data.legendPosition;
    this.yScaleMax = data.yScaleMax;
    this.colorScheme = data.colorScheme;
  }
}

export class ChartGaugeConfigModel {
  view?: number[];
  legend: boolean;
  legendTitle: boolean;
  legendPosition: string;
  colorScheme: any;
  angleSpan: number;
  startAngle: number;
  showAxis: boolean;
  max: number;
  min: number;
  tooltipDisabled: boolean;
  showText: boolean;

  /**
   * Constructor
   *
   * @param data
   */
  constructor(data: ChartGaugeConfigModel) {
    this.view = data.view;
    this.legend = data.legend;
    this.legendTitle = data.legendTitle;
    this.legendPosition = data.legendPosition;
    this.colorScheme = data.colorScheme;
    this.angleSpan = data.angleSpan;
    this.startAngle = data.startAngle;
    this.showAxis = data.showAxis;
    this.max = data.max;
    this.min = data.min;
    this.tooltipDisabled = data.tooltipDisabled;
    this.showText = data.showText;
  }
}

export class ChartPieConfigModel {
  view?: number[];
  legend: boolean;
  legendTitle: string;
  legendPosition: LegendPosition;
  colorScheme: any;
  gradient: boolean;
  isDoughnut: boolean;
  showLabels: boolean;

  /**
   * Constructor
   *
   * @param data
   */
  constructor(data: ChartPieConfigModel) {
    this.view = data.view;
    this.legend = data.legend;
    this.legendTitle = data.legendTitle;
    this.legendPosition = data.legendPosition;
    this.colorScheme = data.colorScheme;
    this.gradient = data.gradient;
    this.isDoughnut = data.isDoughnut;
    this.showLabels = data.showLabels;
  }
}

export class SeriesChartModel {
  name: string;
  series: SeriesModel[];

  /**
   * Constructor
   *
   * @param data
   */
  constructor(data: SeriesChartModel) {
    this.name = data.name;
    this.series = data.series;
  }
}

export class SeriesModel {
  name: Date | string | number;
  value: string | number;

  /**
   * Constructor
   *
   * @param data
   */
  constructor(data: SeriesModel) {
    this.name = data.name;
    this.value = data.value;
  }
}

import { Injectable } from '@angular/core';
import { ILocale } from './ilocale';

@Injectable()
export class LanguageService {
  private _supportedLanguages: ILocale[] = [];

  public get supportedLanguages(): ILocale[] {
    return this._supportedLanguages;
  }
  public set supportedLanguages(value: ILocale[]) {
    this._supportedLanguages = value;
  }

  private _currentLanguage: ILocale;

  public get currentLanguage(): ILocale {
    return this._currentLanguage;
  }
  public set currentLanguage(value: ILocale) {
    this._currentLanguage = value;
  }
}

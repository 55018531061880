import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { InTakeFormSectionModel } from '@common/co/feature/in-take/models/in-take-form-section.model';
import { environment } from '@common/co/environment';
import { InTakeService } from '@common/co/auth/services/in-take.service';

@Component({
  selector: 'in-take-flow',
  templateUrl: './in-take-flow.component.html',
  styleUrls: ['./in-take-flow.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InTakeFlowComponent implements OnInit {
  @Input()
  sections: InTakeFormSectionModel[];

  environment: any;

  constructor(public _inTakeService: InTakeService) {
    this.environment = environment;
  }

  ngOnInit(): void {
    //TODO: Add Some code here ot remove
  }
}

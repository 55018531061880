<div class="title-section">
  <h1 class="text-centered">
    {{ 'REGISTER.WAIVER_OF_LIABILITY' | translate }}    
  </h1>
  <div class="text-centered waiver-scroll-content"
       style="max-height: 400px"
       fusePerfectScrollbar
       [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
       [innerHTML]="waiverOfLiability">
  </div>
</div>

<form [formGroup]="registerForm">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'COMMON.PERSONAL_INFO.NAME' | translate }}</mat-label>
    <input matInput [formControl]="registerForm.controls['waiverOfLiabilitySignerName']" />
    <mat-error *ngIf="registerForm.get('waiverOfLiabilitySignerName').invalid && (registerForm.get('waiverOfLiabilitySignerName').dirty || registerForm.get('waiverOfLiabilitySignerName').touched) && registerForm.get('waiverOfLiabilitySignerName').hasError('required')">
      {{ 'COMMON.PERSONAL_INFO.NAME_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="registerForm.get('waiverOfLiabilitySignerName').invalid && (registerForm.get('waiverOfLiabilitySignerName').dirty || registerForm.get('waiverOfLiabilitySignerName').touched) &&
                !registerForm.get('waiverOfLiabilitySignerName').hasError('required') &&
                registerForm.get('waiverOfLiabilitySignerName').hasError('waiverOfLiabilitySignerName')
              ">
      {{ 'COMMON.PERSONAL_INFO.NAME_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  
  <mat-form-field appearance="outline">
    <mat-label>{{ 'REGISTER.DATE' | translate }}</mat-label>
    <input matInput [formControl]="registerForm.controls['waiverOfLiabilitySignedAt']" [matDatepicker]="datePicker"
           [value]="currentDate" [readonly]="true" [disabled]="true" />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    <mat-error *ngIf="registerForm.get('waiverOfLiabilitySignedAt').invalid && (registerForm.get('waiverOfLiabilitySignedAt').dirty || registerForm.get('waiverOfLiabilitySignedAt').touched) && registerForm.get('waiverOfLiabilitySignedAt').hasError('required')">
      {{ 'REGISTER.DATE_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="registerForm.get('waiverOfLiabilitySignedAt').invalid && (registerForm.get('waiverOfLiabilitySignedAt').dirty || registerForm.get('waiverOfLiabilitySignedAt').touched) &&
                !registerForm.get('waiverOfLiabilitySignedAt').hasError('required') &&
                registerForm.get('waiverOfLiabilitySignedAt').hasError('waiverOfLiabilitySignedAt')
              ">
      {{ 'REGISTER.DATE_INVALID' | translate }}
    </mat-error>
  </mat-form-field>
</form>

<button mat-button class="spl-button center mt-16 mb-16"
        (click)="next()"
        [disabled]="!completed || process" 
        [attr.aria-label]="'REGISTER.I_AGREE' | translate" 
        [matTooltip]="'REGISTER.I_AGREE' | translate"
        >
        {{ 'REGISTER.I_AGREE' | translate }}
</button>

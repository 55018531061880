<div mat-dialog-content
     class="p-16 pb-0 m-0 in-take-section"
     [ngClass]="{'isMobile': isMobile, 'isInTakePassed': isInTakePassed}">
  <span class="title">
    {{section.name}}
  </span>
  <span class="sub-title">
    {{section.text}}
  </span>

  <form [name]="section.id"
        [formGroup]="formGroup" class="in-take-section-form-wrapper">
    <div class="fields-wrapper">
      <in-take-field *ngFor="let field of section.fields"
                     [field]="field"
                     [control]="formGroup.controls[field.id]">
      </in-take-field>
    </div>
    <div class="action-buttons">
      <button mat-button
              class="spl-button center"
              (click)="next()"
              [disabled]="!completed || isLoading"
              aria-label="Accept"
              [matTooltip]="section.actionText || (section.requiresAccept ? 'Accept' : 'Continue')">
        {{ section.actionText || (section.requiresAccept ? 'Accept' : 'Continue') }}
      </button>
      
      <button mat-button class="spl-outline-button center mt-16 mb-16" (click)="next()" [disabled]="!completed || isLoading"
              [attr.aria-label]="'COMMON.ACTIONS.SKIP' | translate"
              [matTooltip]="'COMMON.ACTIONS.SKIP' | translate"
      >
        {{ 'COMMON.ACTIONS.SKIP' | translate }}
      </button>
      
      <span *ngIf="section.canPrevious"
            mat-button
            color="primary"
            class="go-back-button mb-8"
            (click)="previous()"
            [attr.aria-label]="'COMMON.ACTIONS.GO_BACK_A_STEP' | translate"
            [matTooltip]="'COMMON.ACTIONS.GO_BACK_A_STEP' | translate">
      < 
        {{ 'COMMON.ACTIONS.GO_BACK_A_STEP' | translate}}
    </span>
      <span *ngIf="section.canBackToSignIn"
            mat-button
            color="primary"
            class="go-back-button mb-8"
            (click)="goToSignIn()"
            [attr.aria-label]="'COMMON.ACTIONS.GO_BACK_TO_LOGIN' | translate"
            [matTooltip]="'COMMON.ACTIONS.GO_BACK_TO_LOGIN' | translate">
      < 
        {{'COMMON.ACTIONS.GO_BACK_TO_LOGIN' | translate}}
    </span>
    </div>
  </form>
</div>

import _ from 'lodash';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SplNavigationService } from '@common/co/core/components/navigation/navigation.service';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { NAVIGATION } from '@common/co/navigation/navigation';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { FuseNavigation } from '@fuse/types';
import { AppSettingsResponse } from '@common/services/co-api-client';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'spl-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplNavigationComponent implements OnInit {
  @Input()
  layout = 'vertical';

  @Input()
  navigation: FuseNavigation[];

  generalNavigations: any[] = [];
  profileNavigations: any[] = [];

  private translations: any;
  private readonly infoLocalizationKey = 'NAV.SIGNED_IN_AS';
  info: string;

  // Private
  private _settings: AppSettingsResponse;
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {SplNavigationService} _splNavigationService
   * @param {AuthorizeService} _authorizeService
   * @param {FuseProgressBarService} _fuseProgressBar
   * @param {AppBusService} _appBusService
   */
  constructor(
    @Inject(NAVIGATION) private navigationsArray: FuseNavigation[],
    private _changeDetectorRef: ChangeDetectorRef,
    private _splNavigationService: SplNavigationService,
    private _authorizeService: AuthorizeService,
    private _fuseProgressBar: FuseProgressBarService,
    private _appBusService: AppBusService,
    private _translateService: TranslateService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._translateService
      .stream([this.infoLocalizationKey])
      .subscribe((translations) => {
        this.translations = translations;
        this.setInfo();
      });

    this._appBusService.appSettingsChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        if (!settings) return;
        this._settings = settings;
        this.setNavigation();
        this.setNavigationGroups();
        this.setInfo();
      });

    // Subscribe to the current navigation changes
    this._splNavigationService.onNavigationChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Load the navigation
        this.setNavigation();
        this.setNavigationGroups();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Subscribe to navigation item
    merge(
      this._splNavigationService.onNavigationItemAdded,
      this._splNavigationService.onNavigationItemUpdated,
      this._splNavigationService.onNavigationItemRemoved,
    )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.setNavigation();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    this._authorizeService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.setInfo();
      });

    this._appBusService.logoutRequested$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._fuseProgressBar.show();
      });

    this._appBusService.logout$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._fuseProgressBar.hide();
      });

    this._appBusService.actualizeProfileInfo$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.setInfo();
      });
  }

  private setNavigationGroups(): void {
    this.generalNavigations = this.navigation.filter(
      (el) => el.groupType === 'general',
    );
    this.profileNavigations = this.navigation.filter(
      (el) => el.groupType === 'profile',
    );
  }

  private setInfo(): void {
    const userName: string = this.getUserName(
      this._authorizeService.currentProfile?.name,
    );

    const translatedPrefix = this.translations
      ? this.translations[this.infoLocalizationKey]
      : 'Signed In As';
    if (userName) {
      this.info = `${translatedPrefix} ${userName}`;
      this._changeDetectorRef.markForCheck();
    }
  }

  private getUserName(profileName: string): string | undefined {
    if (!profileName || !profileName.trim()) return undefined;
    profileName = profileName.replace(/\s+/g, ' ').trim();
    const nameArray: string[] = profileName.split(' ');
    return nameArray.length > 1
      ? `${nameArray[0]} ${nameArray[1][0]}.`
      : `${nameArray[0]}`;
  }

  private setNavigation(): void {
    this.navigation = _.cloneDeep(this.navigationsArray);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

import { NgModule } from '@angular/core';

import { SplAthletesAppLayoutModule } from 'app/layout/spl-athletes-app-layout/spl-athletes-app-layout.module';

@NgModule({
  imports: [
    SplAthletesAppLayoutModule
  ],
  exports: [
    SplAthletesAppLayoutModule
  ],
})
export class LayoutModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthClient, RegisterCommand } from '@common/services/co-api-client';
import { validateAllFormFields } from '@common/co/core/helpers/validate-all-form-fields';
import { DateTimeHelper } from '@common/shared/helpers/date-time.helper';
@Component({
  selector: 'register-waiver-of-liability',
  templateUrl: './waiver-of-liability.component.html',
  styleUrls: ['./waiver-of-liability.component.scss'],
})
export class WaiverOfLiabilityComponent implements OnInit {
  @Input() registerFields: RegisterCommand;
  @Output() nextStep: EventEmitter<RegisterCommand> = new EventEmitter();

  registerForm: FormGroup;

  waiverOfLiability: string;
  currentDate: any;
  process: boolean = false;

  get completed(): boolean {
    return (
      this.registerForm.get('waiverOfLiabilitySignerName').valid &&
      this.registerForm.get('waiverOfLiabilitySignedAt').valid
    );
  }

  constructor(
    private _authClient: AuthClient,
    private _formBuilder: FormBuilder,
  ) {}

  async ngOnInit(): Promise<void> {
    this.registerForm = this._formBuilder.group({
      waiverOfLiabilitySignerName: [
        this.registerFields.waiverOfLiabilitySignerName,
        [Validators.required],
      ],
      waiverOfLiabilitySignedAt: [
        this.registerFields.waiverOfLiabilitySignedAt,
        [Validators.required],
      ],
    });

    this.currentDate = new Date();
    this.registerForm.controls['waiverOfLiabilitySignedAt'].setValue(
      this.currentDate,
    );
    const dateOfBirthWithAddedOffset = DateTimeHelper.getDateWithAddedOffset(
      this.registerFields.userRegistrationValidation?.dateOfBirth,
    );
    const response = await this._authClient
      .waiverOfLiability(
        this.registerFields.userRegistrationFlow,
        this.registerFields.email,
        this.registerFields.userRegistrationValidation?.uniqueId,
        dateOfBirthWithAddedOffset,
      )
      .toPromise();
    this.waiverOfLiability = response.content;
    return Promise.resolve();
  }

  next(): void {
    if (this.registerForm.valid) {
      this.process = true;
      this.nextStep.emit(this.registerForm.getRawValue());
    } else {
      validateAllFormFields(this.registerForm);
    }
  }
}

import { Directive, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NotificationService } from '@common/co/core/services/notification.service';


@Directive({
  selector: '[showValidationInfo]',
})
export class ShowValidationInfoDirective implements OnInit {
  private elementRef: ElementRef;
  
  constructor(
    // Get the control directive
    private control: NgControl,
    elementRef: ElementRef,
    private notificationService: NotificationService
  ) {
    this.elementRef = elementRef;
  }

  ngOnInit(): void {
    const abstractControl = this.control.control;
    this.elementRef.nativeElement.addEventListener('blur', () => {
      if (!abstractControl.errors) {
        this.notificationService.clearValidationMessage();
      }
    });
  }
}
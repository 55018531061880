<button
  mat-icon-button
  class="mat-elevation-z1"
  [matMenuTriggerFor]="languageMenu"
>

  <flag [code]="selectedLang?.code" [size]="'lg'"  [effect]="'round'"></flag>

</button>


<mat-menu
  #languageMenu="matMenu"
  class="language-menu mat-elevation-z8"
>
  <mat-selection-list #profiles [multiple]="false" fusePerfectScrollbar
  class='mat-selection-list-wrapper spl-scroll-container anguage-list' [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
  <mat-list-option *ngFor="let lang of languages" [value]="lang" (click)="onSelectLanguage(lang)">
    <span class="language-list-item">
      <flag [code]="lang.code" [size]="'lg'"  [effect]="'round'" class="flag-icon"></flag>
      <span class="flag-title">{{ lang.humanTitle }}</span>
    </span>
  </mat-list-option>
</mat-selection-list>
  
</mat-menu>
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ButtonsMenuModel } from '@common/co/shared/models/buttons-menu.model';

@Component({
  selector: 'buttons-menu',
  templateUrl: './buttons-menu.component.html',
  styleUrls: ['./buttons-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonsMenuComponent implements OnChanges {
  @Input() options: ButtonsMenuModel[];
  @Input() title: string;
  @Input() subTitle: string;
  @Output() optionSelected: EventEmitter<ButtonsMenuModel> = new EventEmitter();
  selectedOption: ButtonsMenuModel;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.options && changes.options.currentValue !== changes.options.previousValue) {
      const foundOptionIndex = this.options.findIndex(option => !option.disabled);
      if (foundOptionIndex > -1) {
        this.selectOption(this.options[foundOptionIndex]);
      }
    }
  }

  selectOption(option: ButtonsMenuModel): void {
    if (!option.disabled) {
      this.selectedOption = option;
      this.optionSelected.emit(this.selectedOption);
    }
  }
}

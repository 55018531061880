import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import moment from 'moment';
import {
  ChartConfigModel,
  SeriesChartModel,
} from '@common/co/shared/models/chart-config.model';
import { ChartService } from '@common/services/chart.service';

@Component({
  selector: 'spl-chart',
  templateUrl: './spl-line-chart.component.html',
  styleUrls: ['./spl-line-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SplLineChartComponent implements OnChanges {
  @Input() config: ChartConfigModel;
  @Input() series: SeriesChartModel[];

  constructor(private chartService: ChartService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.series) {
      const chartPoinst = [];
      if (this.series.length > 0) {
        this.series.forEach((s) => {
          chartPoinst.push(...s.series.map((x) => x.value));
        });

        ({ max: this.config.yScaleMax, min: this.config.yScaleMin } =
          this.chartService.calculateMinMaxFromPoints(chartPoinst));
      }
    }
  }

  format(val: Date): string {
    return moment(val).format('MM/DD').toString();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthClient } from '@common/services/co-api-client';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import {
  APPLICATIONS_PATHS,
  IApplicationPathsType,
  ReturnUrlType,
} from '@common/co/core/app.constants';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';
import { NotificationService } from '@common/co/core/services/notification.service';

@Component({
  selector: 'external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss'],
})
export class ExternalLoginComponent implements OnInit {
  constructor(
    @Inject(APPLICATIONS_PATHS) private applicationPaths: IApplicationPathsType,
    private activatedRoute: ActivatedRoute,
    private authClient: AuthClient,
    private authService: AuthorizeService,
    private router: Router,
    private notificationService: NotificationService,
  ) {}

  async ngOnInit(): Promise<void> {
    const returnUrl = this.getReturnUrl();
    const externalResult = await this.authClient
      .externalCallback(
        this.authService.oidcConfig.appId,
        this.getProvider(),
        returnUrl,
        null,
      )
      .toPromise();
    if (externalResult?.success) {
      await this.authService.externalAuthenticateUser(
        externalResult.accessToken,
        null,
        null,
      );
    } else {
      this.notificationService.generateNotification(
        getSplNotification('error', externalResult?.reason),
      );
    }
    await this.navigateToReturnUrl(returnUrl);
    return Promise.resolve();
  }

  private navigateToReturnUrl(returnUrl: string): Promise<boolean> {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    return this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (
      this.activatedRoute.snapshot.queryParams as INavigationState
    ).returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (
      fromQuery &&
      !(
        fromQuery.startsWith(`${window.location.origin}/`) ||
        // eslint-disable-next-line no-useless-escape
        /\/[^\/].*/.test(fromQuery)
      )
    ) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      this.applicationPaths.DefaultLoginRedirectPath
    );
  }

  private getProvider(): string {
    const fromQuery = (
      this.activatedRoute.snapshot.queryParams as INavigationState
    ).provider;
    return fromQuery || '';
  }
}

interface INavigationState {
  [ReturnUrlType]: string;
  ['provider']: string;
}

import { Component } from '@angular/core';
import { ILocale } from '@common/i18n/ilocale';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import {
  AthleteClient,
  SetLocaleCommand,
} from '@common/services/co-api-client';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { LanguageService } from '@common/i18n/language.service';

@Component({
  selector: 'user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
})
export class UserPreferencesComponent {
  public supportedLanguages: ILocale[];
  public currentLanguage: ILocale;
  public languageChanged: boolean = false;
  constructor(
    private _translateService: TranslateService,
    private athleteClient: AthleteClient,
    private authService: AuthorizeService,
    private languageService: LanguageService,
  ) {
    this.supportedLanguages = this.languageService.supportedLanguages;
    this.currentLanguage = _.find(
      this.supportedLanguages,
      (locale) => locale.lang === _translateService.currentLang,
    );
  }

  public onLangChange(locale: ILocale): void {
    this.currentLanguage = locale;
    this._translateService.use(locale.lang);
    const command = new SetLocaleCommand({
      clientId: this.authService.currentProfileId,
      locale: locale.lang,
    });
    this.athleteClient.setLocale(command).subscribe((res) => {
      if (res.success) {
        this.authService.actualizeProfileInfo({ locale: locale.lang });
      }
    });
  }
}

<div class="profile-host">
  <h1>
    {{ 'CHANGE_PROFILE.CHANGE_PROFILE' | translate }}
  </h1>
  <p>
    {{ 'CHANGE_PROFILE.WHICH_PROFILE' | translate }}
  </p>

  <mat-selection-list #profiles [multiple]="false" fusePerfectScrollbar
    class='mat-selection-list-wrapper spl-scroll-container' [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
    <mat-list-option *ngFor="let profile of _authorizeService.availableProfiles" [value]="profile">
      <div class="user-profile-line">
        <div class="user-icon-host"> </div> <span class="user-profile-name">{{profile.name}} <span *ngIf="!_authorizeService.singleTenant">({{profile.tenant?.name}})</span></span>
      </div>
    </mat-list-option>
  </mat-selection-list>


  <button mat-button [disabled]="!this.profiles.selectedOptions.selected[0]?.value" class="spl-button full mt-16 mb-16 dashboard"
    (click)="goToDashboard()" 
    [attr.aria-label]="'CHANGE_PROFILE.CONTINUE_TO_DASHBOARD' | translate" 
    [matTooltip]="'CHANGE_PROFILE.CONTINUE_TO_DASHBOARD' | translate"
    >
    {{ 'CHANGE_PROFILE.CONTINUE_TO_DASHBOARD' | translate }}
  </button>

  <button mat-button class="spl-outline-button full mt-16 mb-16" (click)="createAnother()"
    [attr.aria-label]="'CHANGE_PROFILE.ADD_ANOTHER_PROFILE' | translate" 
    [matTooltip]="'CHANGE_PROFILE.ADD_ANOTHER_PROFILE' | translate"
    >
    {{ 'CHANGE_PROFILE.ADD_ANOTHER_PROFILE' | translate }}
  </button>

  <button mat-button class="spl-outline-button full mt-16 mb-16 sign-out"
    (click)="goToLogin()"
    [attr.aria-label]="'COMMON.ACTIONS.SIGN_OUT' | translate" 
    [matTooltip]="'COMMON.ACTIONS.SIGN_OUT' | translate">
    {{ 'COMMON.ACTIONS.SIGN_OUT' | translate }}    
  </button>

  <button mat-button class="spl-outline-button full mt-16 mb-16 delete-account"
    (click)="openDeleteAccountConfirmationDialog()"
    [attr.aria-label]="'CHANGE_PROFILE.DELETE_ACCOUNT' | translate" 
    [matTooltip]="'CHANGE_PROFILE.DELETE_ACCOUNT' | translate"
    >
    {{ 'CHANGE_PROFILE.DELETE_ACCOUNT' | translate }}
  </button>

</div>

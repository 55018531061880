import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AthleteAssessmentClient,
  EventClient,
  EventDto,
  OrderType,
  TestingEventsResponse,
} from '@common/services/co-api-client';
import { getButtonsListMenuDataForTestsHistory } from '@common/co/shared/helpers/dataToButtonsListMenu';
import { LinksMenuModel } from '@common/co/shared/models/links-menu.model';
import { ButtonsListMenuModel } from '@common/co/shared/models/buttons-list-menu.model';
import { PaginationModel } from '@common/co/shared/models/pagination.model';
import { commonNavigationConfig } from '@common/co/navigation/navigation';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import * as _ from 'lodash';
import { translateLinks } from '@common/i18n/ilocale';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'testing-assessment-history',
  templateUrl: './testing-assessment-history.component.html',
  styleUrls: ['./testing-assessment-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TestingAssessmentHistoryComponent implements OnInit {
  public historyList: LinksMenuModel[] = [];
  public totalCount: number = 0;
  public processing: boolean = false;
  public isLoadingPromise: Promise<void | TestingEventsResponse>;
  public moreTestsList: ButtonsListMenuModel[] = [
    {
      title: 'View More Tests',
    },
  ];
  private historyTests: EventDto[] | undefined;
  private pagingParamsForHistory: PaginationModel = {
    pageIndex: 1,
    pageSize: 3,
    from: undefined,
    to: new Date(),
    orderType: OrderType.Descending,
  };

  constructor(
    private eventClient: EventClient,
    private appBusService: AppBusService,
    private _athleteAssessmentClient: AthleteAssessmentClient,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isLoadingPromise = this.getHistory().then(() => {
      this.appBusService.setDateOfLastTest(
        this.historyTests.length
          ? _.last(_.sortBy(this.historyTests, 'dateTime'))?.dateTime
          : null,
      );
    });
  }

  public nextPage(): void {
    this.pagingParamsForHistory.pageIndex += 1;
    this.getHistory();
  }

  public get isLoadMoreListShown(): boolean {
    return this.historyList.length < this.totalCount && !this.processing;
  }

  private getHistory(): Promise<void | TestingEventsResponse> {
    this.processing = true;
    return this._athleteAssessmentClient
      .getTestingEvents(
        this.pagingParamsForHistory.from,
        this.pagingParamsForHistory.to,
        this.pagingParamsForHistory.orderType,
        this.pagingParamsForHistory.pageIndex,
        this.pagingParamsForHistory.pageSize,
      )
      .toPromise()
      .then((res: TestingEventsResponse) => {
        this.totalCount = res.events.totalCount;
        this.historyTests = res.events.items;
        this.historyList = [
          ...this.historyList,
          ...getButtonsListMenuDataForTestsHistory(
            this.historyTests,
            commonNavigationConfig.testing_assessment_summary.url,
          ),
        ];
        translateLinks(this.historyList, this.translateService);
        this.processing = false;
      });
  }
}

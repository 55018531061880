import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { BitmovinPlayerDialogComponent } from '@common/bitmovin-player/components/bitmovin-player-dialog/bitmovin-player-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'bitmovin-player-poster',
  templateUrl: './bitmovin-player-poster.component.html',
  styleUrls: ['./bitmovin-player-poster.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BitmovinPlayerPosterComponent {
  @Input() video: string;
  @Input() poster: string;
  @Input() muted: boolean = false;
  @Input() relatedObjectId: string;
  @Output() videoClosed: EventEmitter<boolean> = new EventEmitter();
  constructor(private matDialog: MatDialog) {}

  openVideo(): void {
    const confirmDialogRef = this.matDialog.open(
      BitmovinPlayerDialogComponent,
      {
        disableClose: false,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'bitmovin-dialog-full-screen-modal',
      },
    );
    confirmDialogRef.componentInstance.video = this.video;
    confirmDialogRef.componentInstance.poster = this.poster;
    confirmDialogRef.componentInstance.muted = this.muted;
    confirmDialogRef.componentInstance.relatedObjectId = this.relatedObjectId;
    confirmDialogRef.componentInstance.trackVideoView();
    confirmDialogRef.afterClosed().subscribe(() => {
      this.videoClosed.next(true);
    });
  }
}

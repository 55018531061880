import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'msecToTime',
})
export class MsecToTimePipe implements PipeTransform {
  public transform(value: number): string {
    let time = '';
    if (value != null && !isNaN(value) && value >= 0) {
      let secs: number = value / 1000;
      const mins: number = Math.floor(secs / 60);
      secs = secs - mins * 60;
      time = `${this.addLeadingZero(mins)}:${this.addLeadingZero(secs)}`;
    }
    return time;
  }

  private addLeadingZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }
}

<div class="pie-chart-wrapper">
  <div class="pie-chart" *ngIf="series.length > 0">
    <ngx-charts-pie-chart
      [view]="config.view"
      [scheme]="config.colorScheme"
      [results]="series"
      [gradient]="config.gradient"
      [legend]="config.legend"
      [legendPosition]="config.legendPosition"
      [legendTitle]="config.legendTitle"
      [labels]="config.showLabels"
      [doughnut]="config.isDoughnut"
    >
    </ngx-charts-pie-chart>
  </div>
</div>
import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { PlatformService } from '@common/co/core/services/platform.service';

@Directive({
  selector: '[displayOnlyOnMobile]'
})
export class DisplayOnlyOnMobileDirective {

  constructor(private template: TemplateRef<any>, private view: ViewContainerRef,
              private platformService: PlatformService) {
    if (this.platformService.isMobile) {
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
  }
}
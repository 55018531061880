import { RouterModule, Routes } from '@angular/router';
import { SleepTrackerComponent } from './sleep-tracker.component';
import { AuthorizeGuard } from '@common/co/auth/guards/authorize.guard';
import { SelectProfileGuard } from '@common/co/auth/components/login/components/select-profile/select-profile.guard';
import { commonNavigationConfig } from '@common/co/navigation/navigation';
import { ModuleWithProviders } from '@angular/core';

const routes: Routes = [
  {
    path: 'sleep-tracker',
    component: SleepTrackerComponent,
    canActivate: [AuthorizeGuard, SelectProfileGuard],
    data: commonNavigationConfig.sleep_tracker.payload,
  },
];

export const SleepTrackerRoutes: ModuleWithProviders<any> =
  RouterModule.forChild(routes);

<div class="spl-bitmovin-player-dialog">
  <button
      mat-icon-button
      class="bitmovin-player-dialog__close"
      (click)="closeVideo()"
    >
      <mat-icon class="bitmovin-player-dialog__close-icon">close</mat-icon>
  </button>
  <app-bitmovin-player
  [src]="video"
  [poster]="poster"
  [autoplay]="true"
  [fluid]="true"
  [muted] = "muted"
  >
  </app-bitmovin-player>
</div>

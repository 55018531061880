import {
  Component,
  ViewEncapsulation,
  OnDestroy,
  OnInit,
  Inject,
} from '@angular/core';
import { InTakeService } from '@common/co/auth/services/in-take.service';
import * as _ from 'underscore';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {
  INavigationConfig,
  NAVIGATION_CONFIG,
} from '@common/co/navigation/navigation';

@Component({
  selector: 'in-take',
  templateUrl: './in-take.component.html',
  styleUrls: ['./in-take.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InTakeComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(NAVIGATION_CONFIG) private navigationConfig: INavigationConfig,
    public inTakeService: InTakeService,
    private route: ActivatedRoute,
    private _router: Router,
  ) {
    this._unsubscribeAll = new Subject();
    inTakeService.dataLoaded$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((sectionsVM) => {
        if (sectionsVM && !this.inTakeService.isEdit) {
          for (let i = 0; i < sectionsVM.length; ++i) {
            if (i > 0) {
              sectionsVM[i].canPrevious = true;
            }
            if (_.any(sectionsVM[i].fields, (f) => !f.answer?.value)) {
              sectionsVM[i].active = true;
              break;
            }
          }
        }
        if (this.inTakeService.isEdit) {
          this.inTakeService.setEditSectionsVM();
        }
      });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.isEdit) {
        this.inTakeService.isEdit = true;
        this.inTakeService.refreshInTake();
      }
    });
  }

  ngOnDestroy(): void {
    this.inTakeService.isEdit = false;

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

import { FuseNavigation } from '@fuse/types';
import {
  commonNavigationConfig,
  INavigationConfig,
} from '@common/co/navigation/navigation';
import { StatisticsActivities } from '@common/models/statistic-activities';
import { propertyStringPathFactory } from '@common/i18n/translation-helpers';
import { ILocaleData } from '@common/i18n/ilocale';
import { ISPLNavigation } from 'app/i18n/ispl-locale';

export const translationFiels = propertyStringPathFactory<ILocaleData>();
export const translationFielsExtra =
  propertyStringPathFactory<ISPLNavigation>();

export const navigationConfig: INavigationConfig = {
  root: {
    id: 'root',
    title: 'Root',
    type: 'item',
    url: '',
    hidden: true,
  },
  register: {
    id: 'register',
    title: 'Register',
    type: 'item',
    url: '/register',
  },
  check_unique_id: {
    id: 'check_unique_id',
    title: 'Check Unique Id',
    type: 'item',
    url: '/check-unique-id',
  },
  forgot_password: {
    id: 'forgot_password',
    title: 'Forgot Password',
    type: 'item',
    url: '/forgot-password',
  },
  reset_password: {
    id: 'reset_password',
    title: 'Reset Password',
    type: 'item',
    url: '/reset-password',
  },
  nutrition: {
    id: 'nutrition',
    title: 'Nutrition',
    type: 'item',
    url: undefined,
    isComing: true,
    groupType: 'general',
    hidden: true,
  },
  mindset: {
    id: 'mindset',
    title: 'Mindset',
    type: 'item',
    url: undefined,
    isComing: true,
    groupType: 'general',
    hidden: true,
  },
  recovery: {
    id: 'recovery',
    title: 'Recovery',
    type: 'item',
    url: undefined,
    isComing: true,
    groupType: 'general',
    hidden: true,
  },
  physical_therapy: {
    id: 'physical_therapy',
    title: 'Physical Therapy',
    type: 'item',
    url: undefined,
    isComing: true,
    groupType: 'general',
    hidden: true,
  },
  shop: {
    id: 'shop',
    title: 'Shop',
    type: 'item',
    url: undefined,
    isComing: true,
    groupType: 'general',
    hidden: true,
  },
  edit_profile: {
    id: 'edit_profile',
    title: 'Edit Profile',
    translate: translationFielsExtra('EXTRA_NAV.EDIT_PROFILE'),
    type: 'item',
    url: undefined,
    isActionable: true,
    groupType: 'profile',
    payload: {
      pageTrackingKey: StatisticsActivities.PAGE_EDIT_PROFILE,
    },
  },
  sign_out: {
    id: 'sign_out',
    title: 'Sign out',
    translate: translationFielsExtra('EXTRA_NAV.SIGN_OUT'),
    type: 'item',
    url: undefined,
    isActionable: true,
    groupType: 'profile',
  },
  about_spl: {
    id: 'about_spl',
    title: 'About Sports performance Lab',
    translate: translationFielsExtra('EXTRA_NAV.ABOUT'),
    type: 'item',
    url: '/about',
    isActionable: true,
    groupType: 'profile',
    payload: {
      breadcrumb: 'About Sports Performance Lab',
      pageTrackingKey: StatisticsActivities.PAGE_ABOUT,
    },
  },
  select_profile: {
    id: 'select_profile',
    title: 'Select Profile',
    type: 'item',
    url: '/select-profile?',
    hidden: true,
  },
  schedule_in_studio_single_session: {
    id: 'schedule_in_studio_single_session',
    title: 'Schedule In-Studio Training',
    type: 'item',
    url: '/training/in-studio-single-session',
    payload: {
      breadcrumb: translationFiels('NAV.SCHEDULE_IN_STUDIO_TRAINING'),
    },
    hidden: true,
  },
};

export const navigation: FuseNavigation[] = [
  commonNavigationConfig.dashboard,
  navigationConfig.nutrition,
  commonNavigationConfig.testing_assessment,
  navigationConfig.mindset,
  commonNavigationConfig.training,
  commonNavigationConfig.sleep_tracker,
  commonNavigationConfig.hydration_tracker,
  navigationConfig.recovery,
  navigationConfig.physical_therapy,
  navigationConfig.shop,
  navigationConfig.edit_profile,
  commonNavigationConfig.preferences,
  commonNavigationConfig.change_password,
  commonNavigationConfig.switch_profile,
  navigationConfig.sign_out,
  navigationConfig.about_spl,
];

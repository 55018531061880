import { Injectable, ErrorHandler } from '@angular/core';
import { LoggingService } from '@common/services/logging.service';
import { NotificationService } from '@common/co/core/services/notification.service';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  private readonly errorNotificationDurationMs: number = 5000;
  private readonly errorNetworkMessage: string =
    'Something went wrong, please check your internet connection and try again.';
  private lastErrors: ErrorLog[] = [];
  private readonly maxLastErrorsLength: number = 10;

  constructor(
    private loggingService: LoggingService,
    private notificationService: NotificationService,
  ) {
    super();
  }

  getLastErrors(): ErrorLog[] {
    return this.lastErrors.slice(0);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleError(error: any): void {
    this.loggingService.logException(error); // Manually log exception

    if (error.promise && error.rejection) {
      error = error.rejection;
    }

    if (error.status !== undefined && error.status === 0) {
      this.displayNetworkError();
    } else if (error.status !== undefined && error.status === 401) {
      // auth error do not display error
    } else if (error.status !== undefined && error.status === 400) {
      if (error.response) {
        const response = JSON.parse(error.response);
        if (response.errors) {
          this.displayValidationErrorMessage(response.errors);
        }
      } else if (error.message) {
        this.displayErrorMessage(error.message);
      }
    } else if (error.message) {
      this.displayErrorMessage(error.message);
    }

    const operationId = this.loggingService.getOperationId();
    const timestamp = new Date().getTime();
    const message = error.message ? error.message : error.toString();
    const stackTrace = error.stack ? error.stack : null;

    const errorLog = new ErrorLog();
    errorLog.operationId = operationId;
    errorLog.timestamp = timestamp;
    errorLog.message = message;
    errorLog.stack = stackTrace;
    this.lastErrors.push(errorLog);

    if (this.lastErrors.length > this.maxLastErrorsLength) {
      this.lastErrors.shift();
    }
  }

  private displayNetworkError(): void {
    this.notificationService.generateNotification(
      getSplNotification(
        'error',
        this.errorNetworkMessage,
        this.errorNotificationDurationMs,
      ),
    );
  }

  private displayValidationErrorMessage(errors: {
    [key: string]: string[];
  }): void {
    const allErrors = Object.values(errors)
      .map((e) => e.join('\n'))
      .join('\n');
    this.notificationService.generateNotification(
      getSplNotification('error', allErrors, this.errorNotificationDurationMs),
    );
  }

  private displayErrorMessage(message: string): void {
    const notification = getSplNotification(
      'error',
      message,
      this.errorNotificationDurationMs,
    );
    this.notificationService.generateNotification(notification);
  }
}

export class ErrorLog {
  operationId: string;
  timestamp: number;
  message: string;
  stack: string;
}

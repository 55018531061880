import {
  Component,
  ViewChild,
  Input,
  AfterViewInit,
  ViewEncapsulation,
  Inject,
} from '@angular/core';
import { Player } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import { APP_CONFIG, IAppConfig } from '@common/co/core/config/app.config';

@Component({
  selector: 'app-bitmovin-player',
  templateUrl: './bitmovin-player.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./bitmovin-player.component.scss'],
})
export class BitmovinPlayerComponent implements AfterViewInit {
  @ViewChild('player') playerElement;
  @Input() src: string;
  @Input() poster: string;
  @Input() controls = true;
  @Input() fluid: boolean;
  @Input() width: string;
  @Input() height: string;
  @Input() autoplay: boolean;
  @Input() muted: boolean = false;

  constructor(@Inject(APP_CONFIG) private appConfig: IAppConfig) {}

  player;
  playerConfig;

  ngAfterViewInit(): void {
    this.playerConfig = {
      key: this.appConfig.bitmovinPlayerKey,
      ui: false,
      playback: {
        muted: this.muted,
        autoplay: this.autoplay,
      },
      adaptation: {
        startupBitrate: '9999kbps',
      },
    };
    const playerSource = {
      dash: this.src + '.mpd',
      hls: this.src + '.m3u8',
      poster: this.poster,
    };
    this.player = new Player(
      this.playerElement.nativeElement,
      this.playerConfig,
    );
    this.player.load(playerSource).then(
      () => {
        UIFactory.buildDefaultUI(this.player);
        console.log('Successfully loaded source');
      },
      () => {
        console.log('Error while loading source');
      },
    );
  }

  ngDestroy(): void {
    if (this.player) {
      this.player.destroy();
    }
  }
}

import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InTakeComponent } from '@common/co/feature/in-take/in-take.component';
import { AuthorizeGuard } from '@common/co/auth/guards/authorize.guard';
import { SelectProfileGuard } from '@common/co/auth/components/login/components/select-profile/select-profile.guard';
import { InTakeCompletionComponent } from '@common/co/feature/in-take/in-take-completion/in-take-completion.component';
import { commonNavigationConfig } from '@common/co/navigation/navigation';

const routes: Routes = [
  {
    path: 'in-take',
    component: InTakeComponent,
    canActivate: [AuthorizeGuard, SelectProfileGuard],
    data: commonNavigationConfig.in_take.payload,
  },
  {
    path: 'in-take-complete',
    component: InTakeCompletionComponent,
    canActivate: [AuthorizeGuard, SelectProfileGuard],
  },
];

export const InTakeRoutes: ModuleWithProviders<any> =
  RouterModule.forChild(routes);

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[switchParentClass]',
})
export class SwitchParentClassDirective {
  @Input() switchParentClass: string;

  private elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  @HostListener('click', ['$event']) onClick(): void {
    const classList = this.elementRef?.nativeElement?.classList;
    if (Array.from(classList).indexOf(this.switchParentClass) > -1) {
      this.elementRef.nativeElement.classList.remove(this.switchParentClass);
    } else {
      this.elementRef.nativeElement.classList.add(this.switchParentClass);
    }
  }
}

import {
  Component, EventEmitter,
  Input, OnChanges, Output,
  ViewEncapsulation,
} from '@angular/core';
import { SelectListMenuModel } from '@common/co/shared/models/select-list-menu.model';

@Component({
  selector: 'select-list-menu',
  templateUrl: './select-list-menu.component.html',
  styleUrls: ['./select-list-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectListMenuComponent implements OnChanges {
  @Input() list: SelectListMenuModel[];
  @Input() title: string;
  @Output() optionSelected: EventEmitter<SelectListMenuModel[]> = new EventEmitter();
  selectedOptions: SelectListMenuModel[] = [];
  
  ngOnChanges(): void {
    this.list.forEach((el, index) => {
      if (el.selected) {
        el.index = index;
        this.selectedOptions.push(el);
      }
    })
  }

  selectOption(option: SelectListMenuModel, index: number): void {
    option.selected = !option.selected;
    if (option.selected) {
      option.index = index;
      this.selectedOptions.push(option);
    } else {
      const foundIndex = this.selectedOptions.findIndex(el => {
        return el.title === option.title;
      });
      this.selectedOptions.splice(foundIndex, 1);
    }
    this.optionSelected.emit(this.selectedOptions);
  }
}

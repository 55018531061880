<div
  class="nav"
  [ngClass]="{
    horizontal: layout === 'horizontal',
    vertical: layout === 'vertical'
  }"
>
  <!-- Vertical Navigation Layout -->
  <ng-container *ngIf="layout === 'vertical'">
    <ng-container *ngFor="let item of generalNavigations">
      <spl-nav-vertical-group
        *ngIf="item.type == 'group'"
        [item]="item"
      ></spl-nav-vertical-group>
      <spl-nav-vertical-collapsable
        *ngIf="item.type == 'collapsable'"
        [item]="item"
      ></spl-nav-vertical-collapsable>
      <spl-nav-vertical-item
        *ngIf="item.type == 'item'"
        class="{{ 'group-' + item.groupType }}"
        [item]="item"
      ></spl-nav-vertical-item>
    </ng-container>

    <div class="profile-info">
      {{ info }}
    </div>
    
    <ng-container *ngFor="let item of profileNavigations">
      <spl-nav-vertical-group
        *ngIf="item.type == 'group'"
        [item]="item"
      ></spl-nav-vertical-group>
      <spl-nav-vertical-collapsable
        *ngIf="item.type == 'collapsable'"
        [item]="item"
      ></spl-nav-vertical-collapsable>
      <spl-nav-vertical-item
        *ngIf="item.type == 'item'"
        class="{{ 'group-' + item.groupType }}"
        [item]="item"
      ></spl-nav-vertical-item>
    </ng-container>
  </ng-container>
  <!-- / Vertical Navigation Layout -->
</div>

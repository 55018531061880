import { Component, Input } from '@angular/core';
import {
  ChartPieConfigModel,
  SeriesModel,
} from '@common/co/shared/models/chart-config.model';

@Component({
  selector: 'spl-pie-chart',
  templateUrl: './spl-pie-chart.component.html',
  styleUrls: ['./spl-pie-chart.component.scss'],
})
export class SplPieChartComponent {
  @Input() config: ChartPieConfigModel;
  @Input() series: SeriesModel[];
}

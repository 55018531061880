import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '@common/co/core/config/app.config';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { applicationStorageFields } from '@common/co/core/app-storage.constants';
import { AuthProviders } from '@common/co/auth/model';
import { StorageService } from '@common/services/storage.service';

@Injectable()
export class SocialLoginService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private _appBusService: AppBusService,
    private _storage: StorageService,
  ) {
    // this.isWeb().then((res: boolean) => {
    //   if (!res) {
    //     return;
    //   }
    // GoogleAuth.initialize({
    //   clientId: this.appConfig.googleClientId,
    //   scopes: ['email'],
    //   grantOfflineAccess: true,
    // });

    // FacebookLogin.initialize({ appId: this.appConfig.facebookAppId });
    // });
    this._appBusService.logout$.subscribe(async () => {
      const provider = await this._storage.get({
        key: applicationStorageFields.STORAGE_LOGGED_BY_PROVIDER,
      });
      if (provider && provider.value) {
        if (provider.value == AuthProviders.google) {
          this.signOutGoogle();
        }
        if (provider.value == AuthProviders.facebook) {
          this.signOutFacebook();
        }
      }
    });
  }

  // async googleSignIn(): Promise<string> {
  //   return GoogleAuth.signIn()
  //     .then((googleUser) => {
  //       return googleUser.authentication.idToken;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       throw err;
  //     });
  // }

  // async facebookSignIn(): Promise<string> {
  //   const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];
  //   const result: FacebookLoginResponse = await FacebookLogin.login({
  //     permissions: FACEBOOK_PERMISSIONS,
  //   });
  //   if (!result.accessToken) {
  //     return null;
  //   }
  //   return result.accessToken.token;
  // }

  async signOutGoogle(): Promise<any> {
    // try {
    //   GoogleAuth.signOut();
    // } catch (e) {
    //   // nothing to do
    // }
  }

  async signOutFacebook(): Promise<any> {
    // try {
    //   FacebookLogin.logout();
    // } catch (e) {
    //   // nothing to do
    // }
  }
}

import { RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from '@common/co/auth/guards/authorize.guard';
import { SelectProfileGuard } from '@common/co/auth/components/login/components/select-profile/select-profile.guard';
import { commonNavigationConfig } from '@common/co/navigation/navigation';
import { ModuleWithProviders } from '@angular/core';
import { HydrationTrackerComponent } from '@common/co/feature/hydration-tracker/components/hydration-tracker/hydration-tracker.component';

const routes: Routes = [
  {
    path: 'hydration-tracker',
    component: HydrationTrackerComponent,
    canActivate: [AuthorizeGuard, SelectProfileGuard],
    data: commonNavigationConfig.hydration_tracker.payload,
  },
];

export const HydrationTrackerRoutes: ModuleWithProviders<any> =
  RouterModule.forChild(routes);

import { Injectable } from '@angular/core';
import { BlobUploadCommonResponse, BlockBlobClient } from '@azure/storage-blob';
import { OverlayLoaderService } from '@common/shared/components/overlay-loader/overlay-loader.service';
@Injectable()
export class BlobStorageService {
  private fileReader: FileReader = new FileReader();

  constructor(private overlayLoaderService: OverlayLoaderService) {}

  public async getBlockBlobClient(uri: string): Promise<BlockBlobClient> {
    return new BlockBlobClient(uri);
  }

  public async uploadData(file: File, sasUri: string): Promise<void> {
    try {
      return new Promise((resolve, reject) => {
        this.fileReader.readAsArrayBuffer(file);
        this.fileReader.onload = (): void => {
          this.overlayLoaderService.setTitle('Uploading file...');
          this.overlayLoaderService.isLoading.next(true);
          this.uploadBlob(
            sasUri,
            this.fileReader.result as ArrayBuffer,
            file?.size,
            file?.type,
          )
            .then(() => {
              resolve();
            })
            .catch((e) => {
              reject(e);
            })
            .finally(() => {
              this.overlayLoaderService.isLoading.next(false);
            });
        };
        this.fileReader.onerror = (): void => {
          console.log(`Reading file failed. Error ${this.fileReader.error}`);
          this.overlayLoaderService.isLoading.next(false);
          reject();
        };
      });
    } catch (err: any) {
      this.errorHandler(err, 'Upload');
    }
  }

  private async uploadBlob(
    sasUri: string,
    arrayBuffer: ArrayBuffer,
    size: number,
    blobType: string,
  ): Promise<BlobUploadCommonResponse> {
    const blobClient = await this.getBlockBlobClient(sasUri);
    return blobClient.uploadData(arrayBuffer, {
      blobHTTPHeaders: {
        blobContentType: blobType,
      },
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      onProgress: (ev) => {
        this.overlayLoaderService.setProgress(
          Math.round((ev.loadedBytes / size) * 100),
        );
      },
    });
  }

  private errorHandler(err, action: string): void {
    this.overlayLoaderService.isLoading.next(false);
    console.log(
      `${action} failed, requestId - ${err?.request?.requestId},
         statusCode - ${err?.statusCode},
         errorCode - ${err.details?.errorCode}`,
    );
  }
}

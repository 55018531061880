<div class="radar-chart-wrapper">
  <canvas baseChart
          *ngIf="radarChartOptions && radarChartData"
          [datasets]="radarChartData"
          [options]="radarChartOptions"
          [labels]="radarChartLabels"
          [type]="'radar'"
  >
  </canvas>
</div>

<div class="line-chart-wrapper">
  <div class="line-chart">
    <span class="score">Score</span>
    <ngx-charts-line-chart
      [view]="config.view"
      [scheme]="config.colorScheme"
      [legend]="config.legend"
      [legendPosition]="config.legendPosition"
      [xAxis]="config.xAxis"
      [yAxis]="config.yAxis"
      [timeline]="config.timeline"
      [results]="series"
      [legendTitle]="config.legendTitle"
      [yScaleMax]="config.yScaleMax"
      [yScaleMin]="config.yScaleMin"
      [xAxisTickFormatting]="format"
    >
    </ngx-charts-line-chart>
  </div>
</div>
import { ButtonsListMenuModel } from '@common/co/shared/models/buttons-list-menu.model';
import { SelectListMenuModel } from '@common/co/shared/models/select-list-menu.model';
import moment from 'moment';
import {
  AvailabilityDateDto,
  AvailabilityDateTimeDto,
  AvailabilityMatrixDayOfWeek,
  AvailabilityMatrixSlot,
  EventDto,
  LocationDto,
  PersonalRecordExerciseSetExerciseCompletionDto,
  PersonalRecordExerciseSetWeekDayStatus,
  TrainingPreview,
  TrainingProgramType,
  UpcomingTraining,
} from '@common/services/co-api-client';
import { LinksMenuModel } from '@common/co/shared/models/links-menu.model';
import { commonNavigationConfig } from '@common/co/navigation/navigation';

export function getButtonsListMenuDataForLocations(
  data: LocationDto[],
): ButtonsListMenuModel[] {
  const newModel: ButtonsListMenuModel[] = [];
  data.forEach((block) => {
    newModel.push({
      title: block.name,
      id: block.id,
    });
  });
  return newModel;
}

export function getButtonsListMenuDataForDates(
  data: AvailabilityDateDto[],
): ButtonsListMenuModel[] {
  const newModel: ButtonsListMenuModel[] = [];
  data.forEach((block) => {
    let title = moment(block.availabilityDate).format('dd, MMM D');
    if (block.isMaxTrainingCountExceeded) {
      title += ' - Session Booked';
    } else if (!block.isAvailable) {
      title += ' - No spots available';
    }
    newModel.push({
      title: title,
      disabled: !block.isAvailable || block.isMaxTrainingCountExceeded,
    });
  });
  return newModel;
}

export function getButtonsListMenuDataForTime(
  data: AvailabilityDateTimeDto[],
): ButtonsListMenuModel[] {
  const newModel: ButtonsListMenuModel[] = [];
  data.forEach((block) => {
    const title = `${moment(block.availabilityDateTime).format('h:mm:a')} - ${
      block.isAvailable ? block.trainerName : 'No spots available'
    }`;
    newModel.push({
      title: title,
      disabled: !block.isAvailable,
    });
  });
  return newModel;
}

export function getButtonsSelectListMenuDataForAvailability(
  daysOfTheWeekList: AvailabilityMatrixDayOfWeek[],
): SelectListMenuModel[] {
  const newModel: SelectListMenuModel[] = [];
  daysOfTheWeekList.forEach((day) => {
    newModel.push({
      title: day.dayOfWeek,
      disabled: !day.isAvailable,
      selected: false,
    });
  });
  return newModel;
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getButtonsListMenuDataForSelectTime(
  data: AvailabilityMatrixSlot[],
  isRecurring: boolean,
): ButtonsListMenuModel[] {
  const newModel: ButtonsListMenuModel[] = [];
  data.forEach((block) => {
    const title =
      moment(block.firstAvailabilityDateTime).format('h:mm:a') +
      ` - ${
        (
          isRecurring
            ? block.isAvailableForRecurring
            : block.isAvailableForSingle
        )
          ? block.trainerName
          : 'No spots available'
      }`;
    newModel.push({
      title: title,
      disabled: isRecurring
        ? !block.isAvailableForRecurring
        : !block.isAvailableForSingle,
    });
  });
  return newModel;
}

export function getButtonsListMenuDataForDefaultTrainingEvents(
  data: UpcomingTraining[],
  url: string,
): LinksMenuModel[] {
  const newModel: LinksMenuModel[] = [];
  data.forEach((event) => {
    const day = getDateAsDay(event.defaultTraining?.dateTime);
    const date = moment(event.defaultTraining?.dateTime).format('MM/DD');
    const time = moment(event.defaultTraining?.dateTime).format('h:mma');
    const title = `${day} <span class="v-splitter">|</span> ${date} <span class="v-splitter">|</span> ${time} <span class="v-splitter">|</span> ${event.defaultTraining?.trainingClassName}`;
    newModel.push({
      title: title,
      id: event.defaultTraining?.trainingScheduleId,
      url: url,
      extra: {
        state: moment(event.defaultTraining?.dateTime).format('M/DD/YY'),
      },
    });
  });
  return newModel;
}

export function getButtonsListMenuDataForCustomTrainingEvents(
  data: UpcomingTraining[],
  type: TrainingProgramType,
  url: string,
): LinksMenuModel[] {
  const newModel: LinksMenuModel[] = [];
  data.forEach((event) => {
    newModel.push({
      title: event.description,
      url: url,
      id: type.toString(),
      extra: {
        queryParams: {
          weekNumber: event.customTraining?.weekNumber,
          dayNumber: event.customTraining?.dayNumber,
        },
      },
    });
  });
  return newModel;
}

export function getButtonsListMenuDataForExerciseProgram(
  data: PersonalRecordExerciseSetExerciseCompletionDto[],
  type: TrainingProgramType,
): LinksMenuModel[] {
  const newModel: LinksMenuModel[] = [];
  data.forEach((event) => {
    let title = `Week ${event.weekNumber} Day ${event.dayNumber}`;
    if (event.status === PersonalRecordExerciseSetWeekDayStatus.Missed) {
      title += ` <i class="material-icons link-icon skip-icon">close</i>`;
    } else if (
      event.status === PersonalRecordExerciseSetWeekDayStatus.Completed
    ) {
      title += ` <i class="material-icons link-icon success-icon">check</i> <span class="sub-title">${moment(
        event.completedDateTime,
      ).format('M/DD/YY')}</span>`;
    } else if (
      event.status === PersonalRecordExerciseSetWeekDayStatus.Started
    ) {
      title += ` <i class="material-icons link-icon started-icon">access_time</i> <span class="sub-title">${moment(
        event.startedDateTime,
      ).format('M/DD/YY')}</span>`;
    }
    if (event.notes) {
      title += ` <i class="material-icons link-icon info-icon">info</i>`;
    }
    newModel.push({
      title: title,
      url:
        event.status === PersonalRecordExerciseSetWeekDayStatus.Completed
          ? commonNavigationConfig.training_details.url
          : commonNavigationConfig.exercise_program_training_session.url,
      id:
        event.status === PersonalRecordExerciseSetWeekDayStatus.Completed
          ? event.trainingScheduleId
          : type.toString(),
      extra: {
        queryParams: {
          weekNumber: event.weekNumber,
          dayNumber: event.dayNumber,
        },
      },
    });
  });
  return newModel;
}

export function getButtonsListMenuDataForTestingEvents(
  data: EventDto[],
  inactive: boolean = false,
): ButtonsListMenuModel[] {
  const newModel: ButtonsListMenuModel[] = [];
  data.forEach((event) => {
    const day = getDateAsDay(event.dateTime);
    const date = moment(event.dateTime).format('MM/DD');
    const time = moment(event.dateTime).format('h:mma');
    const title = `${day} <span class="v-splitter">|</span> ${date} <span class="v-splitter">|</span> ${time}`;
    newModel.push({
      title: title,
      id: event.extra.trainingScheduleId,
      inactive: inactive,
    });
  });
  return newModel;
}

export function getButtonsListMenuDataForHistory(
  data: TrainingPreview[],
  url: string,
): LinksMenuModel[] {
  const newModel: LinksMenuModel[] = [];
  data.forEach((event) => {
    let title = event.title;
    if (!event.checkedIn) {
      title += ' - No Data';
    }
    newModel.push({
      title: title,
      id: event.id,
      url: url,
      extra: {
        state: moment(event.dateTime).format('M/DD/YY'),
      },
    });
  });
  return newModel;
}

export function getDateAsDay(value: Date): string {
  const date = moment(value);
  const isCurrentDate = date.isSame(new Date(), 'day');
  const isTomorrowDate = date.isSame(moment().add(1, 'day'), 'day');
  if (isCurrentDate) {
    return 'Today';
  } else if (isTomorrowDate) {
    return 'Tomorrow';
  } else {
    return moment(value).format('dddd');
  }
}

export function getButtonsListMenuDataForTestsHistory(
  data: EventDto[],
  url: string,
): LinksMenuModel[] {
  const newModel: LinksMenuModel[] = [];
  data.forEach((event) => {
    const day = getDateAsDay(event.dateTime);
    const date = moment(event.dateTime).format('MM/DD');
    const title = `${day} ${
      date === 'Today' || date === 'Tomorrow' ? 'Test' : date
    }`;
    newModel.push({
      title: title,
      id: event.id,
      url: url,
    });
  });
  return newModel;
}

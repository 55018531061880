<div class="color-gradient-wrapper">
  <span *ngIf="title" class="gradient-title">{{ title }}</span>
  <div class="color-gradient">
    <div class="display-wrapper">
      <div class="display" [ngStyle]="{'background-image': 'linear-gradient(' + colorString + ')'}">
      </div>
      <div class="mark" *ngIf="value !== null" [ngStyle]="{'left': position + 'px'}">
      </div>
      
      <span class="mark-level">
        {{ level }}
      </span>
    </div>

    <div class="value-wrapper" *ngIf="value !== null" [ngStyle]="{'background': valueColor}">
      {{ value }}
    </div>
  </div>
</div>
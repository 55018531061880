import { NgModule } from '@angular/core';
import { InTakeComponent } from './in-take.component';
import { RouterModule } from '@angular/router';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { InTakeFlowComponent } from './in-take-flow/in-take-flow.component';
import { InTakeSectionComponent } from './in-take-section/in-take-section.component';
import { InTakeFieldComponent } from './in-take-field/in-take-field.component';
import { SplCoreModule } from '@common/co/core/core.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FlexModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { InTakeCompletionComponent } from './in-take-completion/in-take-completion.component';
import { DirectivesModule } from '@common/shared/directives/directives.module';
import { InTakeRoutes } from '@common/co/feature/in-take/in-take.routes';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    InTakeComponent,
    InTakeFlowComponent,
    InTakeSectionComponent,
    InTakeFieldComponent,
    InTakeCompletionComponent,
  ],
  imports: [
    InTakeRoutes,
    NgxJsonViewerModule,
    SplCoreModule,
    FuseSharedModule,
    FlexModule,
    NgxMaskModule,
    DirectivesModule,
    TranslateModule,
  ],
  exports: [RouterModule, InTakeComponent],
})
export class InTakeModule {}

import { Injectable } from '@angular/core';
import { StorageService } from '@common/services/storage.service';

@Injectable()
export class AppStateService {

  constructor(private storage: StorageService) {

  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async setState(key: string, state: any): Promise<boolean> {
    await this.storage.set({
      key: key,
      value: JSON.stringify(state),
    });
    return Promise.resolve(true);
  }

  public async getState(key: string): Promise<any> {
    const result = await this.storage.get({
      key: key,
    });
    return Promise.resolve(result);
  }

  public async removeState(key: string): Promise<any> {
    const result = await this.storage.remove({
      key: key,
    });
    return Promise.resolve(result);
  }
}

import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { InTakeService } from '@common/co/auth/services/in-take.service';
import { commonNavigationConfig } from '@common/co/navigation/navigation';
import { UserRegistrationService } from '@common/co/auth/services/user-registration.service';
import { AppBusService } from '@common/co/core/services/app-bus.service';

@Component({
  selector: 'in-take-completion',
  templateUrl: './in-take-completion.component.html',
  styleUrls: ['./in-take-completion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InTakeCompletionComponent {
  constructor(
    private _inTakeService: InTakeService,
    private _router: Router,
    private userRegistrationService: UserRegistrationService,
    private _appBusService: AppBusService,
  ) {}

  async createAnother(): Promise<void> {
    await this.userRegistrationService.createAnother();
    return Promise.resolve();
  }

  goToDashboard(): void {
    this._appBusService.setAppSettings(null);
    this._router.navigateByUrl(commonNavigationConfig.dashboard.url, {
      replaceUrl: true,
    });
    this._inTakeService.setInTakePassed(true);
  }
}

<div
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{
  suppressScrollX: true,
  updateOnRouteChange: true
  }"
  class='spl-scroll-container spl-scroll-container__ios-fix'
  id="in-take-flow-host">
  <in-take-flow [sections]="inTakeService.sectionsVM">
  </in-take-flow>
</div>

<input type="file" class="file-input"
       (change)="onFileSelected($event)" #fileUpload [multiple]="multiple" [accept]="accept">

<div class="file-upload-wrapper">
  <div class="file-upload" *ngIf="files.length === 0" (click)="fileUpload.click()">
    <mat-icon class="s-28">{{ matIcon }}</mat-icon><span>{{ title }}</span>
  </div>
  <div class="file-upload selected-files" *ngFor="let file of files">
    <mat-icon class="s-28" (click)="clear(file)">clear</mat-icon><span>{{ file.name }}</span>
  </div>
</div>
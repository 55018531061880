<div class="center">
  <!-- <div class="logo">
    <img width="128" src="assets/images/logos/spl.png" />
  </div> -->

  <!-- Material Design Spinner -->
  <div class="spinner-wrapper">
    <div class="spinner">
      <div class="inner">
        <div class="gap"></div>
        <div class="left">
          <div class="half-circle"></div>
        </div>
        <div class="right">
          <div class="half-circle"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Material Design Spinner -->
</div>

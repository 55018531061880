import * as dateFns from 'date-fns';

export class DateTimeHelper {
  public static getDateWithoutTime(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public static getDateWithAddedOffset(date: Date): Date {
    if (!date) return undefined;
    return dateFns.addMinutes(date, -date.getTimezoneOffset());
  }
}

<div class="select-list-wrapper">
  <span *ngIf="title" class="title mb-36">{{ title }}</span>
  <div class="list">
    <ng-container *ngFor="let option of list; let i = index;">
      <span *ngIf="!option.disabled" class="option" [ngClass]="{'selected': option.selected}" (click)="selectOption(option, i)">
        <span>{{ option.title }}</span>
        <mat-icon>check_circle</mat-icon>
      </span>
      <span *ngIf="option.disabled" class="option disabled">
        <span>{{ option.title }}</span>
      </span>
    </ng-container>
  </div>
</div>
<div class="check-unique-id-wrapper">
  <div class="title-section">
    <h1>
      {{ 'CHECK_UNIQ_ID.ADD_PROFILE' | translate }}
    </h1>
    <p>
      {{ 'CHECK_UNIQ_ID.YOU_CAN_LINK_EXISTING' | translate }}
    </p>
  </div>
  
  <register-unique-id-info [registerFields]="registerFields" (nextStep)="next($event)" 
  [buttonTitle]="'CHECK_UNIQ_ID.LINK_EXISTING' | translate">
  </register-unique-id-info>

  <span mat-button color="primary" class="go-back-button mb-12"
        (click)="createAnother()" 
        [attr.aria-label]="'CHECK_UNIQ_ID.OR_CREATE_NEW' | translate" 
        [matTooltip]="'CHECK_UNIQ_ID.OR_CREATE_NEW' | translate"
        >
    {{ 'CHECK_UNIQ_ID.OR_CREATE_NEW' | translate }}
  </span>
  <span mat-button color="primary" class="go-back-button"
        (click)="goBack()" 
        [attr.aria-label]="'COMMON.ACTIONS.GO_BACK' | translate" 
        [matTooltip]="'COMMON.ACTIONS.GO_BACK' | translate"
        >
        {{ 'COMMON.ACTIONS.GO_BACK' | translate }}
  </span>
</div>
<div class="solo-column user-preferences-wrapper">

  <div class="preferences-section">
    <span class="title">{{ 'COMMON.YOUR_LANGUAGE' | translate }}</span>
    <country-selector class="country-selector"
    [languages]="supportedLanguages"
    [selectedLang]="currentLanguage"
    (changeLanguage)="onLangChange($event)"
    ></country-selector>
  </div>
  
  <div class="preferences-section">
    <span class="title">{{ 'PREFERENCES.MEDICAL_COVERAGE' | translate }}</span>
    <medical-coverage></medical-coverage>
  </div>
</div>
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CustomDialogService } from '@common/shared/components/custom-dialog/custom-dialog.service';

@Component({
  selector: 'exercise-preview',
  templateUrl: './exercise-preview.component.html',
  styleUrls: ['./exercise-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExercisePreviewComponent {
  @Input() exerciseId: string | null;
  @Input() title: string | null;
  @Input() description: string | null;
  @Input() notes: string | null;
  @Input() video: string | null;
  @Input() poster: string | null;
  @Input() muted: boolean = false;

  constructor(private dialogService: CustomDialogService) {}

  public openNoteModal(): void {
    this.dialogService.showDialog({
      title: '',
      description: this.notes,
      confirmButtonText: 'Acknowledge',
      showCancelButton: false,
      dialogActionsAlign: 'center',
      confirmAction: () => {
        // this.managementService.readNotes();
      },
    });
  }
}

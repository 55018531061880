import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  HydrationLevelDto,
  HydrationRecordDto,
  HydrationTrackerClient,
  UpsertHydrationRecordCommand,
} from '@common/services/co-api-client';
import {
  addDays,
  addMonths,
  endOfMonth,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
  startOfMonth,
  subDays,
} from 'date-fns';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import {
  CalendarComponent,
  CalendarView,
} from '@progress/kendo-angular-dateinputs';
import moment from 'moment/moment';

@Component({
  selector: 'hydration-tracker',
  templateUrl: 'hydration-tracker.component.html',
  styleUrls: ['hydration-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HydrationTrackerComponent implements OnInit {
  @ViewChild('calendar')
  public calendar: CalendarComponent;

  public loadingPromise: Promise<void>;
  public isLoading: boolean = false;

  public maxDate: Date = endOfMonth(addMonths(new Date(), 1));
  public startRangeDate: Date;
  public endRangeDate: Date;
  public endOfCurrentDate: Date;

  public hydrationLevels: HydrationLevelDto[];
  public records: HydrationRecordDto[];
  public selectedRecord: HydrationRecordDto;

  public selectedDay: Date;
  public selectedUrineLevel: HydrationLevelDto;
  public selectedEnergyLevel: FormControl<number> = new FormControl<number>(1);

  constructor(
    private hydrationTrackerClient: HydrationTrackerClient,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.startRangeDate = startOfMonth(new Date());
    this.endRangeDate = endOfMonth(new Date());
    this.endOfCurrentDate = endOfMonth(new Date());
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.getHydrationLevels();
    this.loadingPromise = this.getHydrationTracker();
  }

  private getHydrationLevels(): void {
    this.hydrationTrackerClient.getHydrationLevels().subscribe((res) => {
      this.hydrationLevels = res.hydrationLevels.sort(
        (a, b) => a.level - b.level,
      );
      this.changeDetection();
    });
  }

  private async getHydrationTracker(): Promise<void> {
    const startDate = subDays(this.startRangeDate, 15);
    const endDate = addDays(this.endRangeDate, 15);
    try {
      this.isLoading = true;
      const res = await this.hydrationTrackerClient
        .getHydrationTracker(startDate, endDate)
        .toPromise();
      this.records = res.records;
    } finally {
      this.isLoading = false;
      this.changeDetection();
    }
  }

  public onSelectedDayChange(value: Date): void {
    this.selectedDay = value;
    if (
      !(
        isAfter(value, this.startRangeDate) &&
        isBefore(value, this.endRangeDate)
      )
    ) {
      this.startRangeDate = startOfMonth(this.selectedDay);
      this.endRangeDate = endOfMonth(this.selectedDay);
    }
    this.setSelectedRecord();
    this.changeDetection();
  }

  private setSelectedRecord(): void {
    this.selectedRecord = this.records.find((el) =>
      isEqual(el.recordDate, this.selectedDay),
    );
    if (this.selectedRecord) {
      this.selectedEnergyLevel.setValue(
        this.selectedRecord.perceivedEnergyLevel,
      );
      this.selectedUrineLevel = this.selectedRecord.hydrationLevel;
    } else {
      this.selectedEnergyLevel.setValue(1);
      this.selectedUrineLevel = undefined;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onMonthChange(event: any): void {
    const focusedDate = event.focusedDate;
    if (
      isBefore(focusedDate, new Date()) &&
      !isEqual(this.startRangeDate, startOfMonth(focusedDate))
    ) {
      this.startRangeDate = startOfMonth(focusedDate);
      this.endRangeDate = endOfMonth(focusedDate);
      this.getHydrationTracker();
    }
  }

  public onActiveViewChange(view: CalendarView): void {
    if (view == 'month') {
      const focusedDate = this.calendar.multiViewCalendar.focusedDate;
      if (moment(focusedDate).isBefore(moment())) {
        this.startRangeDate = startOfMonth(focusedDate);
        this.endRangeDate = endOfMonth(focusedDate);
        this.getHydrationTracker();
      }
    }
  }

  public closeInputs(): void {
    this.selectedDay = undefined;
    this.selectedUrineLevel = undefined;
    this.selectedEnergyLevel.setValue(1);
  }

  public selectUrineLevel(level: HydrationRecordDto): void {
    this.selectedUrineLevel = level;
  }

  public submit(): void {
    const command: UpsertHydrationRecordCommand =
      new UpsertHydrationRecordCommand({
        id: this.selectedRecord?.id,
        recordDate: this.selectedDay,
        perceivedEnergyLevel: this.selectedEnergyLevel.value,
        hydrationLevelId: this.selectedUrineLevel.id,
      });
    this.isLoading = true;
    this.hydrationTrackerClient.upsertHydrationRecord(command).subscribe(
      (res) => {
        this.selectedRecord = res.record;
        const foundRecordIndex = this.records.findIndex(
          (el) => el.id === res.record?.id,
        );
        if (foundRecordIndex > -1) {
          this.records[foundRecordIndex] = res.record;
        } else {
          this.records.push(res.record);
        }
        this.isLoading = false;
        this.changeDetection();
      },
      (error) => {
        this.isLoading = false;
        this.changeDetection();
        throw error;
      },
    );
  }

  public resolveDayCellStyles(date: Date): any {
    const classesList = {
      dayCell: true,
      disabledCell: false,
      missingCell: false,
      currentDate: false,
      futureCell: false,
      hasRecordCell: false,
    };
    const now = new Date();
    const inPastMonth = isBefore(date, this.startRangeDate);
    const inFuture = isAfter(date, now);
    const inFutureMonth = isAfter(date, this.endRangeDate);

    const hasSleepRecord = !!this.getSleepRecord(date);

    if (isEqual(startOfDay(now), startOfDay(date))) {
      classesList.currentDate = true;
    }
    if (inPastMonth || inFutureMonth) {
      classesList.disabledCell = true;
    }
    if (inFuture) {
      classesList.futureCell = true;
    }
    if (!inFuture && !hasSleepRecord) {
      classesList.missingCell = true;
    }
    if (hasSleepRecord) {
      classesList.hasRecordCell = true;
    }

    return classesList;
  }

  private getSleepRecord(date: Date): HydrationRecordDto {
    return this.records.find((d) => isEqual(d.recordDate, date));
  }

  public getActiveDayColor(date: Date): { [p: string]: any } | void {
    const record = this.getSleepRecord(date);
    if (!record) return;
    return {
      'background-color': record.hydrationLevel.hydrationColor,
    };
  }

  public disabledDates = (date: Date): boolean => {
    const now = new Date();
    const earlier = isBefore(date, this.startRangeDate);
    const later = isAfter(date, now);
    return earlier || later;
  };

  public get isValid(): boolean {
    return (
      !_.isNil(this.selectedUrineLevel) &&
      !_.isNil(this.selectedEnergyLevel.value)
    );
  }

  public get recordWasChanged(): boolean {
    return (
      this.selectedRecord?.hydrationLevel?.level !==
        this.selectedUrineLevel?.level ||
      this.selectedRecord?.perceivedEnergyLevel !==
        this.selectedEnergyLevel.value
    );
  }

  private changeDetection(): void {
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }
}

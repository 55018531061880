<div class="exercise-wrapper">
  <div class="exercise-title-wrapper">
    <span class="small-title mb-4" [innerHTML]="title"></span>
    <mat-icon class="copy-versions" *ngIf="notes" (click)="openNoteModal()">description</mat-icon>
  </div>
  <span class='spl-info-text'>{{ description }}</span>
  <div class="spl-video-wrapper">
    <bitmovin-player-poster *ngIf="!!video" [video]="video" [poster]="poster" [muted]="muted" [relatedObjectId]="exerciseId"></bitmovin-player-poster>
    <div *ngIf="!video" class="video-wrapper__no-video">Video not available</div>
  </div>
</div>

<mat-form-field appearance="outline"
                class="w-100-p"
                *ngIf="field.type === fieldTypes.Date">
  <mat-label>
    {{ field.caption }}
  </mat-label>
  <input matInput
         [name]="field.id"
         [formControl]="control"
         [required]="field.required"
         [value]="(field.answer?.value || field.firstDisplayAnswer)"
         [matDatepicker]="datePicker"
         [max]="maxDate" />
  <mat-datepicker-toggle matSuffix
                         [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker></mat-datepicker>
  <mat-error *ngIf="control.invalid && (control.dirty || control.touched) && control.errors?.required">
    {{ !control.hasError('matDatepickerParse') ?
    'Field \'' + field.caption + '\' is required.' : 'Please enter a valid \'' + field.caption + '\'.'}}
  </mat-error>
  <mat-error *ngIf="control.invalid && (control.dirty || control.touched) && !control.errors?.required
    && control.errors?.regex">
    {{ (control.errors.regex.value || 'Field ' + field.caption + 'is invalid.') }}
  </mat-error>
  <mat-error *ngIf="control.invalid && (control.dirty || control.touched) && control.errors?.matDatepickerMax">
    {{ 'Field \'' + field.caption + '\' is invalid.' }}
  </mat-error>
</mat-form-field>

<mat-form-field appearance="outline"
                class="w-100-p"
                *ngIf="field.type === fieldTypes.DropDown">
  <mat-label>
    {{ field.caption }}
  </mat-label>
  <mat-select
              [formControl]="control"
              [required]="field.required"
              [(value)]="selected">
    <mat-option *ngFor="let option of field.extraData?.dropDownData?.options"
                [disabled]="option.disabled"
                [value]="option.value">
      {{ option.caption }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="control.invalid && (control.dirty || control.touched) && control.errors?.required">
    Field '{{ field.caption }}' is required.
  </mat-error>
  <mat-error *ngIf="control.invalid && (control.dirty || control.touched) && !control.errors?.required
    && control.errors?.regex">
    {{ (control.errors.regex.value || 'Field ' + field.caption + 'is invalid.') }}
  </mat-error>
</mat-form-field>

<div appearance="outline"
                class="w-100-p h-36"
                *ngIf="field.type === fieldTypes.Checkbox">
  <mat-checkbox
                color="primary"
                [name]="field.id"
                [formControl]="control"
                [required]="field.required"
                [value]="undefined"
                [checked]="undefined">
    {{ field.caption + (field.required ? " *" : "") }}
    <mat-error *ngIf="control.invalid && (control.dirty || control.touched) && control.errors?.required">
      Field '{{ field.caption }}' is required.
    </mat-error>
    <mat-error *ngIf="control.invalid && (control.dirty || control.touched) && !control.errors?.required
      && control.errors?.regex">
      {{ (control.errors.regex.value || 'Field ' + field.caption + 'is invalid.') }}
    </mat-error>
  </mat-checkbox>
</div>

<mat-form-field appearance="outline"
                class="w-100-p"
                *ngIf="field.type === fieldTypes.None || field.type === fieldTypes.Text">
  <mat-label>
    {{ field.caption }}
  </mat-label>
  <input matInput
         trimOnBlur
         [name]="field.id"
         [formControl]="control"
         [required]="field.required"
         [value]="(field.answer?.value || (field.firstDisplayAnswer ? field.firstDisplayAnswer : ''))"
         [mask]="field.extraData?.mask"
         [validation]="true"
         [placeholder]="field.extraData?.mask" />
  <mat-error *ngIf="control.invalid && (control.dirty || control.touched) && control.errors?.required">
    Field '{{ field.caption }}' is required.
  </mat-error>
  <mat-error *ngIf="control.invalid && (control.dirty || control.touched) && !control.errors?.required
      && control.errors?.regex">
    {{ (control.errors.regex.value || 'Field ' + field.caption + 'is invalid.') }}
  </mat-error>
</mat-form-field>

import { Component, ViewEncapsulation, Inject } from '@angular/core';
import {
  INavigationConfig,
  NAVIGATION_CONFIG,
} from '@common/co/navigation/navigation';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { SplNavigationService } from '@common/co/core/components/navigation/navigation.service';
import { RegisterCommand } from '@common/services/co-api-client';
@Component({
  selector: 'check-unique-id',
  templateUrl: './check-unique-id.component.html',
  styleUrls: ['./check-unique-id.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckUniqueIdComponent {
  registerFields: any = {};

  /**
   * Constructor
   *
   * @param navigationConfig
   */
  constructor(
    @Inject(NAVIGATION_CONFIG) private navigationConfig: INavigationConfig,
    private fuseProgressBar: FuseProgressBarService,
    private _authorizeService: AuthorizeService,
    public splNavigationService: SplNavigationService,
  ) {}

  async next(data: RegisterCommand): Promise<void> {
    this.fuseProgressBar.show();
    await this._authorizeService.createAnotherUniqueId(
      data.userRegistrationValidation.uniqueId,
      data.userRegistrationValidation.dateOfBirth,
    );
    this.fuseProgressBar.hide();
  }

  public createAnother(): void {
    this._authorizeService.createAnother();
  }

  public goBack(): void {
    this.splNavigationService.goBack();
  }
}

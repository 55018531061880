import { Component, Input } from '@angular/core';
import { CustomDialogService } from '@common/shared/components/custom-dialog/custom-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'notes-info',
  templateUrl: './notes-info.component.html',
  styleUrls: ['./notes-info.component.scss'],
})
export class NotesInfoComponent {
  @Input() notes: string;
  @Input() isNewNotes: boolean = false;
  public viewInited: boolean = false;
  constructor(
    private dialogService: CustomDialogService,
    private _translateService: TranslateService,
  ) {}

  public showNotes(): void {
    this.dialogService.showDialog({
      title: '',
      description: this.notes,
      confirmButtonText:
        this._translateService.instant('COMMON.ACTIONS.ACKNOWLEDGE') ||
        'Acknowledge',
      showCancelButton: false,
      dialogActionsAlign: 'center',
      confirmAction: () => {
        // this.managementService.readNotes();
      },
    });
  }
}

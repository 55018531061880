<div class="title-section">
  <h1>
    {{ 'COMMON.PERSONAL_INFO.YOUR_PERSONAL_INFO' | translate }}    
  </h1>
  <p>
    {{ 'PERSONAL_INFO.ENTER_YOUR_PERSONAL_INFO' | translate }}
  </p>
  
  <p class="important">
    {{ 'PERSONAL_INFO.IF_YOU_CREATING_FOR_YOUTH' | translate }}
  </p>
</div>

<form [formGroup]="registerForm">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'COMMON.PERSONAL_INFO.YOUR_FIRST_NAME' | translate }}</mat-label>
    <input trimOnBlur matInput [formControl]="registerForm.controls['firstName']" />
    <mat-error *ngIf="registerForm.get('firstName').hasError('required')">
      {{ 'COMMON.PERSONAL_INFO.FIRST_NAME_REQUIRED' | translate }}   
    </mat-error>
    <mat-error *ngIf="
                !registerForm.get('firstName').hasError('required') &&
                registerForm.get('firstName').hasError('firstName')
              ">
      {{ 'COMMON.PERSONAL_INFO.FIRST_NAME_INVALID' | translate }}   
    </mat-error>
  </mat-form-field>
  
  <mat-form-field appearance="outline">
    <mat-label>{{ 'COMMON.PERSONAL_INFO.YOUR_LAST_NAME' | translate }}</mat-label>
    <input trimOnBlur matInput [formControl]="registerForm.controls['lastName']" />
    <mat-error *ngIf="registerForm.get('lastName').hasError('required')">
      {{ 'COMMON.PERSONAL_INFO.LAST_NAME_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="
                !registerForm.get('lastName').hasError('required') &&
                registerForm.get('lastName').hasError('lastName')
              ">
      {{ 'COMMON.PERSONAL_INFO.LAST_NAME_INVALID' | translate }}
    </mat-error>
  </mat-form-field>
  
  <mat-form-field appearance="outline">
    <mat-label>{{ 'COMMON.PERSONAL_INFO.YOUR_DATE_OF_BIRTH' | translate }}</mat-label>
    <input matInput [formControl]="registerForm.controls['dateOfBirth']" [matDatepicker]="datePicker"
      [max]="maxDateOfBirth" showValidationInfo (blur)="validationDateOfBirth()"/>
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    <mat-error *ngIf="registerForm.get('dateOfBirth').hasError('required')">
      {{ !registerForm.get('dateOfBirth').hasError('matDatepickerParse') 
        ? ('COMMON.PERSONAL_INFO.DATE_OF_BIRTH_REQUIRED' | translate)  
        : ('COMMON.PERSONAL_INFO.DATE_OF_BIRTH_INVALID' | translate)
      }}
    </mat-error>
    <mat-error *ngIf="registerForm.get('dateOfBirth').hasError('dateOfBirthToSmall')">
      {{ 'COMMON.PERSONAL_INFO.DATE_OF_BIRTH_MINIMAL' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'COMMON.PERSONAL_INFO.ZIP_CODE' | translate }}</mat-label>
    <input trimOnBlur matInput [formControl]="registerForm.controls['zip']" />
    <mat-error *ngIf="registerForm.get('zip').hasError('required')">
      {{ 'COMMON.PERSONAL_INFO.ZIP_CODE_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
</form>

<button mat-button class="spl-button center mt-16 mb-16"
        (click)="next()"
        [disabled]="!completed" 
        [attr.aria-label]="'COMMON.ACTIONS.CONTINUE' | translate" 
        [matTooltip]="'COMMON.ACTIONS.CONTINUE' | translate"
        >
        {{ 'COMMON.ACTIONS.CONTINUE' | translate }}
</button>

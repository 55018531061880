import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { SplNotification } from '@common/co/core/models/splNotification.model';
import { NotificationService } from '@common/co/core/services/notification.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SplNotification,
    private notificationService: NotificationService,
    private _platform: Platform,
  ) {}

  close(): void {
    this.notificationService.dismiss();
  }

  doAction(): void {
    if (this._platform.ANDROID || this._platform.IOS) {
      if (this.data.source === 'push') {
        this.notificationService.resolve(this.data.relatedObject);
        this.close();
      }
    } else {
      this.close();
    }
  }
}

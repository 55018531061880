import { Component, Inject, Input, OnChanges } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { COLORS, IColorConstants } from '@common/co/core/app.constants';
import { AthleteAssessmentSAQDto } from '@common/services/co-api-client';

@Component({
  selector: 'spl-radar-chart',
  templateUrl: './spl-radar-chart.component.html',
  styleUrls: ['./spl-radar-chart.component.scss'],
})
export class SplRadarChartComponent implements OnChanges {
  @Input() saqData: AthleteAssessmentSAQDto;
  @Input() radarChartData: ChartConfiguration<'radar'>['data']['datasets'];
  @Input() valuesConfig: { min: number; max: number };
  // Radar
  public radarChartOptions: ChartConfiguration<'radar'>['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        angleLines: {
          display: true,
          color: 'rgba(111, 111, 111, 1)',
        },
        pointLabels: {
          font: {
            size: 11,
          },
          color: this.colors.radarChartFontColor,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        grid: {
          color: 'rgba(111, 111, 111, 1)',
        },
        ticks: {
          stepSize: 20,
        },
      },
    },
    elements: {
      arc: {
        backgroundColor: 'black',
      },
    },
  };
  public radarChartLabels: string[] = [
    'Explosiveness',
    'Reaction',
    'Proprioception/Accuracy',
    'Movement Efficiency',
    'Symmetry',
  ];

  constructor(@Inject(COLORS) private colors: IColorConstants) {}

  ngOnChanges(): void {
    console.log(this.radarChartData);
    if (this.valuesConfig) {
      this.radarChartOptions.scales.r.suggestedMin = this.valuesConfig.min;
      this.radarChartOptions.scales.r.suggestedMax = this.valuesConfig.max;
      this.radarChartOptions.scales.r.ticks.stepSize = Math.trunc(
        this.valuesConfig.max / 5,
      );
    }
  }
}

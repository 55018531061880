import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { NavigationModule } from '@common/co/core/components/navigation/navigation.module';
import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarVerticalStyle2Component } from 'app/layout/spl-athletes-app-layout/components/navbar/vertical/style-2/style-2.component';

@NgModule({
  declarations: [NavbarVerticalStyle2Component],
  imports: [
    MatButtonModule,
    MatIconModule,

    FuseSharedModule,
    NavigationModule,
  ],
  exports: [NavbarVerticalStyle2Component],
})
export class NavbarVerticalStyle2Module {}

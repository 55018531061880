import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@common/co/auth/components/login/login.component';
import { RegisterComponent } from '@common/co/auth/components/register/register.component';
import { SelectProfileComponent } from './components/login/components/select-profile/select-profile.component';
import { AuthorizeGuard } from './guards/authorize.guard';
import { AuthorizedUserGuard } from '@common/co/auth/guards/authorized-user.guard';
import { ExternalLoginComponent } from '@common/co/auth/components/external-login/external-login.component';
import { ChangePasswordComponent } from '@common/co/auth/components/register/components/change-password/change-password.component';
import { ForgotPasswordComponent } from '@common/co/auth/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@common/co/auth/components/reset-password/reset-password.component';
import { CheckUniqueIdComponent } from '@common/co/auth/components/login/components/check-unique-id/check-unique-id.component';
import { commonNavigationConfig } from '@common/co/navigation/navigation';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthorizedUserGuard],
  },
  {
    path: 'external-login',
    component: ExternalLoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AuthorizedUserGuard],
  },
  {
    path: 'check-unique-id',
    component: CheckUniqueIdComponent,
    canActivate: [AuthorizeGuard],
  },
  {
    path: 'select-profile',
    component: SelectProfileComponent,
    canActivate: [AuthorizeGuard],
    data: commonNavigationConfig.switch_profile.payload,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthorizeGuard],
    data: commonNavigationConfig.change_password.payload,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
];

/* @export
 * @class AuthRoutingModule
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutes {}

<ng-container 
  *ngFor="let section of sections">
  <in-take-section *ngIf="section.active"
                   [section]="section">
  </in-take-section>
</ng-container>

<!-- <ngx-json-viewer *ngIf="!environment.production"
                 [json]="sections"
                 [expanded]="false">
</ngx-json-viewer> -->

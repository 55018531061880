export enum SkeletonModeType {
  ByScreen,
  ByContent // For this type to work correctly, a min-height must be set.
}

export enum SkeletonType {
  Default,
  AssessmentHeader,
  InfoBlock,
  TrainingSession
}

import { NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl =
    'sportsperformancetechnologiesllc.zendesk.com?key=7659964c-369a-4db2-84cf-79823874c307';
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  callback(zE: any): void {
    zE('messenger:set', 'zIndex', -1);
    zE('messenger:set', 'locale', 'en-US');
  }
}

import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { SplCountdownManagementService } from '@common/co/core/components/spl-countdown/spl-countdown.management-service';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import {
  EventAnnounceResponse,
  EventPreviewDetailsRecord,
  InStudioClient,
} from '@common/services/co-api-client';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import moment from 'moment';
import { map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EventType } from '@common/services/co-api-client';
import { CountdownEventTypeModel } from '@common/co/shared/models/countdown-event-type.model';
import { AppStateService } from '@common/co/core/services/app-state.service';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { CountdownMessageModel } from '@common/co/shared/models/countdown-message.model';
import { EventsQueueModel } from '@common/co/shared/models/events-queue.model';
import { LoggingService } from '@common/services/logging.service';
import {
  commonNavigationConfig,
  INavigationConfig,
  NAVIGATION_CONFIG,
} from '@common/co/navigation/navigation';
import {
  APPLICATION_DICTIONARY_SERVICE,
  IApplicationDictionaryService,
} from '@common/services/iapplication-dictionary-service';

interface CountdownValue {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

@Injectable()
export class SplCountdownService implements OnDestroy {
  changeDetectionEmitter: EventEmitter<void> = new EventEmitter<void>();
  private readonly STORAGE_ACTIVE_TRAINING_EVENTS = 'active-training-events';

  public countdown: CountdownValue;
  public event: EventAnnounceResponse;
  public description: string;
  public showGetStartedMessage: boolean = false;
  public mobileLayout: boolean = false;
  public eventSubject: Subject<boolean> = new Subject<boolean>();
  public countDownIntervalSubject: Subject<any>;
  public eventIsOver: Subject<{
    event: EventsQueueModel;
    nextEvent: EventsQueueModel;
  }> = new Subject();

  private countDownInterval = undefined;
  private _unsubscribeAll: Subject<any>;
  private isActive: boolean;
  private countdownIsStarted: boolean = false;
  private eventsQueue: EventsQueueModel[] = [];
  private countdownIsEmpty: boolean = false;

  public showCountdownMessage$ = new BehaviorSubject(undefined);

  constructor(
    @Inject(APPLICATION_DICTIONARY_SERVICE)
    private applicationDictionaryService: IApplicationDictionaryService,
    @Inject(NAVIGATION_CONFIG) private navigationConfig: INavigationConfig,
    private splCountdownManagementService: SplCountdownManagementService,
    private _appBusService: AppBusService,
    private router: Router,
    private _appState: AppStateService,
    private _authService: AuthorizeService,
    private _inStudioClient: InStudioClient,
    private loggingService: LoggingService,
  ) {
    this._unsubscribeAll = new Subject();

    this._appBusService.isActive
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isActive) => {
        this.isActive = isActive;
        if (this._authService.currentProfileId) {
          this.resolveGetEvents();
        }
      });

    this._authService
      .isAuthenticated()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isAuth) => {
        if (!isAuth) {
          this._appState.removeState(this.STORAGE_ACTIVE_TRAINING_EVENTS);
        }
      });

    this._appBusService.scheduleChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        if (value) {
          this.updateCountdown();
        }
      });

    this._appBusService.setProfile$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.updateCountdown();
        this.hideCountdownMessage();
      });
  }

  private resolveGetEvents(): void {
    this.loggingService.logFunctionStart({
      isActive: this.isActive,
      countdownIsStarted: this.countdownIsStarted,
      eventsQueueLength: this.eventsQueue.length,
    });
    if (
      this.isActive &&
      !this.countdownIsStarted &&
      this.eventsQueue.length === 0 &&
      !this.countdownIsEmpty
    ) {
      this.countdownIsStarted = true;
      this.getEvents();
      return;
    }
    if (
      this.isActive &&
      !this.countdownIsStarted &&
      this.eventsQueue.length > 0
    ) {
      this.calculateDiff();
      return;
    }
    if (!this.isActive && this.countdownIsStarted) {
      this.stopCountdown();
    }
    this.loggingService.logFunctionEnd({
      countdownIsStarted: this.countdownIsStarted,
    });
  }

  public stopCountdown(): void {
    this.loggingService.logFunctionStart();
    this.countdownIsStarted = false;
    this.countDownInterval = undefined;
    if (this.countDownIntervalSubject) {
      this.countDownIntervalSubject.unsubscribe();
      this.loggingService.logTrace('Countdown Service - countdown Was Stopped');
    }
  }

  private async getEvents(): Promise<void> {
    await this.loggingService.logFunctionStart();
    const restoredEventsQueueFromStorage =
      await this.restoreEventsQueueFromStorage();
    this.eventsQueue = this.filterQueueByCurrentTime(
      restoredEventsQueueFromStorage,
    );
    this.splCountdownManagementService.getEvents().then(async (event) => {
      this.event = event;

      if (!this.event.next?.details?.extra?.trainingScheduleId) {
        this.event.next = null;
      }
      if (!this.event.current?.details?.extra?.trainingScheduleId) {
        this.event.current = null;
      }

      // set the current event if the training was started in advance
      if (
        !this.event.current &&
        (this.currentEvent?.type === CountdownEventTypeModel.Exercise ||
          this.currentEvent?.type === CountdownEventTypeModel.Rest) &&
        this.event.next
      ) {
        this.event.current = this.event.next;
        this.event.next = undefined;
      }

      this.countdownIsEmpty = !this.event.current && !this.event.next;
      this.loggingService.logTrace(
        `Countdown Service - Event from Backend in {${this.getEvents.name}}`,
        {
          event: JSON.stringify(event),
          restoredEventsQueueFromStorage: this.eventsQueue,
        },
      );
      if (this.event.current) {
        this.checkDisplayMessage(this.event.current);
      }
      this.eventSubject.next(true);
      if (this.eventsQueue.length === 0) {
        this.processEvents();
      } else {
        this.calculateDiff();
      }
    });
  }

  private processEvents(): void {
    this.loggingService.logFunctionStart({
      currentEvent: JSON.stringify(this.event.current),
      nextEvent: JSON.stringify(this.event.next),
    });
    if (this.event?.current?.details?.detailsRecords?.length > 0) {
      this.setQueueByDetails(
        this.event.current.details.detailsRecords,
        this.event.current.eventTime,
      );
    }
    if (this.event.next) {
      this.eventsQueue.push({
        description: this.applicationDictionaryService.getTextByTemplate(
          this.event.next.descriptionTemplate,
          this.event.next.descriptionToken,
          this.applicationDictionaryService.Training,
        ),
        eventTime: this.event.next.eventTime,
        type: this.event.next.type,
        trainingScheduleId: this.event.next.details?.extra?.trainingScheduleId,
      });
      if (this.event.next.details?.detailsRecords.length > 0) {
        this.setQueueByDetails(
          this.event.next.details.detailsRecords,
          this.event.next.eventTime,
        );
      }
    }
    if (!this.event.current && !this.event.next) {
      this.countdown = undefined;
      this.countdownIsStarted = false;
      return;
    }
    this.eventsQueue = this.filterQueueByCurrentTime(this.eventsQueue);
    if (this.eventsQueue.length > 0) {
      this.calculateDiff();
      this._appState.setState(
        this.STORAGE_ACTIVE_TRAINING_EVENTS,
        this.eventsQueue,
      );
    }
    this.loggingService.logFunctionEnd({
      eventsQueue: JSON.stringify(this.eventsQueue),
    });
    console.log(this.eventsQueue);
  }

  private async restoreEventsQueueFromStorage(): Promise<EventsQueueModel[]> {
    const eventsQueueFromStorage = (
      await this._appState.getState(this.STORAGE_ACTIVE_TRAINING_EVENTS)
    ).value;
    if (!eventsQueueFromStorage) return [];
    const eventsQueue = JSON.parse(eventsQueueFromStorage);
    if (!eventsQueue || eventsQueue.length === 0) return [];
    return eventsQueue;
  }

  setQueueByDetails(
    detailsRecords: EventPreviewDetailsRecord[],
    parentEventTime: Date,
  ): void {
    this.loggingService.logFunctionStart();
    detailsRecords.sort(function (a, b) {
      return a.seq - b.seq;
    });
    for (let i = 0; i < detailsRecords.length; i++) {
      const event: EventsQueueModel = {
        description: detailsRecords[i].description + ' for',
        eventTime: moment(parentEventTime)
          .add(
            detailsRecords[i].duration + detailsRecords[i].timeOffset,
            'minutes',
          )
          .toDate(),
        type: CountdownEventTypeModel.Exercise,
        stationId: detailsRecords[i].stationId,
        displayName: detailsRecords[i].description,
        lastEvent: i === detailsRecords.length - 1,
      };
      this.eventsQueue.push(event);

      if (detailsRecords[i + 1]) {
        const nextEventTime = moment(parentEventTime).add(
          detailsRecords[i + 1].duration + detailsRecords[i + 1].timeOffset,
          'minutes',
        );
        const startCurrentEvent = moment(nextEventTime).subtract(
          detailsRecords[i + 1].duration,
          'minutes',
        );
        const diff = startCurrentEvent.diff(event.eventTime, 'minutes');
        if (diff > 0) {
          this.eventsQueue.push({
            description: detailsRecords[i + 1].description + ' starts in',
            eventTime: moment(event.eventTime).add(diff, 'minutes').toDate(),
            type: CountdownEventTypeModel.Rest,
            stationId: detailsRecords[i + 1].stationId,
            displayName: detailsRecords[i].description,
          });
        }
      }
    }
    this.loggingService.logFunctionEnd();
  }

  private calculateDiff(): void {
    this.loggingService.logFunctionStart();
    this.countdownIsStarted = true;
    const currDate = moment();
    const eventDate = moment(this.currentEvent?.eventTime);
    const diff = eventDate.diff(currDate, 'seconds');
    if (diff > 0) {
      this.description = this.currentEvent?.description;
      this.setCountdown(diff);
    } else {
      this.processEventsAfterEndEvent();
    }
    this.loggingService.logFunctionEnd();
  }

  private filterQueueByCurrentTime(
    eventsQueue: EventsQueueModel[],
  ): EventsQueueModel[] {
    const currDate = moment();
    return eventsQueue.filter((event) => {
      const eventDate = moment(event.eventTime);
      const diff = eventDate.diff(currDate, 'seconds');
      if (event.lastEvent && diff < 0) {
        this.setTrainingIsFinished();
      }
      return diff > 0;
    });
  }

  private setCountdown(diff: number): void {
    this.loggingService.logFunctionStart();
    // Calculate the remaining time for the first time so there will be no
    // delay on the countdown
    this.countdown = this._secondsToRemaining(diff);
    this.changeDetectionEmitter.emit();

    // Create a subscribable interval

    if (this.countDownIntervalSubject) {
      this.countDownInterval = undefined;
      this.countDownIntervalSubject.unsubscribe();
    }
    this.countDownInterval = interval(1000).pipe(
      map(() => {
        return (diff = diff - 1);
      }),
      map((value) => {
        return this._secondsToRemaining(value);
      }),
    );

    // Subscribe to the countdown interval
    this.countDownIntervalSubject = this.countDownInterval.subscribe(
      (value) => {
        this.countdown = value;
        this.changeDetectionEmitter.emit();

        if (!this.countdown) {
          this.processEventsAfterEndEvent();
        }
      },
    );
  }

  private async processEventsAfterEndEvent(): Promise<void> {
    this.loggingService.logFunctionStart({
      event: JSON.stringify(this.currentEvent),
      nextEvent: JSON.stringify(this.nextEvent),
    });
    this.eventIsOver.next({
      event: this.currentEvent,
      nextEvent: this.nextEvent,
    });
    this.stopCountdown();
    if (this.currentEvent?.type) {
      this.resolveEvent();
    }
    if (this.currentEvent?.lastEvent) {
      await this.setTrainingIsFinished();
    }
    this.eventsQueue.shift();
    this.loggingService.logTrace(
      'Countdown Service - events Queue After Switch Event To Next',
      {
        eventsQueue: JSON.stringify(this.eventsQueue),
      },
    );
    this._appState.setState(
      this.STORAGE_ACTIVE_TRAINING_EVENTS,
      this.eventsQueue,
    );
    if (this.eventsQueue.length > 0) {
      this.calculateDiff();
    } else {
      this.stopCountdown();
      await this.clearCountdown();
      this.getEvents();
    }
    this.loggingService.logFunctionEnd();
  }

  public toEndCurrentEvent(): void {
    this.loggingService.logFunctionStart();
    this.processEventsAfterEndEvent();
  }

  private async setTrainingIsFinished(): Promise<void> {
    this.loggingService.logFunctionStart({
      trainingScheduleId:
        this.event?.current?.details?.extra?.trainingScheduleId,
      event: JSON.stringify(this.event),
    });
    if (this.event) {
      const res = await this._inStudioClient
        .finishTraining(this.event.current?.details?.extra?.trainingScheduleId)
        .toPromise();
      if (!res.success) {
        const error = new Error(res.reason);
        this.loggingService.logException(error);
      }
      this.event.current = null;
    }
    this._appBusService.setTrainingState(null);
    this.hideCountdownMessage();
    this.loggingService.logFunctionEnd({
      event: JSON.stringify(this.event),
    });
  }

  private _secondsToRemaining(seconds): CountdownValue {
    const timeLeft = moment.duration(seconds, 'seconds');

    if (seconds > 0) {
      return {
        days: Math.floor(timeLeft.asDays()),
        hours: Math.floor(timeLeft.hours()),
        minutes: Math.floor(timeLeft.minutes()),
        seconds: Math.floor(timeLeft.seconds()),
      };
    } else {
      return undefined;
    }
  }

  private resolveEvent(): void {
    switch (this.currentEvent?.type) {
      case EventType.InStudioTraining:
        if (!this.event.current && this.event.next) {
          this.event.current = this.event.next;
        }
        if (this.router.url === commonNavigationConfig.training.url) {
          this.router.navigateByUrl(
            commonNavigationConfig.training_session.url,
          );
        } else {
          this.prepareEventMessage(this.currentEvent?.type);
        }
        break;
    }
  }

  public async updateCountdown(): Promise<void> {
    this.loggingService.logFunctionStart();
    this.stopCountdown();
    await this.clearCountdown();
    this.getEvents();
    this.loggingService.logFunctionEnd();
  }

  private checkDisplayMessage(currentEvent): void {
    if (
      moment(currentEvent.eventTime).valueOf() < moment().valueOf() ||
      this.currentEvent?.type === CountdownEventTypeModel.Exercise ||
      this.currentEvent?.type === CountdownEventTypeModel.Rest
    ) {
      this.prepareEventMessage(currentEvent.type);
    }
  }

  public continue(stationId: string): void {
    this.loggingService.logFunctionStart({
      stationId,
      eventsQueue: JSON.stringify(this.eventsQueue),
    });
    if (stationId === this.currentEvent?.stationId) {
      if (this.nextEvent?.type === CountdownEventTypeModel.Exercise) {
        const event: EventsQueueModel = {
          description: this.nextEvent?.displayName + ' starts in',
          eventTime: moment()
            .add(this.countdown.seconds, 'seconds')
            .add(this.countdown.minutes, 'minutes')
            .add(this.countdown.hours, 'hours')
            .toDate(),
          type: CountdownEventTypeModel.Rest,
          stationId: this.nextEvent?.stationId,
          displayName: this.nextEvent?.displayName,
        };
        this.eventsQueue.splice(1, 0, event);
      }
      this.processEventsAfterEndEvent();
    }
    this.loggingService.logFunctionEnd({
      eventsQueue: JSON.stringify(this.eventsQueue),
    });
  }

  public get currentEvent(): EventsQueueModel | undefined {
    if (this.eventsQueue !== null && this.eventsQueue !== undefined) {
      return this.eventsQueue[0];
    }
    return undefined;
  }

  public get nextEvent(): EventsQueueModel | undefined {
    if (this.eventsQueue !== null && this.eventsQueue !== undefined) {
      return this.eventsQueue[1];
    }
    return undefined;
  }

  public async clearCountdown(): Promise<void> {
    this.loggingService.logFunctionStart();
    this.eventsQueue = [];
    await this._appState.removeState(this.STORAGE_ACTIVE_TRAINING_EVENTS);
    this.splCountdownManagementService.clearEvent();
    this.countdown = undefined;
    this.isActive = true;
    this.loggingService.logFunctionEnd();
  }

  public prepareEventMessage(eventType: number): void {
    this.loggingService.logFunctionStart();
    switch (eventType) {
      case EventType.InStudioTraining:
        if (
          !this.router.url.includes(
            commonNavigationConfig.training_session.url,
          ) &&
          this.event?.current?.details?.detailsRecords.length > 0
        ) {
          const messageConfig: CountdownMessageModel = {
            message: 'Your training has begun.',
            buttonTitle: 'Get started',
            redirectUrl: commonNavigationConfig.training_session.url,
            eventType: EventType.InStudioTraining,
          };
          this.showCountdownMessage(messageConfig);
        }
        break;
    }
  }

  public showCountdownMessage(messageConfig: CountdownMessageModel): void {
    this.loggingService.logFunctionStart();
    this.showCountdownMessage$.next(messageConfig);
  }

  public hideCountdownMessage(): void {
    this.loggingService.logFunctionStart();
    this.showCountdownMessage(undefined);
  }

  ngOnDestroy(): void {
    this.clearCountdown();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[highlightTextOnClick]',
})
export class HighlightTextOnClickDirective {
  
  private elementRef: ElementRef;

  constructor(
    elementRef: ElementRef,
  ) {
    this.elementRef = elementRef;
  }

  @HostListener('click', ['$event']) onClick(): void {
    if (this.elementRef.nativeElement.value !== '') {
      this.elementRef.nativeElement.select();
    }
  }
}
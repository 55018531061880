import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'files-uploader',
  templateUrl: 'files-uploader.component.html',
  styleUrls: ['files-uploader.component.scss']
})
export class FilesUploaderComponent {
  @Input() title: string = 'No file uploaded yet.';
  @Input() matIcon: string = 'add';
  @Input() multiple: boolean = false;
  @Input() accept: string = '';
  @ViewChild('fileUpload') fileUpload: ElementRef;
  @Output() fileSelected: EventEmitter<File[]> = new EventEmitter();

  files: File[] = [];

  onFileSelected(event: Event): void {
    this.files = Array.from((event.target as HTMLInputElement).files).map((file: File) => {
      return file;
    });
    if (this.files) {
      this.fileSelected.emit(this.files);
    }
  }

  clear(file: File): void {
    const foundIndex = Array.from(this.files).findIndex(el => {
      return file.name === el.name;
    });
    if (foundIndex > -1) {
      this.files.splice(foundIndex, 1);
    }
    if (this.files.length === 0) {
      this.fileUpload.nativeElement.value = '';
    }
    this.fileSelected.emit(this.files);
  }
}
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '@common/co/core/config/app.config';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { Router } from '@angular/router';
import { INavigationConfig, NAVIGATION_CONFIG } from '@common/co/navigation/navigation';

@Injectable()
export class UserRegistrationService {
  constructor(@Inject(APP_CONFIG) private appConfig: IAppConfig,
              @Inject(NAVIGATION_CONFIG) private navigationConfig: INavigationConfig,
              private fuseProgressBar: FuseProgressBarService,
              public _authorizeService: AuthorizeService,
              private _router: Router) {
  }

  public async createAnother(): Promise<void> {
    this._router.navigateByUrl(this.navigationConfig.check_unique_id.url);
  }
}

import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { AppBusService } from '@common/co/core/services/app-bus.service';

@Injectable()
export class SplAppApiService {
  
  constructor(private _appBusService: AppBusService) {
  }
  
  public initialize(): void {
    App.addListener('appStateChange', ({ isActive }) => {
      this._appBusService.setIsActive(isActive);
    });
  }
}
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { LinksMenuModel } from '@common/co/shared/models/links-menu.model';

@Component({
  selector: 'links-menu',
  templateUrl: './links-menu.component.html',
  styleUrls: ['./links-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinksMenuComponent {
  @Input() links: LinksMenuModel[];
}

<div id="register" fxLayout="column">
  <div id="register-form-wrapper" fxLayout="row" fxLayoutAlign="space-around">
    <div id="register-form" [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
      <div class="forms-wrapper">

        <register-default *ngIf="currentStep === stepType.Default" [userSocialLogin]="userSocialLogin"
                          [registerFields]="registerFields"
                          (nextStep)="next($event)"></register-default>
        <ng-container *ngIf="currentStep === stepType.UniqueId">
          <div class="title-section">
            <h1>
              {{ 'REGISTER.CREATE_AN_ACCOUNT' | translate }}
            </h1>
            <p *ngIf="currentRegistrationType === registrationType.UseUniqueId">
              {{ 'REGISTER.ENTER_UNIQUE_ID' | translate }}              
            </p>
            <p *ngIf="currentRegistrationType === registrationType.InvalidEmail" class="important">
              {{ 'REGISTER.UNABLE_TO_VERIFY_EMAIL_UNIQ_ID' | translate }}              
            </p>
          </div>
          <register-unique-id-info
            [registerFields]="registerFields"
            (nextStep)="next($event)"></register-unique-id-info>
        </ng-container>
        <register-personal-info *ngIf="currentStep === stepType.PersonalInfo"
                          [registerFields]="registerFields"
                          (nextStep)="next($event)"></register-personal-info>
        <register-password *ngIf="currentStep === stepType.Password"
                          [registerFields]="registerFields"
                          (nextStep)="next($event)"></register-password>
        <register-waiver-of-liability *ngIf="currentStep === stepType.WaiverOfLiability"
                          [registerFields]="registerFields"
                          (nextStep)="next($event)"></register-waiver-of-liability>
        <register-complete *ngIf="currentStep === stepType.Complete"></register-complete>

        <span mat-button color="primary" *ngIf="currentStep == stepType.Default && currentRegistrationType !== registrationType.UseUniqueId" class="go-back-button"
              (click)="createUseUniqueId()" 
              [aria-label]="'REGISTER.CREATE_WITH_UNIQ_ID' | translate" 
              [matTooltip]="'REGISTER.CREATE_WITH_UNIQ_ID' | translate">
              {{ 'REGISTER.CREATE_WITH_UNIQ_ID' | translate }}   
        </span>

<!--    Profile is already created we should just navigate user to in-take form    -->
        <button mat-button class="spl-button" *ngIf="currentStep == stepType.Complete"
          (click)="navigateToIntake()" 
          [aria-label]="'REGISTER.CREATE_PROFILE' | translate" 
          [matTooltip]="'REGISTER.CREATE_PROFILE' | translate">
          {{ 'REGISTER.CREATE_PROFILE' | translate }}  
        </button>

        <div *ngIf="currentStep != stepType.Complete" class="filler">
        </div>
        <div *ngIf="currentStep != stepType.Complete" class="separator">
        </div>

        <span mat-button color="primary" *ngIf="(currentStep != stepType.Default || prevStep) && currentStep != stepType.Complete"
          class="go-back-button" (click)="previous()" 
          [aria-label]="'COMMON.ACTIONS.GO_BACK_A_STEP' | translate" 
          [matTooltip]="'COMMON.ACTIONS.GO_BACK_A_STEP' | translate">
          {{ 'COMMON.ACTIONS.GO_BACK_A_STEP' | translate }}   
        </span>

        <span mat-button color="primary" *ngIf="currentStep == stepType.Default" 
          class="go-back-button mt-12"
          [routerLink]="'/login'" 
          [aria-label]="'REGISTER.RETURN_TO_SIGN_IN' | translate" 
          [matTooltip]="'REGISTER.RETURN_TO_SIGN_IN' | translate">
          {{ 'REGISTER.RETURN_TO_SIGN_IN' | translate }}   
        </span>

      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CountdownPanelComponent  } from 'app/layout/spl-athletes-app-layout/components/countdown-panel/countdown-panel.component';
import { SplCoreModule } from '@common/co/core/core.module';

@NgModule({
  declarations: [CountdownPanelComponent],
  imports: [
    SplCoreModule
  ],
  exports: [CountdownPanelComponent],
})
export class CountdownPanelModule {}

import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { AuthorizeService } from './services/authorize.service';
import { Platform } from '@angular/cdk/platform';
import { APP_CONFIG, IAppConfig } from '@common/co/core/config/app.config';
import { APP_INFO, IAppInfo } from '@common/co/core/config/app.info';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    @Inject(APP_INFO) private appInfo: IAppInfo,
    private authorize: AuthorizeService,
    private _platform: Platform,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const currentTenant = this.authorize.currentTenantId;
    const currentProfile = this.authorize.currentProfileId;
    if (
      req.url.indexOf('/connect/token') > -1 ||
      req.url.indexOf('/_configuration') > -1
    ) {
      return this.processRequestWithToken(
        null,
        currentTenant,
        currentProfile,
        req,
        next,
      );
    }
    if (req.url.indexOf('/Profile/profiles') > -1) {
      return from(
        this.authorize.getAccessToken().then((token) => {
          return this.processRequestWithToken(
            token.accessToken,
            null,
            null,
            req,
            next,
          )
            .toPromise()
            .catch(async (reason: any) => {
              if (reason.status === 401) {
                // try refresh token and make call again
                const token = await this.authorize.refreshTokenForced();
                if (!token || token.status === 401) {
                  this.authorize.logout();
                  throw reason;
                }
                return this.processRequestWithToken(
                  token.accessToken,
                  null,
                  null,
                  req,
                  next,
                )
                  .toPromise()
                  .catch((reason) => {
                    if (reason.status === 401) {
                      this.authorize.logout();
                      throw reason;
                    } else {
                      throw reason;
                    }
                  });
              } else {
                throw reason;
              }
            });
        }),
      );
    } else {
      return from(
        this.authorize.getAccessToken().then((token) => {
          return this.processRequestWithToken(
            token.accessToken,
            currentTenant,
            currentProfile,
            req,
            next,
          )
            .toPromise()
            .catch(async (reason: any) => {
              if (reason.status === 401) {
                // try refresh token and make call again
                const token = await this.authorize.refreshTokenForced();
                if (!token || token.status === 401) {
                  this.authorize.logout();
                  throw reason;
                }
                return this.processRequestWithToken(
                  token.accessToken,
                  currentTenant,
                  currentProfile,
                  req,
                  next,
                )
                  .toPromise()
                  .catch((reason) => {
                    if (reason.status === 401) {
                      this.authorize.logout();
                      throw reason;
                    } else {
                      throw reason;
                    }
                  });
              } else {
                throw reason;
              }
            });
        }),
      );
    }
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequestWithToken(
    token: string,
    tenant: string,
    profile: string,
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    headers['X-App-Id'] = this.appInfo.AppId;
    if (tenant) headers['X-Tenant-Id'] = tenant;
    if (profile) headers['X-Athlete-Id'] = profile;
    if (this._platform?.ANDROID || this._platform?.IOS) {
      headers['SPL-Client-Origin'] = this.appConfig.clientUrl;
    }

    req = req.clone({
      setHeaders: headers,
    });

    return next.handle(req);
  }

  private isSameOriginUrl(req: any): boolean {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    // eslint-disable-next-line no-useless-escape
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }

    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }
}

<div id="forgot-password" fxLayout="column">
  <div
    id="forgot-password-form-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      id="forgot-password-form"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
    >
      <ng-container *ngIf="!isEmailSent">

        <div class="spl-info-text mb-36">
          {{ 'FORGOT_PASSWORD.TO_RESET_PASSWORD' | translate }}
        </div>

        <form
          name="forgotPasswordForm"
          [formGroup]="forgotPasswordForm"
          novalidate
        >
          <mat-form-field appearance="outline">
            <mat-label>  {{ 'COMMON.EMAIL' | translate }}</mat-label>
            <input matInput formControlName="email" showValidationInfo/>
            <mat-error
              *ngIf="email.hasError('required')"
            >
              {{ 'COMMON.EMAIL_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="!email.hasError('required') && email.hasError('email')">
              {{ 'FORGOT_PASSWORD.ENTER_VALID_EMAIL' | translate }}
            </mat-error>
            <mat-error *ngIf="
            email.hasError('required') &&
            !email.hasError('email') &&
            email.hasError('backend')">
              {{ email.getError('backend') }}
            </mat-error>
          </mat-form-field>

          <button
            mat-button
            class="spl-button center mt-16 mb-16"
            color="accent"
            [attr.aria-label]="'FORGOT_PASSWORD.REQUEST_NEW_PASSWORD' | translate"
            (click)="onForgot()"
            [disabled]="forgotPasswordForm.invalid"
          >
            {{ 'FORGOT_PASSWORD.REQUEST_NEW_PASSWORD' | translate }}
          </button>

          <span class='splitter'></span>
        </form>
      </ng-container>
      <div class='check-email' *ngIf="isEmailSent">
        <div class="logo">
          <mat-icon class="mat-accent s-96">email</mat-icon>
        </div>

        <div class="title">{{ 'FORGOT_PASSWORD.REQUEST_NEW_PASSWORD' | translate }}</div>

        <div class="subtitle spl-info-text mb-24" fxLayout="column">
          <p>{{ 'FORGOT_PASSWORD.CONFIRMATION_SENT_TO' | translate }} <b>{{ email?.value }}</b>.</p>
          <p>
            {{ 'FORGOT_PASSWORD.CHECK_INBOX' | translate }}
          </p>
        </div>
      </div>
      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>chevron_left</mat-icon><a class="link" [routerLink]="'/login'">
          {{ 'COMMON.ACTIONS.GO_BACK_TO_LOGIN' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  AuthClient,
  CheckPasswordCommand,
  CheckPasswordResponse,
  RegisterCommand,
} from '@common/services/co-api-client';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { validateAllFormFields } from '@common/co/core/helpers/validate-all-form-fields';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'register-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordComponent implements OnInit, OnDestroy {
  @Input() registerFields: RegisterCommand;
  @Output() nextStep: EventEmitter<RegisterCommand> = new EventEmitter();
  registerForm: FormGroup;

  private _unsubscribeAll: Subject<any>;
  private translations: any;

  get completed(): boolean {
    return (
      this.registerForm.get('password').valid &&
      this.registerForm.get('confirmPassword').valid
    );
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _authClient: AuthClient,
    private appBusService: AppBusService,
    private _translationService: TranslateService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._translationService
      .get([
        'CHANGE_PASSWORD.PASSWORD_POLICY_ERROR',
        'CHANGE_PASSWORD.PASSWORD_WAS_CHANGED',
      ])
      .subscribe((translations) => {
        this.translations = translations;
      });

    this.registerForm = this._formBuilder.group({
      password: [this.registerFields.password, Validators.required],
      confirmPassword: [
        this.registerFields.confirmPassword,
        [Validators.required, confirmPasswordValidator],
      ],
    });

    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    this.registerForm
      .get('password')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.registerForm.get('confirmPassword').updateValueAndValidity();
      });
  }

  async next(): Promise<void> {
    if (this.registerForm.valid) {
      const checkPassword = await this._authClient
        .checkPassword(
          new CheckPasswordCommand({
            password: this.registerForm.get('password').value,
          }),
        )
        .toPromise();

      const respCheckPassword = new CheckPasswordResponse(checkPassword);
      if (!respCheckPassword?.success) {
        const errors = {};
        errors['password'] = this._translationService.instant(
          'CHANGE_PASSWORD.PASSWORD_POLICY_ERROR',
        );
        this.registerForm.get('password').setErrors(errors);
        this.appBusService.processValidationMessage(
          getSplNotification('info', respCheckPassword.reasons.join('\n')),
        );
      } else {
        this.appBusService.clearValidationMessage();
        this.nextStep.emit(this.registerForm.getRawValue());
      }
    } else {
      validateAllFormFields(this.registerForm);
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('confirmPassword');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};

import { Component, Input, OnInit } from '@angular/core';
import { FormFieldType } from '@common/services/co-api-client';
import { FormControl } from '@angular/forms';
import { InTakeService } from '@common/co/auth/services/in-take.service';
import { InTakeFormFieldModel } from '@common/co/feature/in-take/models/in-take-form-field.model';

@Component({
  selector: 'in-take-field',
  templateUrl: './in-take-field.component.html',
  styleUrls: ['./in-take-field.component.scss'],
})
export class InTakeFieldComponent implements OnInit {
  @Input()
  field: InTakeFormFieldModel;

  @Input()
  control: FormControl;

  fieldTypes = FormFieldType;

  selected;

  get maxDate(): Date {
    return new Date();
  }

  constructor(private _inTakeService: InTakeService) {}

  ngOnInit(): void {
    this.selected =
      this.field.answer?.value ||
      this.field.firstDisplayAnswer ||
      this.field.extraData?.dropDownData?.defaultValue;

    this.control.valueChanges.subscribe(() => {
      console.log(this.control);
    });
  }
}

import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AthleteDto } from '@common/services/co-api-client';

@Component({
  selector: 'spl-change-profile-dialog',
  templateUrl: './change-profile-dialog.component.html',
  styleUrls: ['./change-profile-dialog.component.scss'],
})
export class ChangeProfileDialogComponent {
  public currentProfile: AthleteDto;
  public targetProfile: AthleteDto;
  public okButtonText: string = 'Switch';
  public cancelButtonText: string = 'Cancel';

  constructor(public dialogRef: MatDialogRef<ChangeProfileDialogComponent>) {}
}

<div class="buttons-menu-wrapper">
  <span class="small-title" *ngIf="title">
    {{ title }}
  </span>
  <span class="spl-info-text mt-4" *ngIf="subTitle">
    {{ subTitle }}
  </span>
  <div class="menu">
      <span
        class="menu-option"
        *ngFor="let option of options"
        [ngClass]="{'selected': selectedOption?.id == option.id, 'disabled': option.disabled}"
        (click)="selectOption(option)"
      >
        {{ option.name }}
      </span>
  </div>
</div>
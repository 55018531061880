import {
  Component, Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoBlockComponent {
  @Input() title: string;
  @Input() value: string | number;
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { SplCountdownService } from '@common/co/core/components/spl-countdown/spl-countdown.service';
import { Router } from '@angular/router';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { CountdownMessageModel } from '@common/co/shared/models/countdown-message.model';
import { EventType } from '@common/services/co-api-client';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'spl-countdown',
  templateUrl: './spl-countdown.component.html',
  styleUrls: ['./spl-countdown.component.scss'],
})
export class SplCountdownComponent implements OnInit, OnDestroy {
  public mobileLayout: boolean = false;
  public messageConfig: CountdownMessageModel;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _platform: Platform,
    public splCountdownService: SplCountdownService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _appBusService: AppBusService,
    private router: Router,
  ) {
    this._unsubscribeAll = new Subject();
    if (this._platform.ANDROID || this._platform.IOS) {
      this.mobileLayout = true;
    }

    this.splCountdownService.changeDetectionEmitter
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._changeDetectorRef.detectChanges();
      });
  }

  ngOnInit(): void {
    this.splCountdownService.showCountdownMessage$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: CountdownMessageModel) => {
        if (config) {
          this.resolveShowMessage(config);
        } else {
          this.messageConfig = null;
          this._changeDetectorRef.detectChanges();
        }
      });
  }

  private resolveShowMessage(config: CountdownMessageModel): void {
    this.messageConfig = config;
  }

  public resolveMessageAction(): void {
    if (this.messageConfig.redirectUrl) {
      if (
        this.messageConfig.eventType === EventType.InStudioTraining &&
        !this.splCountdownService.event.current
      ) {
        return;
      }
      this.router.navigateByUrl(this.messageConfig.redirectUrl);
    } else {
      this.splCountdownService.hideCountdownMessage();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

<div class="title-section">
  <h1>
    {{ 'REGISTER.CREATE_AN_ACCOUNT' | translate }}
  </h1>
  <p>
    {{ 'REGISTER.ENTER_YOUR_CONACT_INFO' | translate }}
  </p>
  
  <p>
    {{ 'REGISTER.FOR_ADULTS' | translate }}
  </p>
  
  <p class="important">
    {{ 'REGISTER.AGE_WARNING' | translate }}
  </p>
</div>

<form [formGroup]="registerForm">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'COMMON.YOUR_EMAIL' | translate }}</mat-label>
    <input matInput [formControl]="registerForm.controls['email']" [required]="true" showValidationInfo/>
    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
    <mat-error *ngIf="registerForm.get('email').hasError('required')">
      {{ 'COMMON.EMAIL_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="!registerForm.get('email').hasError('required') && registerForm.get('email').hasError('email')">
      {{ 'COMMON.EMAIL_INVALID' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'COMMON.YOUR_PHONE' | translate }}</mat-label>
    <input matInput [formControl]="registerForm.controls['phone']" [required]="true" [mask]="'000-000-0000'"
           [validation]="true" placeholder="Ex. (000) 000-0000" showValidationInfo/>
    <mat-icon matSuffix class="secondary-text">phone</mat-icon>
    <mat-error *ngIf="registerForm.get('phone').hasError('required')">
      {{ 'COMMON.PHONE_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="registerForm.get('phone').hasError('required') && registerForm.get('phone').hasError('phone')">
      {{ registerForm.get('phone').getError('phone') }}
    </mat-error>
    <mat-error *ngIf="registerForm.get('phone').hasError('mask') || registerForm.get('phone').hasError('phone')">
      {{ 'COMMON.PHONE_INVALID' | translate }}
    </mat-error>
  </mat-form-field>
</form>


<button mat-button class="spl-button center mt-16 mb-16"
        (click)="next()"
        [disabled]="!completed" 
        [attr.aria-label]="'COMMON.ACTIONS.CONTINUE' | translate" 
        [matTooltip]="'COMMON.ACTIONS.CONTINUE' | translate">
        {{ 'COMMON.ACTIONS.CONTINUE' | translate }}
</button>

<div id="reset-password" fxLayout="column">
  <div
    id="reset-password-form-wrapper"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      *ngIf="!onload"
      id="reset-password-form"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
    >
      <ng-container *ngIf="!isPasswordReset && !displayBackButton">
        <div class="spl-info-text mb-36">
          {{ 'RESET_PASSWORD.PLEASE_CHANGE_YOUR_PASSWORD' | translate }}
          <br/>
          <br/>
          {{ 'RESET_PASSWORD.PASSWORD_MUST_INCLUDE' | translate }}
        </div>

        <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'COMMON.EMAIL' | translate }}</mat-label>
            <input matInput type="email" formControlName="email" />
            <mat-error *ngIf="email.hasError('required')">{{ 'COMMON.EMAIL_REQUIRED' | translate }}</mat-error>
          </mat-form-field>
  
          <app-password-input [control]="password" [label]="'COMMON.PASSWORD' | translate"></app-password-input>
  
          <app-password-input [control]="passwordConfirm" [label]="'RESET_PASSWORD.CONFIRM_PASSWORD' | translate"></app-password-input>

          <div class='button-wrapper'>
            <button
              mat-button
              class="spl-button m-auto"
              color="accent"
              (click)="onReset()"
              [disabled]="resetPasswordForm.invalid"
              [attr.aria-label]="'RESET_PASSWORD.UPDATE_PASSWORD' | translate | uppercase"
            >
            {{ 'RESET_PASSWORD.UPDATE_PASSWORD' | translate | uppercase }}
            </button>
          </div>
        </form>
      </ng-container>

      <div class='reset-success' *ngIf="isPasswordReset">
        <div class="logo mb-32">
          <img src='assets/images/logos/cup_logo.svg'>
        </div>

        <div class="spl-info-text mb-32">
          {{ 'COMMON.STATUSES.SUCCESS' | translate }}
          <br/>
          <br/>
          {{ 'RESET_PASSWORD.YOU_UPDATED_PASSWORD' | translate }}
        </div>

        <button
          mat-button
          class="spl-button m-auto"
          color="accent"
          [attr.aria-label]="'COMMON.ACTIONS.GO_TO_SIGNIN' | translate"
          [routerLink]="'/login'"
        >
        {{ 'COMMON.ACTIONS.GO_TO_SIGNIN' | translate }}
        </button>
      </div>

      <div class='error-code' *ngIf="displayBackButton">
        <div class="logo mb-32">
          <img src='assets/images/logos/spl.png'>
        </div>

        <div class="spl-info-text mb-32">
          {{ 'RESET_PASSWORD.RESET_PASSWORD_REQUEST_INCORRECT' | translate }}
        </div>

        <button
          mat-button
          class="spl-button m-auto"
          color="accent"
          [routerLink]="'/login'"
          [attr.aria-label]="'COMMON.ACTIONS.BACK' | translate"
        >
        {{ 'COMMON.ACTIONS.BACK' | translate }}
        </button>
      </div>
    </div>
    <mat-spinner *ngIf="onload"></mat-spinner>
  </div>
</div>

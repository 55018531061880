import { Component, Input, OnChanges } from '@angular/core';
import { ISAQScoresRangeItem } from '@common/co/shared/models/saq-scores-range-config';

@Component({
  selector: 'color-gradient-between',
  templateUrl: './color-gradient-between.component.html',
  styleUrls: ['./color-gradient-between.component.scss'],
})
export class ColorGradientBetweenComponent implements OnChanges {
  @Input() leftValue: number;
  @Input() rightValue: number;
  @Input() config: ISAQScoresRangeItem[];
  position: number = 0;
  stepValue: number = 0.8;
  margin: number = 4;
  pointerWidth: number = 7;

  ngOnChanges(): void {
    const displayBlock = document.getElementsByClassName('metric-color')[0];
    if (!displayBlock) {
      return;
    }
    const displayBlockCenterPosition =
      (displayBlock.clientWidth - this.pointerWidth) / 2;
    let offset = this.rightValue - this.leftValue;

    this.position = displayBlockCenterPosition;
    // if values is equal
    if (offset === 0) {
      return;
    }

    // offset as percent
    if (offset > 0) {
      offset = (offset / this.rightValue) * 100;
    } else {
      offset = (offset / this.leftValue) * 100;
    }

    // getting step
    const positiveOffset = Math.abs(offset);
    const foundEl: ISAQScoresRangeItem = this.config.find((el) => {
      return positiveOffset >= el.start && positiveOffset <= el.end;
    });

    // marker position for extreme limit
    if (!foundEl && this.config.length > 0) {
      if (
        positiveOffset > this.config[this.config.length - 1].end &&
        offset > 0
      ) {
        this.position =
          displayBlock.clientWidth - (this.margin + this.pointerWidth);
      }
      if (
        positiveOffset > this.config[this.config.length - 1].end &&
        offset < 0
      ) {
        this.position = this.margin;
      }
      return;
    }

    // calculate position for step
    if (offset > 0) {
      this.position +=
        (this.position / this.config.length) * this.stepValue * foundEl.index;
    } else {
      this.position -=
        (this.position / this.config.length) * this.stepValue * foundEl.index;
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@common/co/core/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FilesUploaderComponent } from '@common/shared/components/files-uploader/files-uploader.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  declarations: [FilesUploaderComponent],
  exports: [FilesUploaderComponent],
})
export class FilesUploaderModule {}

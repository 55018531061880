<div class="links-wrapper">
  <ng-container *ngFor="let link of links">
    <a class="link" *ngIf="link.id" [routerLink]="[link.url, link.id]" [state]="{date: link.extra?.state ? link.extra.state : ''}" [queryParams]="link.extra?.queryParams">
      <span class="link-content" [innerHTML]="link.title"></span>
      <div class="icons-wrap">
        <img class="download-icon" *ngIf="link.isIconVisible && link?.extra?.state"
             src="assets/icons/download-results.svg">
        <img src="assets/images/icons/right-double-arrow.svg">
      </div>
    </a>
    <a class="link" *ngIf="!link.id" [routerLink]="link.url" [state]="{date: link.title}" [queryParams]="link.extra?.queryParams">
      <span class="link-content" [innerHTML]="link.title"></span>
      <div class="icons-wrap">
        <img class="download-icon" *ngIf="link.isIconVisible && link?.extra?.state"
             src="assets/icons/download-results.svg">
        <img src="assets/images/icons/right-double-arrow.svg">
      </div>
    </a>
  </ng-container>

</div>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import {
  AuthClient,
  ForgotPasswordCommand,
} from '@common/services/co-api-client';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public isEmailSent: boolean = false;
  private readonly emailValidators: Validators[] = [
    Validators.required,
    Validators.email,
  ];

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private _authClient: AuthClient,
    private appBusService: AppBusService,
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', this.emailValidators],
    });
  }

  public get email(): AbstractControl | null {
    return this.forgotPasswordForm.get('email');
  }

  public async onForgot(): Promise<void> {
    const response = await this._authClient
      .forgotPassword(
        new ForgotPasswordCommand({
          email: this.email.value,
        }),
      )
      .toPromise();

    if (response?.success) {
      //TODO: show alert
      this.isEmailSent = true;
      console.log('email sent');
    } else {
      this.email.setErrors({ backend: response?.reason });
      const errors = {};
      errors['email'] = 'Email is invalid';
      this.email.setErrors(errors);
      this.appBusService.processValidationMessage(
        getSplNotification('info', response?.reason),
      );
    }
    return Promise.resolve();
  }
}

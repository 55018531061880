import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ApplicationDateFormats } from '@common/shared/constants/constants';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import {
  SkeletonModeType,
  SkeletonType,
} from '@common/co/shared/components/skeleton/models/skeleton.model';
import { takeUntil } from 'rxjs/operators';
import {
  APPLICATION_DICTIONARY_SERVICE,
  IApplicationDictionaryService,
} from '@common/services/iapplication-dictionary-service';

@Component({
  selector: 'events-info-panel',
  templateUrl: './events-info-panel.component.html',
  styleUrls: ['./events-info-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventsInfoPanelComponent implements OnInit, OnDestroy {
  @Input() eventsCountResponsePromise: Promise<void>;
  @Input() trainingSessionsCount: number | undefined;
  @Input() totalCount: number | undefined;
  public dateFormat: string = ApplicationDateFormats.DateOnlyFormat;
  public skeletonType = SkeletonType;
  public skeletonModeType = SkeletonModeType;
  public isTrainingClassAvailable: boolean | null;
  public dateOfLastTest: Date | null;

  private _unsubscribeAll: Subject<void>;

  constructor(
    @Inject(APPLICATION_DICTIONARY_SERVICE)
    public applicationDictionaryService: IApplicationDictionaryService,
    private appBusService: AppBusService,
  ) {
    this._unsubscribeAll = new Subject();
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
  }

  ngOnInit(): void {
    this.appBusService.appSettingsChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        if (!settings) return;
        // @TODO уточнить у Юры
        this.isTrainingClassAvailable =
          settings.availableExercisePrograms.InStudioDefault;
        this.isTrainingClassAvailable = true;
      });

    this.appBusService.dateOfLastTest$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((date) => {
        this.dateOfLastTest = date;
      });
  }
}

import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PanelInfoModel } from '@common/co/shared/models/panel-info.model';

@Component({
  selector: 'info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoPanelComponent {
  @Input() panelInfo: PanelInfoModel;
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ContentModule } from 'app/layout/spl-athletes-app-layout/components/content/content.module';
import { FooterModule } from 'app/layout/spl-athletes-app-layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/spl-athletes-app-layout/components/navbar/navbar.module';
import { ToolbarModule } from 'app/layout/spl-athletes-app-layout/components/toolbar/toolbar.module';

import { SplAthletesAppLayoutComponent  } from 'app/layout/spl-athletes-app-layout/spl-athletes-app-layout.component';

@NgModule({
  declarations: [SplAthletesAppLayoutComponent],
  imports: [
    RouterModule,

    FuseSharedModule,
    FuseSidebarModule,
    
    ContentModule,
    FooterModule,
    NavbarModule,
    ToolbarModule
  ],
  exports: [SplAthletesAppLayoutComponent],
})
export class SplAthletesAppLayoutModule {}

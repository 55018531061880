import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITechnicalClientService } from '@common/shared/services/itechnical-client-service';
import { TechnicalClient } from '@common/services/co-api-client';

@Injectable({
  providedIn: 'root',
})
export class CoTechnicalClientService implements ITechnicalClientService {
  constructor(private _technicalClient: TechnicalClient) {}

  public index(): Observable<string> {
    return this._technicalClient.index();
  }
}

import { Injectable } from '@angular/core';
import { AppStateService } from '@common/co/core/services/app-state.service';
import {
  EventAnnounceResponse,
  EventClient,
} from '@common/services/co-api-client';

@Injectable()
export class SplCountdownManagementService {
  private event: EventAnnounceResponse;

  constructor(
    private _appStateService: AppStateService,
    private _eventClientClient: EventClient,
  ) {}

  public async getEvents(): Promise<EventAnnounceResponse> {
    return new Promise((resolve) => {
      if (this.event) {
        resolve(this.event);
      } else {
        this._eventClientClient.announce().subscribe((event) => {
          this.event = event;
          resolve(event);
        });
      }
    });
  }

  public async clearStorage(): Promise<void> {
    return new Promise((resolve) => {
      this._appStateService.removeState('countdownEvent').then(() => {
        resolve();
      });
    });
  }

  public clearEvent(): void {
    this.event = undefined;
  }
}

<spl-skeleton class="solo-column" [showSkeleton]="loadingPromise">
  <div class="hydration-tracker-wrapper">
    <div class="calendar-holder" *ngIf="records !== undefined">
      <div class="spinner-wrapper" *ngIf="isLoading">
        <mat-spinner [diameter]="70"></mat-spinner>
      </div>
      <kendo-calendar type="classic" class="calendar"
                      #calendar
                      [disabledDates]="disabledDates"
                      [value]="selectedDay"
                      (valueChange)="onSelectedDayChange($event)"
                      (navigate)="onMonthChange($event)"
                      (activeViewChange)="onActiveViewChange($event)"
                      [max]="maxDate"
      >
        <ng-template kendoCalendarMonthCellTemplate let-date let-context="cellContext">
          <span [ngClass]="resolveDayCellStyles(date)" [ngStyle]="getActiveDayColor(date)">{{context.formattedValue}}</span>
        </ng-template>
      </kendo-calendar>
      
      <div class="hydrated-level" *ngIf="hydrationLevels?.length > 0">
        <span>Dehydrated</span>
        <div
          class="hydrated-level-gradient"
          [ngStyle]="{'background': 'linear-gradient(to right, ' + hydrationLevels[0].hydrationColor + ', ' + hydrationLevels[hydrationLevels.length - 1].hydrationColor}"
        >
        </div>
        <span>Hydrated</span>
      </div>
    </div>
    
    <ng-container *ngIf="selectedDay">
      <div class="inputs-wrapper mt-12">
        <div class="inputs-header">
          <button
            mat-button
            class="spl-outline-button"
            (click)="closeInputs()"
          >
            Back
          </button>
          <span>{{ selectedDay | date }}</span>
        </div>
        <ng-container *ngIf="hydrationLevels?.length > 0">
          <div class="input-section">
            <span class="title">Urine Color</span>
            <div class="input">
              <div
                class="urine-level"
                *ngFor="let level of hydrationLevels.slice().reverse()"
                [ngStyle]="{'background-color': level.urineColor}"
                [ngClass]="{'selected': selectedUrineLevel?.id === level.id}"
                (click)="selectUrineLevel(level)"
              >
              </div>
            </div>
          </div>
          <div class="input-section">
            <span class="title">Perceived Energy Level</span>
            <div class="input">
              <span class="energy-value">1</span>
              <mat-slider min="1" max="10" step="1" [discrete]="true" color="accent">
                <input matSliderThumb [formControl]="selectedEnergyLevel">
              </mat-slider>
              <span class="energy-value">10</span>
            </div>
          </div>
        </ng-container>
      </div>
      
      <div class="buttons-wrapper mt-12">
        <button
          mat-button
          class="spl-button"
          (click)="submit()"
          [disabled]="isLoading || !isValid || !recordWasChanged"
        >
          <span>{{ selectedRecord?.id ? 'Update' : 'Submit'}}</span>
          <mat-spinner *ngIf="isLoading" color="accent" diameter="15"></mat-spinner>
        </button>
      </div>
    </ng-container>
  </div>
</spl-skeleton>
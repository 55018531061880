import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ChartService {
  private readonly defaultMaxValue: number = 100;
  private readonly defaultMinValue: number = 0;
  public calculateMinMaxFromPoints(points: any[]): {
    min: number;
    max: number;
  } {
    points = _.cloneDeep(points);
    points.sort((a, b) => b - a);

    let max = 1;
    let min = this.defaultMinValue;
    if (points.length > 0) {
      min = points[points.length - 1];
    }

    let currentStep = 0;

    if (points.length > 0 && points[0]) {
      for (let i = 0; i < Math.floor(points[0])?.toString()?.length; i++) {
        max *= 10;
      }
    } else {
      max = this.defaultMaxValue;
    }

    if (min < 0) {
      while (min < currentStep) {
        currentStep -= max / 5;
      }
    }

    return { min: currentStep, max: max };
  }
}

import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '@common/co/core/config/app.config';
import { APP_INFO, IAppInfo } from '@common/co/core/config/app.info';
import { PlatformService } from '@common/co/core/services/platform.service';
import { ILoginInfo } from '@common/shared/models/login-info';
import { APP_BUS_SERVICE } from '@common/shared/services/iapp-bus-service';
import {
  ApplicationInsights,
  DistributedTracingModes,
} from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { IPayloadItem } from '@common/co/navigation/navigation';
import { StatisticsActivities } from '@common/models/statistic-activities';
import { AthleteDto } from './co-api-client';

export enum TelemetryType {
  Statistics = 1,
}

@Injectable()
export class StatisticsService {
  appInsights: ApplicationInsights;
  loginInfo: ILoginInfo;
  locationId: string;
  os: string;
  platform: string;
  pageKeys: string[];

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    @Inject(APP_INFO) private appInfo: IAppInfo,
    @Inject(APP_BUS_SERVICE) private appBusService: AppBusService,
    private platformService: PlatformService,
    private router: Router,
  ) {}

  public initialize(): void {
    this.pageKeys = Object.values(StatisticsActivities);

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.appConfig.appInsights.instrumentationKey,
        enableAutoRouteTracking: false, // option to log all route changes
        enableCorsCorrelation: false,
        enableRequestHeaderTracking: false,
        enableResponseHeaderTracking: false,
        distributedTracingMode: DistributedTracingModes.W3C,
        correlationHeaderExcludePatterns: [
          new RegExp('.*.streaming.media.azure.net'),
        ],
      },
    });
    this.appInsights.loadAppInsights();

    this.telemetryInitializer();

    this.appBusService.subscribeToRouteInfo((data: IPayloadItem) => {
      console.log(data);
      const navigation = this.router.getCurrentNavigation();
      const key = data.pageTrackingKey;
      if (key && this.pageKeys.indexOf(key) != -1) {
        this.logPageView(key, navigation.finalUrl.toString());
      }
    });

    this.appBusService.logout$.subscribe(() => {
      this.loginInfo = null;
    });

    this.appBusService.setProfile$.subscribe((profile: AthleteDto) => {
      this.locationId = profile?.locationId;
    });

    this.appBusService.loginData$.subscribe((info: ILoginInfo) => {
      if (!info) {
        return;
      }
      const profileChanged =
        !this.loginInfo || this.loginInfo.profile != info?.profile;
      const userIdChanged =
        !this.loginInfo || this.loginInfo.userId != info?.userId;
      const userNameChanged =
        !this.loginInfo || this.loginInfo.userName != info?.userName;

      this.loginInfo = info;
      this.appInsights.context.user.id = info.userName;
      this.appInsights.setAuthenticatedUserContext(
        info.userName,
        info.currentTenantId,
        false,
      );

      if (
        this.loginInfo?.profile &&
        (profileChanged || userIdChanged || userNameChanged)
      ) {
        this.logEvent(StatisticsActivities.ACTION_APP_START);
      }
    });
  }

  public async telemetryInitializer(): Promise<void> {
    this.platform = await this.platformService.platform();
    this.os = await this.platformService.os();
    const telemetryInitializer = (envelope: any): boolean => {
      envelope.data.platform = this.platform;
      envelope.data.os = this.os;
      envelope.data.url = this.appConfig.apiUrl;
      envelope.data.app_version = this.appConfig.version;
      envelope.data.app_id = this.appInfo.AppId;
      envelope.data.telemetry_type = TelemetryType.Statistics;
      return true;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  public createProperties(): { [key: string]: any } {
    const properties: any = {};
    if (!this.loginInfo) {
      return properties;
    }

    properties.tenants = this.loginInfo.tenants;
    properties.profile = this.loginInfo.profile;
    if (this.locationId) {
      properties.locationId = this.locationId;
    }

    return properties;
  }

  logPageView(
    name?: string,
    url?: string,
    properties?: { [key: string]: any },
  ): void {
    // option to call manually
    try {
      this.appInsights.trackPageView({
        name: name,
        uri: url,
        properties: Object.assign(properties || {}, this.createProperties()),
      });
    } catch (error) {
      console.log('appInsights logging error', error);
    }
  }

  logEvent(
    name: StatisticsActivities,
    properties?: { [key: string]: any },
  ): void {
    try {
      this.appInsights.trackEvent(
        { name: name },
        Object.assign(properties || {}, this.createProperties()),
      );
    } catch (error) {
      console.log('appInsights logging error', error);
    }
  }
}

import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SplCountdownService } from '@common/co/core/components/spl-countdown/spl-countdown.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnDestroy {
  private _unsubscribeAll: Subject<void>;
  public currentYear = new Date().getFullYear();

  constructor(public splCountdownService: SplCountdownService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

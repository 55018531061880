<form [formGroup]="registerForm">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'COMMON.UNIQUE_ID' | translate }}</mat-label>
    <input matInput [formControl]="registerForm.controls['uniqueId']" [required]="true" [maxlength]="9" showValidationInfo/>
    <mat-error *ngIf="registerForm.get('uniqueId').hasError('required')">
      {{ 'COMMON.UNIQUE_ID_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="registerForm.get('uniqueId').hasError('pattern')">
      {{ 'COMMON.UNIQUE_ID_INVALID' | translate }}
    </mat-error>
    <mat-error *ngIf="!registerForm.get('uniqueId').hasError('required') && registerForm.get('uniqueId').hasError('uniqueId')">
      {{ registerForm.get('uniqueId').getError('uniqueId') }}
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'COMMON.PERSONAL_INFO.YOUR_DATE_OF_BIRTH' | translate }}</mat-label>
    <input matInput [formControl]="registerForm.controls['dateOfBirth']" [matDatepicker]="datePicker" showValidationInfo/>
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    <mat-error *ngIf="registerForm.get('dateOfBirth').hasError('required')">
      {{ !registerForm.get('dateOfBirth').hasError('matDatepickerParse') ?
      ('COMMON.PERSONAL_INFO.DATE_OF_BIRTH_REQUIRED' | translate) : ('COMMON.PERSONAL_INFO.DATE_OF_BIRTH_INVALID' | translate)
      }}
    </mat-error>
  </mat-form-field>
</form>


<button mat-button class="spl-button center mt-16 mb-16"
        (click)="next()"
        [disabled]="!completed" 
        [attr.aria-label]="buttonTitle | translate" 
        [matTooltip]="buttonTitle | translate">      
  {{ buttonTitle | translate }}
</button>

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';

import { FooterComponent } from 'app/layout/spl-athletes-app-layout/components/footer/footer.component';
import { CountdownPanelModule } from 'app/layout/spl-athletes-app-layout/components/countdown-panel/countdown-panel.module';
import { SplCoreModule } from '@common/co/core/core.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatToolbarModule,

    FuseSharedModule,
    CountdownPanelModule,
    SplCoreModule
  ],
  exports: [FooterComponent],
})
export class FooterModule {}

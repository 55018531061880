<div class="showGetStartedMessage" [ngClass]="{'mobile': mobileLayout, 'display': messageConfig}">
  <ng-container *ngIf="messageConfig">
    <span>{{ messageConfig.message }}</span>
    <button mat-button class="spl-button mt-12" (click)="resolveMessageAction()">
      {{ messageConfig.buttonTitle }}
    </button>
  </ng-container>
</div>

<div fxLayout="column" fxLayoutAlign="center center" class="countdown" *ngIf="splCountdownService.countdown">
  <span class="title">
    {{ splCountdownService.description }}
  </span>
  <div fxLayout="row">
    <div fxLayout="column" fxLayoutAlign="center center">
      <span class="time">
        {{ splCountdownService.countdown.days | fullDateFormatPipe }}
      </span>
      <span class="unit-time">
        DAY
      </span>
    </div>
    <span class="time-splitter">
      :
    </span>
    <div fxLayout="column" fxLayoutAlign="center center">
      <span class="time">
        {{ splCountdownService.countdown.hours | fullDateFormatPipe }}
      </span>
      <span class="unit-time">
        HR
      </span>
    </div>
    <span class="time-splitter">
      :
    </span>
    <div fxLayout="column" fxLayoutAlign="center center">
      <span class="time">
        {{ splCountdownService.countdown.minutes | fullDateFormatPipe }}
      </span>
      <span class="unit-time">
        MIN
      </span>
    </div>
    <span class="time-splitter">
      :
    </span>
    <div fxLayout="column" fxLayoutAlign="center center">
      <span class="time">
        {{ splCountdownService.countdown.seconds | fullDateFormatPipe }}
      </span>
      <span class="unit-time">
        SEC
      </span>
    </div>
  </div>
</div>
import { Inject, Injectable, NgZone } from '@angular/core';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NotificationComponent } from '@common/co/core/components/notification/notification.component';
import {
  SplNotification,
  SplPushNotification,
} from '@common/co/core/models/splNotification.model';
import {
  IPushNotificationResolver,
  PUSH_NOTIFICATION_RESOLVER,
} from '@common/co/core/resolvers/defaultPushNotification.resolver';

@Injectable()
export class NotificationService {
  ongoingNotifications: SplNotification[] = [];

  constructor(
    private appBusService: AppBusService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    @Inject(PUSH_NOTIFICATION_RESOLVER)
    private defaultPushNotificationResolver: IPushNotificationResolver,
  ) {
    this.appBusService.notificationSubject.subscribe(
      (notification: SplNotification) => {
        this.ongoingNotifications = [notification];
        if (this.ongoingNotifications.length == 1) {
          this.showNotification(this.ongoingNotifications[0]);
        }
      },
    );
    this.appBusService.validationNotificationSubject.subscribe(
      (notification: SplNotification) => {
        this.showValidationMessage(notification);
      },
    );
    this.appBusService.clearValidationMessageSubject.subscribe(() => {
      this.clearValidationMessage();
    });
    this.appBusService.subscribeToRouteInfo(() => {
      this.clearAllMessages();
    });
  }

  public generateNotification(notification: SplNotification): void {
    this.appBusService.processNotification(notification);
  }

  private showNotification(notification): void {
    this.zone.run(() => {
      const snackBarRef = this.snackBar.openFromComponent(
        NotificationComponent,
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: notification.duration,
          data: notification,
          panelClass: ['custom-snackbar', `${notification.level}`],
        },
      );

      snackBarRef.afterDismissed().subscribe(() => {
        const foundIndex = this.ongoingNotifications.findIndex((el) => {
          return el.id === notification.id;
        });
        if (foundIndex > -1) {
          this.ongoingNotifications.splice(foundIndex, 1);
          if (this.ongoingNotifications.length > 0) {
            this.showNotification(this.ongoingNotifications[0]);
          }
        }
      });
    });
  }

  public showValidationMessage(notification: SplNotification): void {
    this.ongoingNotifications = [notification];
    this.showNotification(this.ongoingNotifications[0]);
  }

  public clearValidationMessage(): void {
    this.snackBar.dismiss();
  }

  public clearAllMessages(): void {
    this.ongoingNotifications = [];
    this.snackBar.dismiss();
  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }

  public resolve(notification: SplPushNotification): void {
    switch (notification.data.actionType) {
      // case 'example':
      //   this.examplePushNotificationResolver.exampleResolver(notification.data);
      //   break;
      default:
        this.defaultPushNotificationResolver.defaultResolver(notification.data);
    }
  }
}

<div class="completion-host spl-scroll-container" fusePerfectScrollbar
     [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
  <h1>
    {{ 'IN_TAKE.PROFILE_CREATED' | translate }}    
  </h1>
  <p>
    {{ 'IN_TAKE.YOU_CREATED_PROFILE' | translate }}
  </p>
  <button mat-button
          class="spl-button center mb-16 mt-16"
          (click)="goToDashboard()"
          [attr.aria-label]="'IN_TAKE.GOTO_DASHBOARD' | translate"
          [matTooltip]="'IN_TAKE.GOTO_DASHBOARD' | translate">
    
    {{ 'IN_TAKE.GOTO_DASHBOARD' | translate }}
    
  </button>

  <button mat-button
          class="spl-outline-button center mb-16 mt-16"
          (click)="createAnother()"
          [attr.aria-label]="'IN_TAKE.ADD_ANOTHER_PROFILE' | translate"
          [matTooltip]="'IN_TAKE.ADD_ANOTHER_PROFILE' | translate">
    {{ 'IN_TAKE.ADD_ANOTHER_PROFILE' | translate }}    
  </button>
</div>

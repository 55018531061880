import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { SplCountdownService } from '@common/co/core/components/spl-countdown/spl-countdown.service';
import { AppBusService } from '@common/co/core/services/app-bus.service';

@Component({
  selector: 'spl-athletes-app',
  templateUrl: './spl-athletes-app-layout.component.html',
  styleUrls: ['./spl-athletes-app-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SplCountdownService],
})
export class SplAthletesAppLayoutComponent implements OnInit, OnDestroy {
  private readonly maxSkeletonResultsCount: number = 12;
  fuseConfig: any;
  disableScroll: boolean = false;

  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param splCountdownService
   * @param appBusService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    public splCountdownService: SplCountdownService,
    public appBusService: AppBusService,
    private _cdr: ChangeDetectorRef,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    const skeletonStateArray: { state: boolean }[] = [];
    this.appBusService.displaySkeleton.subscribe((value: any) => {
      if (skeletonStateArray.length === this.maxSkeletonResultsCount) {
        skeletonStateArray.shift();
      }
      skeletonStateArray.push(value);
      this.disableScroll =
        skeletonStateArray.filter((el) => el.state === true)?.length > 0;
      this._cdr.detectChanges();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

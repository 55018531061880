import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { FormFieldModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { SplCoreModule } from '@common/co/core/core.module';
import { SharedModule } from '@common/co/shared/shared.module';
import { SleepTrackerComponent } from './sleep-tracker.component';
import { DirectivesModule } from '@common/shared/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SleepTrackerRoutes } from './sleep-tracker.routes';
import { NgModule } from '@angular/core';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ChartModule } from '@progress/kendo-angular-charts';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { StarRatingComponent } from './star-rating/star-rating.component';

@NgModule({
  declarations: [SleepTrackerComponent, StarRatingComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IntlModule,
    LabelModule,
    FormFieldModule,
    ButtonsModule,
    DateInputsModule,
    InputsModule,
    ChartModule,
    SplCoreModule,
    DirectivesModule,
    TranslateModule,
    SharedModule,
    SleepTrackerRoutes,
  ],
  exports: [RouterModule, SleepTrackerComponent, StarRatingComponent],
})
export class SleepTrackerModule {}

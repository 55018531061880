<div class="reg-complete">
  <img src="assets/images/register/reg-complete.svg" />
</div>
<div class="title-section">
  <h1 class="text-centered">
    {{ 'REGISTER_COMPLETE.REGISTRATION_FINISHED' | translate }}    
  </h1>
  <p class="text-centered">
    {{ 'REGISTER_COMPLETE.YOU_REGISTERED' | translate }}    
  </p>
</div>

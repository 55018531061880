import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BitmovinPlayerComponent } from './components/bitmovin-player/bitmovin-player.component';
import { BitmovinPlayerDialogComponent } from './components/bitmovin-player-dialog/bitmovin-player-dialog.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from '@common/co/core/material.module';
import { BitmovinPlayerPosterComponent } from './components/bitmovin-player-poster/bitmovin-player-poster.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, MaterialModule],
  declarations: [
    BitmovinPlayerComponent,
    BitmovinPlayerDialogComponent,
    BitmovinPlayerPosterComponent,
  ],
  exports: [
    BitmovinPlayerComponent,
    BitmovinPlayerDialogComponent,
    BitmovinPlayerPosterComponent,
  ],
})
export class BitmovinPlayerComponentModule {}

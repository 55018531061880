export function download(link: string, inNewTab: boolean = false): void {
  if (!link) {
    console.log('%cUrl empty. Download abort.');
    throw new Error('%cUrl empty. Download abort.');
  }
  if (inNewTab) {
    window.open(link, '_blank');
  } else {
    window.location.href = link;
  }
}

export const staticFieldsPdfAttachmentFieldName: string = 'ImageLink';
export const dynamicFieldsPdfAttachmentFieldName: string = 'ImageLink';

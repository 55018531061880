import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'training-session-skeleton',
  templateUrl: './training-session-skeleton.component.html',
  styleUrls: ['./training-session-skeleton.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TrainingSessionSkeletonComponent implements OnInit {
  skeletonLoaderTrainingSessionHeader: number = 60;
  skeletonLoaderTrainingSessionSubHeader: number = 66;
  paddingBottom: number = 96;
  blockHeight: number;

  @Input() skeletonHeight: number;

  ngOnInit(): void {
    this.blockHeight =
      this.skeletonHeight -
      this.skeletonLoaderTrainingSessionHeader -
      this.skeletonLoaderTrainingSessionSubHeader -
      this.paddingBottom;
  }
}

import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { SplPushNotificationData } from '@common/co/core/models/splNotification.model';
import { IPushNotificationResolver } from '@common/co/core/resolvers/defaultPushNotification.resolver';
import { NotificationType } from '@common/services/co-api-client';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { ChangeProfileDialogComponent } from '@common/co/auth/components/change-profile-dialog/change-profile-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  INavigationConfig,
  NAVIGATION_CONFIG,
} from '@common/co/navigation/navigation';
import { commonNavigationConfig } from '@common/co/navigation/navigation';

@Injectable()
export class DefaultPushNotificationResolver
  implements IPushNotificationResolver
{
  constructor(
    @Inject(NAVIGATION_CONFIG) private navigationConfig: INavigationConfig,
    private router: Router,
    private authorizeService: AuthorizeService,
    private _matDialog: MatDialog,
  ) {}

  public async defaultResolver(data: SplPushNotificationData): Promise<any> {
    if (
      data.athleteId &&
      this.authorizeService.currentProfileId !== data.athleteId
    ) {
      const res = await this.showChangeProfileDialog(data.athleteId);
      if (!res) {
        return;
      }
    }

    if (
      data.notificationType === NotificationType.Notification0BeforeTrainingTime
    ) {
      this.router.navigateByUrl(
        `${commonNavigationConfig.training_session.url}/${data.objectId}`,
      );
    } else if (
      data.notificationType ===
        NotificationType.Notification10MBeforeTrainingTime ||
      data.notificationType ===
        NotificationType.Notification1HBeforeTrainingTime
    ) {
      this.router.navigateByUrl(commonNavigationConfig.training.url);
    } else if (
      data.notificationType ===
      NotificationType.Notification24HBeforeTrainingTime
    ) {
      this.router.navigateByUrl(this.navigationConfig['dashboard'].url);
    } else {
      this.router.navigateByUrl(this.navigationConfig['dashboard'].url);
    }
  }

  async showChangeProfileDialog(profileId: string): Promise<boolean> {
    return new Promise((resolve) => {
      const confirmDialogRef = this._matDialog.open(
        ChangeProfileDialogComponent,
        {
          disableClose: false,
        },
      );
      confirmDialogRef.componentInstance.currentProfile =
        this.authorizeService.currentProfile;
      confirmDialogRef.componentInstance.targetProfile = _.find(
        this.authorizeService.availableProfiles,
        (p) => p.id == profileId,
      );
      confirmDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.authorizeService.setCurrentProfileId(profileId);
        }
        resolve(result);
      });
    });
  }
}

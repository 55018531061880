<div class="title-section">
  <h1>
    {{ 'COMMON.YOUR_PASSWORD' | translate }}    
  </h1>
  <p>
    {{ 'CHANGE_PASSWORD.CREATE_SECURE_PASSWORD' | translate }}
  </p>

  <p>
    {{ 'CHANGE_PASSWORD.PASSWORD_MUST_INCLUDE' | translate }}
  </p>
</div>

<form [formGroup]="registerForm">
  <app-password-input [control]="registerForm.controls['password']" 
  [label]="'COMMON.PASSWORD'"
  [labelRequired]="'COMMON.PASSWORD_REQUIRED'"
  >
</app-password-input>
  
  <app-password-input [control]="registerForm.controls['confirmPassword']" 
  [label]="'CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD'"
  [labelRequired]="'CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD_REQUIRED'"
  [passwordMustMatch]="'CHANGE_PASSWORD.PASSWORD_MUST_MATCH'" 
  >
</app-password-input>
</form>

<button mat-button class="spl-button center mt-16 mb-16"
        (click)="next()"
        [disabled]="!completed" 
        [attr.aria-label]="'COMMON.ACTIONS.CONTINUE' | translate" 
        [matTooltip]="'COMMON.ACTIONS.CONTINUE' | translate" 
        >
  {{ 'COMMON.ACTIONS.CONTINUE' | translate }}
  
</button>

import {
  ComponentOption, ComponentType,
  ExerciseResultDto,
  TrainingExerciseScoringJsonDataComponent,
} from '@common/services/co-api-client';

export class ExerciseResultCustom extends ExerciseResultDto {
  selectedOptionTitle?: string;
  code?: string;
  hasValue: boolean;
  incomplete: boolean;
  componentType?: ComponentType;
}

export class TrainingExerciseScoringJsonDataComponentCustom extends TrainingExerciseScoringJsonDataComponent {
  options: ComponentOptionCustom[];
  disabledPasteValue: boolean;
}

export class ComponentOptionCustom extends ComponentOption {
  customValue: number;
}

export enum ExerciseStatus {
  InProgress,
  Saving,
  Saved,
  Changed,
}

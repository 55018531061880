import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  APPLICATIONS_PATHS,
  IApplicationPathsType,
  ReturnUrlType,
} from '@common/co/core/app.constants';
import { InTakeService } from '@common/co/auth/services/in-take.service';
import { AuthProviders } from '@common/co/auth/model';
import { AuthClient } from '@common/services/co-api-client';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';
import { NotificationService } from '@common/co/core/services/notification.service';
import { StorageService } from '@common/services/storage.service';
import { IAppConfig, APP_CONFIG } from '@common/co/core/config/app.config';
import { SocialLoginService } from '@common/co/core/services/social-login.service';
import {
  INavigationConfig,
  NAVIGATION_CONFIG,
} from '@common/co/navigation/navigation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errorMessage: string = null;
  appVersion: string = undefined;

  processLogin: boolean = false;
  private returnUrlErrorText: string =
    'Invalid return url. The return url needs to have the same origin as the current page.';
  /**
   * Constructor
   *
   * @param applicationPaths
   * @param navigationConfig
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   * @param authService
   * @param activatedRoute
   * @param router
   * @param inTakeService
   * @param authClient
   * @param fuseProgressBar
   * @param platformService
   * @param notificationService
   * @param storageService
   * @param appConfig
   */
  constructor(
    @Inject(APPLICATIONS_PATHS) private applicationPaths: IApplicationPathsType,
    @Inject(NAVIGATION_CONFIG) public navigationConfig: INavigationConfig,
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private authService: AuthorizeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private inTakeService: InTakeService,
    private authClient: AuthClient,
    private fuseProgressBar: FuseProgressBarService,
    private notificationService: NotificationService,
    private storageService: StorageService,
    private socialLoginService: SocialLoginService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) appConfig: IAppConfig,
  ) {
    this.appVersion = appConfig.version;
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(): Promise<any> {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.translateService
      .get(['LOGIN.RETURN_URL_ERROR'])
      .subscribe((translations) => {
        this.returnUrlErrorText =
          translations['LOGIN.RETURN_URL_ERROR'] || this.returnUrlErrorText;
      });
  }

  // async googleLogin(): Promise<any> {
  //   const returnUrl = this.getReturnUrl();
  //   const token = await this.socialLoginService.googleSignIn();
  //   await this.authenticateGoogleUser(token, returnUrl);
  // }

  // async facebookLogin(): Promise<any> {
  //   const returnUrl = this.getReturnUrl();
  //   const token = await this.socialLoginService.facebookSignIn();
  //   if (!token) {
  //     return;
  //   }
  //   await this.authenticateFacebookUser(token, returnUrl);
  // }

  public async authenticateGoogleUser(
    googleToken: string,
    returnUrl: string,
  ): Promise<void> {
    this.fuseProgressBar.show();
    this.processLogin = true;
    const oidcConfig = await this.authService.getOidcConfigSafe();
    try {
      const externalResult = await this.authClient
        .externalGoogleSignIn(oidcConfig.appId, googleToken)
        .toPromise();
      if (externalResult && externalResult?.success) {
        await this.authService.externalAuthenticateUser(
          externalResult.accessToken,
          externalResult.refreshToken,
          AuthProviders.google,
        );
        await this.navigateToReturnUrl(returnUrl);
      } else {
        this.notificationService.generateNotification(
          getSplNotification('error', externalResult?.reason),
        );
      }
    } finally {
      this.fuseProgressBar.hide();
      this.processLogin = false;
    }
  }

  public async authenticateFacebookUser(
    token: string,
    returnUrl: string,
  ): Promise<void> {
    this.fuseProgressBar.show();
    this.processLogin = true;
    const oidcConfig = await this.authService.getOidcConfigSafe();
    try {
      const externalResult = await this.authClient
        .externalFacebookSignIn(oidcConfig.appId, token)
        .toPromise();
      if (externalResult && externalResult?.success) {
        await this.authService.externalAuthenticateUser(
          externalResult.accessToken,
          externalResult.refreshToken,
          AuthProviders.google,
        );
        await this.navigateToReturnUrl(returnUrl);
      } else {
        this.notificationService.generateNotification(
          getSplNotification('error', externalResult?.reason),
        );
      }
    } finally {
      this.fuseProgressBar.hide();
      this.processLogin = false;
    }
  }

  /**
   * On Login
   */
  async onLogin(): Promise<void> {
    this.fuseProgressBar.show();
    const loginFields = this.loginForm.getRawValue();
    const returnUrl = this.getReturnUrl();
    this.processLogin = true;
    try {
      await this.authService.authenticateUser(
        loginFields.email,
        loginFields.password,
      );
    } finally {
      this.processLogin = false;
      this.fuseProgressBar.hide();
    }

    console.debug('successfully logged in');
    await this.navigateToReturnUrl(returnUrl);
    return Promise.resolve();
  }
  private navigateToReturnUrl(returnUrl: string): Promise<boolean> {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    return this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (
      this.activatedRoute.snapshot.queryParams as INavigationState
    ).returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (
      fromQuery &&
      !(
        fromQuery.startsWith(`${window.location.origin}/`) ||
        // eslint-disable-next-line no-useless-escape
        /\/[^\/].*/.test(fromQuery)
      )
    ) {
      // This is an extra check to prevent open redirects.
      throw new Error(this.returnUrlErrorText);
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      this.applicationPaths.DefaultLoginRedirectPath
    );
  }
}

interface INavigationState {
  [ReturnUrlType]: string;
}

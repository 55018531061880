import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ICustomDialog } from '@common/shared/components/custom-dialog/custom-dialog.interface';
import { CUSTOM_DIALOG_CONFIG } from '.';
import { CustomDialogComponent } from '@common/shared/components/custom-dialog/custom-dialog.component';
import {
  finalize,
  first,
  scan,
  skipWhile,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomDialogService {
  public title: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  public description: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  public confirmButtonText: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  public cancelButtonText: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  public cancellationTime: BehaviorSubject<number | null> = new BehaviorSubject<
    number | null
  >(null);
  public showCancelButton: boolean = true;
  constructor(
    @Inject(CUSTOM_DIALOG_CONFIG)
    private customDialogConfig: Omit<MatDialogConfig, 'data'>,
    private dialog: MatDialog,
  ) {}

  public showDialog(config?: ICustomDialog): void {
    this.setDialogContent(config);
    const dialog = this.dialog.open(
      CustomDialogComponent,
      this.customDialogConfig,
    );
    dialog.componentInstance.dialogActionsAlign =
      (config.dialogActionsAlign as 'start' | 'center' | 'end') || 'end';
    dialog
      .afterOpened()
      .pipe(first())
      .subscribe(() => {
        dialog.componentInstance.result
          .pipe(first())
          .subscribe((result: boolean) => {
            if (result && config?.cancellationTime) {
              this.cancellationTime.next(config.cancellationTime + 1);
              this.subscribeToCancelActionTimer(
                config.cancellationTime + 1,
                dialog.componentInstance.result,
                () => {
                  config.confirmAction.call(this);
                  dialog.close();
                },
                () => {
                  if (
                    config?.cancelAction &&
                    typeof config.cancelAction === 'function'
                  ) {
                    config.cancelAction.call(this);
                  }
                  dialog.close();
                },
              );
              return;
            }
            if (result && !config?.cancellationTime) {
              config.confirmAction.call(this);
            }
            if (
              !result &&
              config?.cancelAction &&
              typeof config.cancelAction === 'function'
            ) {
              config.cancelAction.call(this);
            }
            dialog.close();
          });
      });
  }

  private subscribeToCancelActionTimer(
    startTime: number,
    cancelNotifier: Observable<boolean>,
    successCallback: () => void,
    cancelCallback: () => void,
  ): Subscription {
    return timer(0, 1000)
      .pipe(
        takeUntil(
          cancelNotifier.pipe(
            skipWhile((v: boolean) => v === true),
            first(),
            tap(() => cancelCallback.call(this)),
          ),
        ),
        scan((acc: number) => --acc, startTime),
        takeWhile((timeRemaining) => timeRemaining >= 0),
        tap((v: number) => (v === 0 ? successCallback.call(this) : false)),
        finalize(() => {
          setTimeout(() => this.cancellationTime.next(null), 300);
        }),
      )
      .subscribe((val: number) => this.cancellationTime.next(val));
  }

  private setDialogContent(config?: ICustomDialog): void {
    this.title.next(config.title);
    this.description.next(config.description);
    this.confirmButtonText.next(config?.confirmButtonText);
    this.cancelButtonText.next(config?.cancelButtonText);
    this.showCancelButton =
      config && config.showCancelButton != undefined
        ? config.showCancelButton
        : true;
  }
}

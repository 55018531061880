<spl-skeleton class="solo-column" [showSkeleton]="loadingPromise">
<div class="sleep-tracker">

    <div class="section calendar-holder">
        <kendo-calendar #calendar type="classic" class="calendar"  
        [disabledDates]="disabledDates"
        [navigation]="false"
        [value]="selectedDate"
        [max]="calendarMaxDate"
        (valueChange)="onSelectedDayChange($event)"
        (navigate)="onNavigate($event)"
        (activeViewChange)="onActiveViewChange($event)"
        >
            <ng-template kendoCalendarMonthCellTemplate let-date let-context="cellContext">
                <span [ngClass]="resolveDayCellStyles(date)">{{context.formattedValue}}</span>
            </ng-template>
        </kendo-calendar>
    </div>

    <form 
    [formGroup]="formGroup"
    novalidate>

    <div class="section inputs" *ngIf="selectedDay">
        <span class="section-title main-title">{{ selectedDate | date }}</span>
        <span *ngIf="prevDay" class="prev-day">Previous sleep record: {{prevDay?.sleepStartTime | date: 'short' }} - {{ prevDay?.sleepEndTime | date: 'short'}}</span>
        <div class="border-box date-inputs">
            <div class="sleep-indicator">
                <div class="half-circle"></div>
                <div class="total-sleep-time">{{ totalSleepTimeString }}</div>
            </div>
            <div class="sleep-time-inputs">
                
                <div class="sleep-time-input">
                    <span class="control-label">Sleep Time {{ selectedDay.sleepStartTime | date:'MM/dd'}}</span>
                        <kendo-timepicker
                        formControlName="sleepStartTime"
                        title="Select Time"
                        subtitle="HH:ss"
                        [max]="maxSleepStart"
                        (valueChange)="sleepStartChange($event)"
                        ></kendo-timepicker>
                </div>
                <div class="sleep-time-input">
                    <span class="control-label">Wake Time {{ selectedDay.sleepEndTime | date:'MM/dd' }}</span>
                        <kendo-timepicker
                        [min]="minSleepEnd"
                        [max]="maxSleepEnd"
                        formControlName="sleepEndTime"
                        title="Select Time"
                        subtitle="HH:ss"
                        (valueChange)="sleepEndChange($event)"
                        ></kendo-timepicker>
                </div>
            </div>
        </div>

        <div class="section error-messages">
            <span class="error-message" *ngIf="!formGroup.valid || selectedDay.sleepStartTime >= selectedDay.sleepEndTime">End time must be greater than start time</span>
            <span class="error-message" *ngIf="!formGroup.valid && selectedDay.sleepStartTime == null">Start time is required</span>
            <span class="error-message" *ngIf="!formGroup.valid && selectedDay.sleepEndTime == null">End time is required</span>
        </div>

        <div class="subsection border-box sleep-rate">
            <span class="section-title center-text">Rate your sleep</span>
            <!--
            TODO: replace with kendo component when we can update kendo in project
            <kendo-rating precision="half" [(value)]="selectedDay.sleepRate"></kendo-rating>
            (value)="selectedDay.sleepRate"
            -->
            <star-rating 
            precision="half" 
            (valueChange)="sleepRateChange($event)"
            formControlName="sleepRate"
            ></star-rating>
        </div>
        
        <div class="subsection action-buttons">
            <button [disabled]="isLoading" mat-button class="spl-outline-button center" (click)="cancelEdit()">Cancel</button>
            <button [disabled]="(originalValue == currentValue) || !formGroup.valid || isLoading || selectedDay.sleepStartTime >= selectedDay.sleepEndTime" mat-raised-button class="spl-button center" (click)="submitValues()">
                <span *ngIf="isNewDay">Submit</span>
                <span *ngIf="!isNewDay">Update</span>
                <mat-spinner *ngIf="isLoading" color="accent" diameter="15"></mat-spinner>
            </button>
        </div>
    </div>
    
    </form>


    <div class="section bar-chart mt-12" *ngIf="!selectedDay">
        <span class="section-title">Last 30 Days</span>
        <div class="chart-holder border-box">
            <div class="sleep-times-holder">
                <div class="sleep-time-item">
                    <span class="sleep-time-sm-label">Average Time In Bed</span>
                    <span class="sleep-time-lg-label">{{formatDurationMsToTextWithZeroCheck(monthAverageTime)}}</span>
                </div>
                <div class="h-spacer"></div>
                <div class="sleep-time-item">
                    <span class="sleep-time-sm-label">Last Tracked Sleep</span>
                    <span class="sleep-time-lg-label">{{formatDurationMsToTextWithZeroCheck(getTotalSleepTime(latestSleepRecord)) }}</span>
                </div>
            </div>
            <kendo-chart 
            class="sleep-chart"
            [pannable]="{ lock: 'y' }"
            [zoomable]="{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }"
            renderAs="canvas"
            [categoryAxis]="{
                min: chartMin,
                max: chartMax,
                labels: categoryAxisLabels
              }"
            >

                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-value="value">
                    Sleep time: {{ formatDurationMsToTextWithZeroCheck(value) }}
                    </ng-template>
                </kendo-chart-tooltip>

                <kendo-chart-area background="transparent" [margin]="2"> </kendo-chart-area>

                <kendo-chart-axis-defaults [majorGridLines]="{ visible : false}">
                </kendo-chart-axis-defaults>

                <kendo-chart-value-axis >
                    <kendo-chart-value-axis-item
                      [title]="{ text: '' }"
                      [min]="0"
                      [max]="100000000">
                      <kendo-chart-value-axis-item-labels [content]="valueLabel">
                    </kendo-chart-value-axis-item-labels>
                    </kendo-chart-value-axis-item>
                  </kendo-chart-value-axis>

                <kendo-chart-series>
                  <kendo-chart-series-item type="column" 
                  [data]="monthChartData"
                  field="duration"
                  categoryField="date"
                  [color]="getChartItemColor"
                  >
                  </kendo-chart-series-item>
                </kendo-chart-series>

            </kendo-chart>
        </div>
    </div>

    <div class="spinner-wrapper" *ngIf="isLoading">
        <mat-spinner [diameter]="70"></mat-spinner>
    </div>

</div>
</spl-skeleton>
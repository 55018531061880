<h3 mat-dialog-title>{{ title | async }}</h3>
<div mat-dialog-content class="custom-description">{{ description | async }}</div>
<div mat-dialog-actions [align]="dialogActionsAlign" class="mat-dialog-actions-custom">
  <button
    mat-button
    class="spl-button mr-auto cancel-timer-btn"
    *ngIf="cancellationTime | async | translate"
    (click)="cancel()">
    Cancel
    <span *ngIf="cancellationTime | async">&nbsp;{{ cancellationTime | async | translate}}</span>
  </button>
  <button
    mat-button
    class="spl-outline-button"
    *ngIf="!(cancellationTime | async) && showCancelButton"
    (click)="cancel()">
    {{ (cancelButtonText | async | translate) || ("COMMON.ACTIONS.CANCEL" | translate) }}
  </button>
  <button
    mat-button
    class="spl-outline-button"
    *ngIf="!(cancellationTime | async)"
    (click)="confirm()">
    {{ (confirmButtonText | async) || ("COMMON.ACTIONS.YES" | translate) }}
  </button>
</div>

<div class="gauge-chart-wrapper">
  <div class="gauge-chart">
    <ngx-charts-gauge
      [view]=""
      [legend]="config.legend"
      [legendTitle]="config.legendTitle"
      [legendPosition]="config.legendPosition"
      [scheme]="config.colorScheme"
      [angleSpan]="config.angleSpan"
      [startAngle]="config.startAngle"
      [showAxis]="config.showAxis"
      [max]="config.max"
      [min]="config.min"
      [tooltipDisabled]="config.tooltipDisabled"
      [showText]="config.showText"
      [results]="seriesForGauge"
    >
    </ngx-charts-gauge>
  </div>
  
  <div class="custom-legend">
    <div class="legend-item" *ngFor="let item of series; let i = index;">
      <span class="legend-title">{{ item.name }}</span>
      <span class="legend-score" [ngStyle]="{'color': config.colorScheme.domain[i]}">{{ item.value }}</span>
    </div>
  </div>
</div>
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { Router } from '@angular/router';
import { BreadcrumbsService } from '@common/co/core/services/breadcrumbs.service';
import { SplNavigationService } from '@common/co/core/components/navigation/navigation.service';
import { PlatformService } from '@common/co/core/services/platform.service';
import { FuseNavigation } from '@fuse/types';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  navigation: FuseNavigation[];
  mobileLayout: boolean;

  // Private
  private _unsubscribeAll: Subject<void>;
  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   * @param {Router} _router
   * @param {AuthorizeService} authorizeService
   * @param {BreadcrumbsService} breadcrumbService
   * @param {SplNavigationService} splNavigationService
   * @param platformService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _router: Router,
    public authorizeService: AuthorizeService,
    public breadcrumbService: BreadcrumbsService,
    public splNavigationService: SplNavigationService,
    private platformService: PlatformService
  ) {
    // Set the defaults
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });
  
    if (this.platformService.isMobile) {
      this.mobileLayout = true;
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key: string): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  onTenantChanged(): void {
    this._router.navigateByUrl('');
  }

  onProfileChanged(): void {
    this._router.navigateByUrl('');
  }

  goBack(): void {
    this.splNavigationService.goBack();
  }
}

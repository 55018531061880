import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILocale } from '@common/i18n/ilocale';

@Component({
  selector: 'country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
})
export class CountrySelectorComponent {
  @Input()
  public selectedLang: ILocale;

  @Input()
  public languages: ILocale[] = [];

  @Output()
  public changeLanguage: EventEmitter<ILocale> = new EventEmitter<ILocale>();

  public onSelectLanguage(lang: ILocale): void {
    this.changeLanguage.emit(lang);
  }
}

interface IApplicationDateFormats {
  readonly DateTimeFormat: string;
  readonly DateOnlyFormat: string;
  readonly DateTimeFormatMilitary: string;
}

const applicationDateFormats: IApplicationDateFormats = {
  DateTimeFormat: 'MM/dd/yyyy h:mm a',
  DateTimeFormatMilitary: 'MM/dd/yyyy_HH:mm',
  DateOnlyFormat: 'MM/dd/yyyy',
};

export const ApplicationDateFormats: IApplicationDateFormats =
  applicationDateFormats;

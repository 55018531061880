import { Component, Input, OnChanges } from '@angular/core';
import { IMovementScoresRangeItem } from '@common/co/shared/models/movement-scores-range-config';

@Component({
  selector: 'color-gradient',
  templateUrl: './color-gradient.component.html',
  styleUrls: ['./color-gradient.component.scss'],
})
export class ColorGradientComponent implements OnChanges {
  @Input() title: string;
  @Input() value: number;
  @Input() config: IMovementScoresRangeItem[];
  colorString: string = '90deg';
  level: string;
  position: number = 0;
  valueColor: string;
  margin: number = 4;
  pointerWidth: number = 7;

  ngOnChanges(): void {
    if (this.value === null) {
      this.colorString = '';
      return;
    }
    const foundEl: IMovementScoresRangeItem = this.config.find((el) => {
      return this.value >= el.start && this.value <= el.end;
    });
    this.level = foundEl.level;
    this.valueColor = foundEl.color;
    this.config.forEach((el) => {
      this.colorString = this.colorString + `, ${el.color} ${el.start}%`;
    });

    const displayBlock = document.getElementsByClassName('display')[0];
    if (displayBlock?.clientWidth) {
      this.position = displayBlock.clientWidth * (this.value / 100);
      if (this.position < displayBlock?.clientWidth / 2) {
        this.position += this.margin;
      } else {
        this.position -= this.margin + this.pointerWidth;
      }
    }
  }
}

import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
  ComponentType,
  ExerciseDto, TrainingExerciseScoringType,
} from '@common/services/co-api-client';
import {
  ExerciseResultCustom,
  TrainingExerciseScoringJsonDataComponentCustom,
} from '@common/co/shared/models/station-exercise-custom.model';
import { StationExerciseResultsMapService } from '@common/co/shared/services/station-exercise-results.map-service';

@Component({
  selector: 'exercise-info',
  templateUrl: './exercise-info.component.html',
  styleUrls: ['./exercise-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExerciseInfoComponent implements OnChanges {
  @Input() data: ExerciseDto;
  @Input() trainingInFuture: boolean;
  
  componentType = ComponentType;
  trainingExerciseScoringType = TrainingExerciseScoringType;
  customExerciseResults: ExerciseResultCustom[] = [];
  customComponents: TrainingExerciseScoringJsonDataComponentCustom[] = [];

  constructor(
    private stationExerciseResultsMapService: StationExerciseResultsMapService,
  ) {}

  ngOnChanges(): void {
    if (this.data.resultTypeExtraData.components) {
      this.customComponents =
        this.stationExerciseResultsMapService.getCustomComponents(
          this.data.resultTypeExtraData.components,
        );
    }
    this.customExerciseResults =
      this.stationExerciseResultsMapService.getCustomExerciseResults(
        this.data.results,
        this.customComponents,
      );
  }
}

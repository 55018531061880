<div class="rating-holder">
    <button mat-icon-button 
        *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
        [ngClass]="{'big-icon-button':true}" 
        (click)="onClick($event, i+1)" 
        [color]="getColor(i+1)">
        <mat-icon>
            {{showIcon(i+1)}}
        </mat-icon>
    </button>
</div>
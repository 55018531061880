import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateAsDay',
  pure: false,
})
export class DateAsDayPipe implements PipeTransform {
  transform(value: Date): string {
    const date = moment(value);
    const isCurrentDate = date.isSame(new Date(), 'day');
    const isTomorrowDate = date.isSame(moment().add(1, 'day'), 'day');
    if (isCurrentDate) {
      return this.getDate(value, 'Today');
    } else if (isTomorrowDate) {
      return this.getDate(value, 'Tomorrow');
    } else {
      return this.getDate(value);
    }
  }

  private getDate(value, day?): string {
    if (day) {
      return `${day}, ${moment(value).format('MM/DD @ h:mma')}`;
    } else {
      return moment(value).format('dddd, MM/DD @ h:mma');
    }
  }
}

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  SkeletonModeType,
  SkeletonType,
} from '@common/co/shared/components/skeleton/models/skeleton.model';
import { AppBusService } from '@common/co/core/services/app-bus.service';

@Component({
  selector: 'spl-skeleton',
  templateUrl: './spl-skeleton.component.html',
  styleUrls: ['./spl-skeleton.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SplSkeletonComponent implements OnChanges {
  @Input() showSkeleton: Promise<any>;
  @Input() mode: SkeletonModeType = SkeletonModeType.ByScreen; // screen to end
  @Input() type: SkeletonType = SkeletonType.Default;

  skeletonType = SkeletonType;
  displaySkeleton: boolean;

  public skeletonHeight: number;

  constructor(
    private _el: ElementRef,
    public appBusService: AppBusService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    if (this.showSkeleton) {
      if (this.mode === SkeletonModeType.ByContent) {
        this.skeletonHeight = Number(
          getComputedStyle(this._el.nativeElement).minHeight.replace('px', ''),
        );
      } else {
        this.skeletonHeight =
          window.innerHeight -
          this._el.nativeElement.getBoundingClientRect().top;
      }

      this.displaySkeleton = true;
      const displaySkeletonState = {
        state: this.displaySkeleton,
      };
      this.appBusService.displaySkeleton.next(displaySkeletonState);

      this.showSkeleton
        .then(() => {
          this.hideSkeleton(displaySkeletonState);
        })
        .catch(() => {
          this.hideSkeleton(displaySkeletonState);
        });
    }
  }

  private hideSkeleton(displaySkeletonState: { state: boolean }): void {
    this.displaySkeleton = false;
    displaySkeletonState.state = this.displaySkeleton;
    this.appBusService.displaySkeleton.next(displaySkeletonState);
    this.changeDetection();
  }

  private changeDetection(): void {
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }
}

import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { commonNavigationConfig } from '@common/co/navigation/navigation';
import { UserPreferencesComponent } from '@common/co/feature/preferences/components/user-preferences/user-preferences.component';
import { appGuards, MasterGuard } from '@common/co/auth/guards/master-guard';

const preferencesRoutes: Routes = [
  {
    path: 'user-preferences',
    component: UserPreferencesComponent,
    canActivate: [MasterGuard],
    data: {
      ...commonNavigationConfig.preferences.payload,
      guards: appGuards,
    },
  },
];

export const PreferencesRoutes: ModuleWithProviders<any> =
  RouterModule.forChild(preferencesRoutes);

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PageTabModel } from '@common/co/shared/models/page-tab.model';
import { LoggingService } from '@common/services/logging.service';
import * as _ from 'lodash';

@Component({
  selector: 'tabs-menu',
  templateUrl: './tabs-menu.component.html',
  styleUrls: ['./tabs-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsMenuComponent implements OnChanges {
  @Input() tabs: PageTabModel[] = [];

  public selectedTab: PageTabModel;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private loggingService: LoggingService,
  ) {}

  ngOnChanges(): void {
    if (this.tabs.length > 0) {
      this.setSelectedTab(this.tabs[0]);
    }
  }

  setSelectedTab(tab: PageTabModel): void {
    this.selectedTab = tab;
    this.changeDetector.markForCheck();
    this.setVisibleBlocks();
  }

  private setVisibleBlocks(): void {
    const elements = document.getElementById(this.selectedTab.elementId)
      ?.parentElement?.children as HTMLCollectionOf<HTMLElement>;
    this.loggingService.logTrace('Set Visible Blocks in tabs-menu component', {
      selectedTab: JSON.stringify(this.selectedTab),
      tabs: JSON.stringify(this.tabs),
      elementsIsUndefined: JSON.stringify(_.isUndefined(elements)),
    });
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        if (this.selectedTab.elementId === elements[i].id) {
          elements[i].classList.add('visible-block');
          elements[i].classList.remove('hide-block');
        } else {
          elements[i].classList.add('hide-block');
          elements[i].classList.remove('visible-block');
        }
      }
    }
  }
}

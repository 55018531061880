import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { DOCUMENT } from '@angular/common';
import { PlatformService } from '@common/co/core/services/platform.service';

@Injectable({ providedIn: 'root' })
export class ZendeskService {
  private readonly zendeskInitError: string =
    'Zendesk Plugin is not initialized';

  private renderer: Renderer2;
  private iosSafeAreaClassWasAdded: boolean = false;
  public isInitialized: boolean = false;

  constructor(
    private _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService,
    private platformService: PlatformService,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    const interval = setInterval(() => {
      if (this._NgxZendeskWebwidgetService.isInitialized) {
        this.init();
        clearInterval(interval);
      }
    }, 500);
  }

  public async init(): Promise<void> {
    this.isInitialized = true;
    this._NgxZendeskWebwidgetService.zE(
      'messenger:on',
      'close',
      function (): void {
        this.hideZendesk();
      }.bind(this),
    );
    this.setPaddingForMessagingWindow();
  }

  private async setPaddingForMessagingWindow(): Promise<void> {
    if (!(await this.platformService.isIos()) || this.iosSafeAreaClassWasAdded)
      return;
    const body = this.renderer.selectRootElement('body', true);
    const children: Element[] = Array.from(body.children);
    const foundIframeNode: Element = this.getIframeNode(children);
    if (foundIframeNode) {
      this.renderer.addClass(foundIframeNode, 'zendesk-ios-safe-area');
      this.iosSafeAreaClassWasAdded = true;
    }
  }

  private getIframeNode(elements: Element[]): Element {
    for (const node of elements) {
      if (node.nodeName === 'IFRAME' && node['title'] === 'Messaging window')
        return node;
      if (node.nodeName === 'DIV') {
        const child = this.getIframeNode(Array.from(node.children));
        if (child) return child;
      }
    }
  }

  public async openChat(): Promise<void> {
    if (!this.isInitialized) throw new Error(this.zendeskInitError);
    this._NgxZendeskWebwidgetService.zE('messenger', 'open');
    setTimeout(() => {
      this.setPaddingForMessagingWindow();
    }, 1);
  }

  public hideZendesk(): void {
    if (!this.isInitialized) throw new Error(this.zendeskInitError);
    this._NgxZendeskWebwidgetService.zE('messenger:set', 'zIndex', -1);
  }

  public showZendesk(): void {
    if (!this.isInitialized) throw new Error(this.zendeskInitError);
    this._NgxZendeskWebwidgetService.zE('messenger:set', 'zIndex', 999999);
  }
}

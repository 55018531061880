<div class="info-blocks-wrapper" *ngIf="panelInfo">
  <span class="title">
    {{ panelInfo.title }}
  </span>
  <div class="info-blocks">
    <div *ngFor="let block of panelInfo.blocks" class="info-block">
      <span class="block-title">
        {{ block.title }}
      </span>
      <span class="block-info">
        <span>{{ block.info }}</span>
        <span class="measure">{{ block.unitOfMeasure }}</span>
        <span class="delta" *ngIf="block.delta !== null && block.delta !== undefined">
          <img [src]="'assets/images/icons/' + (block.delta ? 'delta-bottom.svg' : 'delta-top.svg')">
        </span>
      </span>
    </div>
  </div>
</div>

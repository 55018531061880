<div class="change-password-wrapper solo-column">
  <div class="title-section">
    <h1>
      {{ 'CHANGE_PASSWORD.CHANGE_PASSWORD' | translate }}
    </h1>
    <p>
      {{ 'CHANGE_PASSWORD.CREATE_SECURE_PASSWORD' | translate }}
    </p>

    <p>
      {{ 'CHANGE_PASSWORD.PASSWORD_MUST_INCLUDE' | translate }}
    </p>
  </div>
    
  <app-password-input 
    [control]="registerForm.controls['oldPassword']" 
    [label]="'COMMON.PASSWORD'" 
    [labelRequired]="'COMMON.PASSWORD_REQUIRED'" 
    [displayError]="!registerForm.get('oldPassword').hasError('required') && registerForm.get('oldPassword').hasError('oldPassword')"
    [error]="registerForm.get('oldPassword').getError('oldPassword')">
  </app-password-input>
    
  <app-password-input
    [control]="registerForm.controls['newPassword']"
    [label]="'CHANGE_PASSWORD.NEW_PASSWORD'"
    [labelRequired]="'CHANGE_PASSWORD.NEW_PASSWORD_REQUIRED'"
    [passwordMustMatch]="'CHANGE_PASSWORD.PASSWORD_MUST_MATCH'" 
    [displayError]="!registerForm.get('newPassword').hasError('required') && registerForm.get('newPassword').hasError('newPassword')"
    [error]="registerForm.get('newPassword').getError('newPassword')">
  </app-password-input>
  
  <app-password-input
    [control]="registerForm.controls['confirmPassword']"
    [label]="'CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD'"
    [labelRequired]="'CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD_REQUIRED'"
    [passwordMustMatch]="'CHANGE_PASSWORD.PASSWORD_MUST_MATCH'" 
    >
    
  </app-password-input>

  <button mat-button class="spl-button center mt-16 mb-16"
          (click)="change()"
          [disabled]="!completed" 
          [attr.aria-label]="'COMMON.ACTIONS.CONTINUE' | translate" 
          [matTooltip]="'COMMON.ACTIONS.CONTINUE' | translate">
    {{ 'COMMON.ACTIONS.CONTINUE' | translate }}    
  </button>
</div>
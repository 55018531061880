import { LinksMenuModel } from '@common/co/shared/models/links-menu.model';
import { TranslateService } from '@ngx-translate/core';

export interface ILocaleWithData extends ILocale {
  data: ILocaleData;
}
export interface ILocale {
  lang: string;
  code: string;
  humanTitle: string;
}

export interface ILocaleData {
  COMMON: ICommonLocalization;
  NAV: INavMenuLocalization;
  EXTRA_NAV?: INavExtras; // this is defined in particular app

  LOGIN: ILoginLocalization;
  REGISTER: IRegisterComponentLocalization;
  REGISTER_COMPLETE: IRegisterCompleteComponentLocalization;
  IN_TAKE: IIntakeComplete;
  FORGOT_PASSWORD: IForgotPasswordLocalization;
  RESET_PASSWORD: IResetPasswordLocalization;
  CHANGE_PASSWORD: IChangePasswordLocalization;
  CHANGE_PROFILE: IChangeProfileLocalization;
  CHECK_UNIQ_ID: ICheckUniqIdLocalization;
  PERSONAL_INFO: IPersonalInfoLocalization;

  DASHBOARD: IDashboardLocalization;

  ASSESSMENTS: IAssesmentsLocalization;
  TRAININGS: ITrainingsLocalization;

  ABOUT: IAboutLocalization;
  PREFERENCES: IPreferencesLozalization;
  RECOMMENDATIONS: IRecommendationsLocalization;
  TESTING_HISTORY: ITestingHistoryLocalization;
  EVENTS_INFO: IEventsInfoLcalization;

  DIALOGS: IDialogsLocalization;
}

export interface IDialogsLocalization {
  WARNING_TITLE: string;
  DELETE_ACC_TITLE1: string;
  DELETE_ACC_TITLE2: string;
  DELETE_ACC_DESC: string;
  ATTEMPT_START_NEW_LVL: string;
  ATTEMPT_START_NO_REST: string;
  INSTRUCTOR_CHAT: string;
}
// eslint-disable-next-line  @typescript-eslint/no-empty-interface
export interface INavExtras {}

export interface IIntakeComplete {
  PROFILE_CREATED: string;
  YOU_CREATED_PROFILE: string;
  GOTO_DASHBOARD: string;
  ADD_ANOTHER_PROFILE: string;
}
export interface IAssesmentsLocalization {
  UPCOMING_TESTS: string;
  NO_UPCOMING_TESTS: string;
  TEST_SUMMARY: string;
  NO_COMPLETED_TESTS: string;
  TAP_TO_VIEW_RESULTS: string;
  DIAGNOSIS: string;
  NO_DIAGNOSIS: string;
  EXERCISE_PROGRAM: string;
  EXERCISE_PROGRAM_ASSIGNED: string;
  NO_EXERCISE_PROGRAM: string;
  VISION_TEST_TITLE: string;
  VISION_TEST_DESC: string;
  PDF_REPORT: string;
  TAP_TO_DOWNLOAD_REPORT: string;
  RESULTS_NOT_AVAILIABLE: string;

  SUMMARY: string;
  MY_SCORES: string;
  TESTS_OVER_TIME: string;
  BREAKDOWN: string;
  DATA: string;
  EXERCISE: string;
  RESULT: string;
  ERRORS: string;
  BEST: string;
  BEST_PERCENT: string;
  TOTAL_SCORE: string;
  SCORE: string;
  MEASUREMENTS: string;
  CURR_WEIGHT: string;
}

export interface ITrainingsLocalization {
  EXERCISE_PROGRAM: string;
  COMPLETENESS: string;
  NO_PROGRAMM_ASSIGNED: string;
  NO_PROGRAMM_ASSIGNED_YET: string;
  HISTORY: string;
  HERE_IS_HISTORY: string;
  TAP_ONE_TO_VIEW: string;
  DATE: string;
  YOU_ARE_AT: string;
  NO_EXERCISE_FOR_STATION: string;
  SAVE_YOUR_STATS: string;
  SAVING_YOUR_STATS: string;
  STATS_SAVED: string;
  TRAINING_DETAILS: string;
  TRAINING_AVAIL_24: string;
  CUSTOMIZED_PROGRAM_FOR: string;
  EXERCISES: string;
  VIDEO_NOT_FOUND: string;
  YOUR_ARE_AT: string;
  ARE_YOU_IN_SEASON: string;
  THERE_IS_NO_EX_FOR_STATION: string;
  LAST_TRAINING_DAY_WARN: string;
}

export interface IAboutLocalization {
  CLIENT_VERSION: string;
  API_VERSION: string;
}

export interface IPreferencesLozalization {
  MEDICAL_COVERAGE: string;
  CARRIER: string;
  CARRIER_REQUIRED: string;
  INSURED_NAME: string;
  INSURED_NAME_REQUIRED: string;
  MEMBER_ID: string;
  MEMBER_ID_REQUIRED: string;
  GROUP_NUMBER: string;
  GROUP_NUMBER_REQUIRED: string;
  INSURED_DATE_OF_BIRTH: string;
  INSURED_DATE_OF_BIRTH_REQUIRED: string;
  INSURANCE_CARD: string;
  ADD_INSURANCE_CARD: string;
  REMOVE_INSURANCE_CARD: string;
}

export interface ICommonLocalization {
  EMAIL: string;
  YOUR_EMAIL: string;
  EMAIL_INVALID: string;
  EMAIL_REQUIRED: string;
  PHONE: string;
  YOUR_PHONE: string;
  PHONE_INVALID: string;
  PHONE_REQUIRED: string;
  UNIQUE_ID: string;
  UNIQUE_ID_INVALID: string;
  UNIQUE_ID_REQUIRED: string;
  PASSWORD: string;
  PASSWORD_REQUIRED: string;
  PASSWORD_MUST_MATCH: string;
  YOUR_PASSWORD: string;
  HERE: string;
  YOUR_LANGUAGE: string;
  PERSONAL_INFO: {
    NAME: string;
    NAME_REQUIRED: string;
    NAME_INVALID: string;
    YOUR_PERSONAL_INFO: string;
    YOUR_FIRST_NAME: string;
    FIRST_NAME_REQUIRED: string;
    FIRST_NAME_INVALID: string;
    YOUR_LAST_NAME: string;
    LAST_NAME_REQUIRED: string;
    LAST_NAME_INVALID: string;
    YOUR_DATE_OF_BIRTH: string;
    DATE_OF_BIRTH_REQUIRED: string;
    DATE_OF_BIRTH_INVALID: string;
    DATE_OF_BIRTH_MINIMAL: string;
    ZIP_CODE: string;
    ZIP_CODE_REQUIRED: string;
  };
  TIME: {
    WEEK: string;
    DAY: string;
    DATE: string;
    TODAY: string;
    TOMORROW: string;
    MON_TEXT: string;
    TUE_TEXT: string;
    WEN_TEXT: string;
    THU_TEXT: string;
    FRI_TEXT: string;
    SAT_TEXT: string;
    SAN_TEXT: string;
  };
  ACTIONS: {
    BACK: string;
    CONTINUE: string;
    GO_BACK_A_STEP: string;
    GO_BACK: string;
    GO_BACK_TO_LOGIN: string;
    GO_TO_SIGNIN: string;
    SAVE: string;
    CANCEL: string;
    ACKNOWLEDGE: string;
    SIGN_OUT: string;
    REMOVE: string;
    START: string;
    STOP: string;
    SKIP: string;
    EDIT: string;
    YES: string;
    NO: string;
  };
  STATUSES: {
    SUCCSESS: string;
    FAILURE: string;
  };
  DOMAIN_COMMON: {
    IN_STUDIO: string;
    OUT_OF_STUDIO: string;
    SCHEDULE: string;
    OVERVIEW: string;
    LOCATION: string;
    CLASS: string;
    TRAINER: string;
    STATE: string;
    STATION: string;
  };
}

export interface INavMenuLocalization {
  ROOT: string;
  DASHBOARD: string;
  TRAINING: string;
  TRAINING_SESSION: string;
  TRAINING_OVERVIEW: string;
  RESCHEDULE_IN_STUDIO_SESSION: string;
  TRAINING_DETAILS: string;
  GREAT_WORKOUT: string;
  RESCHEDULE_SESSION: string;
  RESCHEDULE_IN_STUDIO_TRAINING: string;
  SCHEDULE_IN_STUDIO_TRAINING: string;
  SCHEDULE_ASSESSMENT: string;
  TESTING: string;
  TESTING_OVERVIEW: string;
  TESTING_HISTORY: string;
  TESTING_ASSESSMENT_HISTORY: string;
  TEST_SUMMARY: string;
  NOT_AUTHORIZED: string;
  PREFERENCES: string;
  EDIT_PROFILE: string;
  CHANGE_PASSWORD: string;
  SWITCH_PROFILE: string;
  SLEEP_TRACKER: string;
  HYDRATION_TRACKER: string;
  SIGNED_IN_AS: string;
}

export interface ILoginLocalization {
  SIGN_IN: string;
  SIGNING_IN: string;
  PASSWORD: string;
  PASSWORD_REQUIRED: string;
  NEW_USER: string;
  REGISTER: string;
  FORGOT_PASSWORD: string;
  RETURN_URL_ERROR: string;
}

export interface IRegisterComponentLocalization {
  CREATE_AN_ACCOUNT: string;
  ENTER_YOUR_CONACT_INFO: string;
  FOR_ADULTS: string;
  AGE_WARNING: string;
  ENTER_UNIQUE_ID: string;
  UNABLE_TO_VERIFY_EMAIL_UNIQ_ID: string;
  CREATE_WITH_UNIQ_ID: string;
  RETURN_TO_SIGN_IN: string;
  CREATE_PROFILE: string;
  WAIVER_OF_LIABILITY: string;
  I_AGREE: string;
  DATE: string;
  DATE_REQUIRED: string;
  DATE_INVALID: string;
}

export interface IForgotPasswordLocalization {
  TO_RESET_PASSWORD: string;
  EMAIL_OR_PHONE: string;
  ENTER_VALID_EMAIL: string;
  REQUEST_NEW_PASSWORD: string;
  CHECK_YOUR_EMAIL_ADDRESS: string;
  CONFIRMATION_SENT_TO: string;
  CHECK_INBOX: string;
}

export interface IChangeProfileLocalization {
  CHANGE_PROFILE: string;
  WHICH_PROFILE: string;
  CONTINUE_TO_DASHBOARD: string;
  ADD_ANOTHER_PROFILE: string;
  DELETE_ACCOUNT: string;
}

export interface ICheckUniqIdLocalization {
  ADD_PROFILE: string;
  YOU_CAN_LINK_EXISTING: string;
  OR_CREATE_NEW: string;
  LINK_EXISTING: string;
}

export interface IResetPasswordLocalization {
  PLEASE_CHANGE_YOUR_PASSWORD: string;
  PASSWORD_MUST_INCLUDE: string;
  UPDATE_PASSWORD: string;
  CONFIRM_PASSWORD: string;
  YOU_UPDATED_PASSWORD: string;
  RESET_PASSWORD_REQUEST_INCORRECT: string;
}

export interface IChangePasswordLocalization {
  CHANGE_PASSWORD: string;
  CREATE_SECURE_PASSWORD: string;
  PASSWORD_MUST_INCLUDE: string;
  PASSWORD_MUST_MATCH: string;
  NEW_PASSWORD: string;
  NEW_PASSWORD_REQUIRED: string;
  CONFIRM_NEW_PASSWORD: string;
  CONFIRM_NEW_PASSWORD_REQUIRED: string;
  PASSWORD_POLICY_ERROR: string;
  PASSWORD_WAS_CHANGED: string;
}

export interface IRegisterCompleteComponentLocalization {
  REGISTRATION_FINISHED: string;
  YOU_REGISTERED: string;
}

export interface IPersonalInfoLocalization {
  ENTER_YOUR_PERSONAL_INFO: string;
  IF_YOU_CREATING_FOR_YOUTH: string;
  DATE_OF_BIRTH_TO_SMALL_NOFITICATION: string;
}

export interface IDashboardLocalization {
  UPCOMING_EVENTS: string;
  UPCOMING_EVENTS_LIST: string;
  NO_UPCOMING_EVENTS: string;
  TREATMENT: string;
  YOU_HAVE_PROGRAMM: string;
  TO_SEE_EXERCISES_TAP: string;
  CHANGE_SCHEDULE: string;
  TESTING: string;
  CONTACT: string;
  BOOK_NOW: string;
  CALL: string;
  CHAT: string;
  PHONE_NOT_CONFIGURED: string;
  OPT_MOBILE_ONLY: string;
}

export interface IRecommendationsLocalization {
  RECOMMENDATIONS: string;
  NO_RECOMMENDATIONS: string;
  BOOK_NOW: string;
  GET_MEDICAL_TREATMENT1: string;
  GET_MEDICAL_TREATMENT2: string;
  GET_MEDICAL_TREATMENT3: string;
}

export interface ITestingHistoryLocalization {
  TESTING_HISTORY: string;
  HERE_IS_YOUR_HISTORY: string;
}
export interface IEventsInfoLcalization {
  TOTAL_TESTS: string;
  LAST_TEST_DATE: string;
  SESSIONS: string;
  UNTIL_NEXT_TEST: string;
}

export function translateLinks(
  links: LinksMenuModel[],
  translateService: TranslateService,
): void {
  try {
    const week = translateService.instant('COMMON.TIME.WEEK');
    const day = translateService.instant('COMMON.TIME.DAY');
    const monday = translateService.instant('COMMON.TIME.MON_TEXT');
    const tuesday = translateService.instant('COMMON.TIME.TUE_TEXT');
    const wednesday = translateService.instant('COMMON.TIME.WEN_TEXT');
    const thursday = translateService.instant('COMMON.TIME.THU_TEXT');
    const friday = translateService.instant('COMMON.TIME.FRI_TEXT');
    const saturday = translateService.instant('COMMON.TIME.SAT_TEXT');
    const sunday = translateService.instant('COMMON.TIME.SAN_TEXT');

    links.forEach((link) => {
      link.title = link.title.replace('Week', week);
      link.title = link.title.replace('Day', day);
      link.title = link.title.replace('Monday', monday);
      link.title = link.title.replace('Tuesday', tuesday);
      link.title = link.title.replace('Wednesday', wednesday);
      link.title = link.title.replace('Thursday', thursday);
      link.title = link.title.replace('Friday', friday);
      link.title = link.title.replace('Saturday', saturday);
      link.title = link.title.replace('Sunday', sunday);
    });
  } catch {
    console.log('dynamic localization of weeks/days failed');
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  SleepRecordDto,
  SleepRecordsListQueryResponse,
  SleepTrackerClient,
} from '@common/services/co-api-client';
import {
  AxisLabelContentArgs,
  CategoryAxisLabels,
} from '@progress/kendo-angular-charts';
import {
  CalendarComponent,
  CalendarView,
} from '@progress/kendo-angular-dateinputs';

import moment from 'moment';
import { Subject } from 'rxjs';
import _ from 'lodash';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { isEqual, startOfDay } from 'date-fns';

@Component({
  selector: 'spl-sleep-tracker',
  templateUrl: './sleep-tracker.component.html',
  styleUrls: ['./sleep-tracker.component.scss'],
})
export class SleepTrackerComponent implements OnInit {
  public loadingPromise: Promise<SleepRecordsListQueryResponse | void>;
  public isLoading: boolean;

  public monthSleepRecords: SleepRecordDto[] = [];
  public startOfMonth: Date;
  public endOfMonth: Date;
  public totalSleepTime: number;
  public totalSleepTimeString: string;
  public latestSleepRecord: SleepRecordDto;
  public calendarMaxDate: Date;

  public monthChartData: ChartDay[] = [];
  public monthAverageTime: number;
  public chartMin: Date;
  public chartMax: Date;

  public formGroup: FormGroup;
  private sleepStartTime: FormControl;
  private sleepEndTime: FormControl;
  private sleepRate: FormControl;

  public minSleepEnd: Date;
  public maxSleepEnd: Date;
  public maxSleepStart: Date;

  public selectedDay: SleepRecordDto;
  public selectedDate: Date;
  public isNewDay: boolean;
  public prevDay: SleepRecordDto;

  @ViewChild('calendar')
  public calendar: CalendarComponent;

  private _unsubscribeAll: Subject<any>;

  private skipNextValueChange: boolean;

  private originalValue: string;
  private currentValue: string;

  constructor(
    private sleepTrackerClient: SleepTrackerClient,
    private formBuilder: FormBuilder,
  ) {
    this._unsubscribeAll = new Subject();
    this.sleepStartTime = new FormControl(null, Validators.required);
    this.sleepEndTime = new FormControl(null, Validators.required);
    this.sleepRate = new FormControl(null, Validators.required);
  }

  public ngOnInit(): void {
    this.startOfMonth = moment().startOf('month').startOf('day').toDate();
    this.endOfMonth = moment().endOf('month').endOf('day').toDate();
    this.calendarMaxDate = new Date(this.endOfMonth);
    this.loadingPromise = this.loadInitialData();
    this.isLoading = true;
    this.formGroup = this.formBuilder.group({
      sleepStartTime: this.sleepStartTime,
      sleepEndTime: this.sleepEndTime,
      sleepRate: this.sleepRate,
    });
  }

  public sleepStartChange(date: Date): void {
    let newDateTime = moment(date);
    const recordDate = moment(this.selectedDay.recordDate);
    if (newDateTime.hours() >= 12) {
      if (recordDate.date() == newDateTime.date()) {
        newDateTime = newDateTime.add(-1, 'day');
      }
    } else {
      if (recordDate.date() > newDateTime.date()) {
        newDateTime = newDateTime.add(1, 'day');
      }
    }
    this.selectedDay.sleepStartTime = newDateTime.toDate();
    this.sleepStartTime.setValue(this.selectedDay.sleepStartTime);

    if (recordDate.date() != newDateTime.date()) {
      this.minSleepEnd = null;
      this.maxSleepEnd = moment(this.selectedDay.sleepStartTime)
        .add(1, 'day')
        .toDate();
    } else {
      this.minSleepEnd = this.selectedDay.sleepStartTime;
      this.maxSleepEnd = null;
    }

    this.totalSleepTime = this.getTotalSleepTime(this.selectedDay);
    this.totalSleepTimeString = this.formatDurationMsToTextWithZeroCheck(
      this.totalSleepTime,
    );

    this.currentValue = JSON.stringify(this.formGroup.value);
  }

  public sleepEndChange(date: Date): void {
    const newDateTime = moment(date);

    this.selectedDay.sleepEndTime = newDateTime.toDate();
    this.sleepEndTime.setValue(this.selectedDay.sleepEndTime);

    this.totalSleepTime = this.getTotalSleepTime(this.selectedDay);
    this.totalSleepTimeString = this.formatDurationMsToTextWithZeroCheck(
      this.totalSleepTime,
    );
    this.currentValue = JSON.stringify(this.formGroup.value);
  }

  public sleepRateChange(value: number): void {
    this.selectedDay.sleepRate = value;
    this.currentValue = JSON.stringify(this.formGroup.value);
  }

  private async loadInitialData(): Promise<void> {
    this.startOfMonth = moment().startOf('month').startOf('day').toDate();

    this.endOfMonth = moment().endOf('month').endOf('day').toDate();

    const last30Days = moment().add(-30, 'day');
    let extendedStart = moment(this.startOfMonth).add(-1, 'week');
    const extendedEnd = moment(this.endOfMonth).add(1, 'week').toDate();

    extendedStart = extendedStart.isBefore(last30Days)
      ? extendedStart
      : last30Days;
    await this.loadDataForRange(extendedStart.toDate(), extendedEnd);

    this.updateChartData();
  }

  private async loadSleepDataForMonth(dateInMonth: Date): Promise<void> {
    this.startOfMonth = moment(dateInMonth)
      .startOf('month')
      .startOf('day')
      .toDate();

    this.endOfMonth = moment(dateInMonth).endOf('month').endOf('day').toDate();

    const extendedStart = moment(this.startOfMonth).add(-1, 'week').toDate();
    const extendedEnd = moment(this.endOfMonth).add(1, 'week').toDate();

    await this.loadDataForRange(extendedStart, extendedEnd);
  }

  private async loadDataForRange(start: Date, end: Date): Promise<void> {
    this.isLoading = true;

    const response = await this.sleepTrackerClient
      .getSleepRecords(start, end, null)
      .toPromise();

    this.monthSleepRecords = response.sleepRecordDtos.sort(this.sortRecords);

    this.isLoading = false;
  }

  private updateChartData(): void {
    this.monthChartData = this.generateChartData(this.monthSleepRecords);
    this.monthAverageTime = this.getAverageSleepTime(this.monthChartData);
    this.monthSleepRecords = this.monthSleepRecords.sort(this.sortRecords);
    this.latestSleepRecord = _.last(this.monthSleepRecords);
    const now = moment();
    this.chartMax = moment(this.endOfMonth).isAfter(now)
      ? now.toDate()
      : this.endOfMonth;
    this.chartMin = moment(this.chartMax).add(-7, 'day').toDate();
  }

  private sortRecords = (a, b): number => {
    const momentA = moment(a.recordDate);
    const momentB = moment(b.recordDate);
    if (momentA.isSame(momentB)) return 0;
    if (momentA.isBefore(momentB)) {
      return -1;
    } else {
      return 1;
    }
  };

  private isDateInCurrentMonth(date: Date): boolean {
    const now = moment().startOf('day');
    const startOfCurrMonth = moment(now).startOf('month').startOf('day');
    const endOfCurrMonth = moment(now).endOf('month').endOf('day');
    return (
      startOfCurrMonth.isSameOrBefore(date) &&
      endOfCurrMonth.isSameOrAfter(date)
    );
  }

  private isDateISelectedMonth(date: Date): boolean {
    const startOfCurrMonth = moment(this.startOfMonth);
    const endOfCurrMonth = moment(this.endOfMonth);
    return (
      startOfCurrMonth.isSameOrBefore(date) &&
      endOfCurrMonth.isSameOrAfter(date)
    );
  }

  private getAverageSleepTime(monthChartData: ChartDay[]): number {
    let averageTime = 0;
    let daysWithData = 0;
    monthChartData.forEach((r) => {
      if (r.duration > 0) {
        daysWithData++;
        averageTime += r.duration;
      }
    });
    return averageTime / daysWithData;
  }

  private generateChartData(records: SleepRecordDto[]): ChartDay[] {
    let currentDate = moment().startOf('day').add(-29, 'day').toDate();
    const days: ChartDay[] = [];
    for (let i = 0; i < 30; i++) {
      const record = records.find((r) =>
        moment(r.recordDate).isSame(currentDate),
      );
      const day = new ChartDay();
      day.date = currentDate;
      if (record) {
        let diff = moment(record.sleepEndTime).diff(record.sleepStartTime);
        if (diff < 0) diff = 0;
        day.duration = diff;
      } else {
        day.duration = 0;
      }
      days.push(day);
      currentDate = moment(currentDate).add(1, 'day').toDate();
    }

    return days;
  }

  public async onActiveViewChange(view: CalendarView): Promise<void> {
    if (view == 'month') {
      console.log(view, this.calendar.multiViewCalendar.focusedDate);
      const focusedDate = this.calendar.multiViewCalendar.focusedDate;
      if (moment(focusedDate).isBefore(moment())) {
        await this.loadSleepDataForMonth(focusedDate);
        this.cancelEdit();
      }
    }
  }

  public async onNavigate(data: {
    activeView: CalendarView;
    focusedDate: Date;
  }): Promise<void> {
    console.log(data.activeView, data.focusedDate);
    if (data.activeView == 'month') {
      const focusedDay = moment(data.focusedDate).startOf('day').unix();
      const today = moment().startOf('day').unix();
      if (focusedDay == today) {
        this.cancelEdit();
        this.skipNextValueChange = true;
        await this.loadSleepDataForMonth(data.focusedDate);
        this.onSelectedDayChange(data.focusedDate);
      } else {
        this.cancelEdit();
        await this.loadSleepDataForMonth(data.focusedDate);
      }
    }
  }

  public onSelectedDayChange(value: Date): void {
    if (this.skipNextValueChange) {
      this.skipNextValueChange = false;
      return;
    }

    const selectedDay = this.monthSleepRecords.find((r) =>
      moment(r.recordDate).isSame(value),
    );

    const dayForEditor = new SleepRecordDto();

    if (!selectedDay) {
      const dateWithoutTime = value.toDateString();
      dayForEditor.recordDate = dateWithoutTime;
      dayForEditor.sleepStartTime = moment(value)
        //.add(-1, 'day')
        .startOf('day')
        .toDate();
      dayForEditor.sleepEndTime = value;
      dayForEditor.sleepRate = 0;
      this.isNewDay = true;
    } else {
      dayForEditor.id = selectedDay.id;
      dayForEditor.personalRecordId = selectedDay.personalRecordId;
      dayForEditor.recordDate = selectedDay.recordDate;
      dayForEditor.sleepStartTime = selectedDay.sleepStartTime;
      dayForEditor.sleepEndTime = selectedDay.sleepEndTime;
      dayForEditor.sleepRate = selectedDay.sleepRate;
      this.isNewDay = false;
    }

    this.selectedDay = dayForEditor;
    this.selectedDate = this.recordDateStringToDate(
      this.selectedDay.recordDate,
    );

    this.sleepStartTime.setValue(this.selectedDay.sleepStartTime);
    this.sleepEndTime.setValue(this.selectedDay.sleepEndTime);
    this.sleepRate.setValue(this.selectedDay.sleepRate);
    this.formGroup.updateValueAndValidity();
    this.originalValue = this.currentValue = JSON.stringify(
      this.formGroup.value,
    );

    this.totalSleepTime = this.getTotalSleepTime(selectedDay);
    this.totalSleepTimeString = this.formatDurationMsToTextWithZeroCheck(
      this.totalSleepTime,
    );

    this.prevDay = null;
    const reverted = this.monthSleepRecords.slice(0).reverse();
    for (let i = reverted.length - 1; i >= 0; i--) {
      if (moment(reverted[i].recordDate).isBefore(value)) {
        this.prevDay = reverted[i];
      }
    }
  }

  public cancelEdit(): void {
    this.selectedDay = null;
    this.selectedDate = null;
    this.isLoading = false;
  }

  public submitValues(): void {
    this.isLoading = true;
    if (!this.selectedDay.id) {
      this.sleepTrackerClient
        .createSleepRecord(this.selectedDay)
        .toPromise()
        .then((response) => {
          if (response) {
            this.monthSleepRecords.push(response);
          }
          if (
            this.isDateInCurrentMonth(
              moment(this.selectedDay.recordDate).toDate(),
            )
          ) {
            this.updateChartData();
          }
          this.selectedDay = null;
          this.selectedDate = null;
          this.isLoading = false;
        });
    } else {
      this.sleepTrackerClient
        .updateSleepRecord(this.selectedDay)
        .toPromise()
        .then((response) => {
          if (response) {
            const updatedRecordIndex = this.monthSleepRecords.findIndex(
              (r) => r.id === this.selectedDay.id,
            );
            if (updatedRecordIndex != -1) {
              this.monthSleepRecords.splice(updatedRecordIndex, 1);
              this.monthSleepRecords.push(response);
            }
          }
          if (
            this.isDateInCurrentMonth(
              moment(this.selectedDay.recordDate).toDate(),
            )
          ) {
            this.updateChartData();
          }
          this.selectedDay = null;
          this.selectedDate = null;
          this.isLoading = false;
        });
    }
  }

  private recordDateStringToDate(str: string): Date {
    const parsed = moment(str);
    const date = parsed.toDate();
    return date;
  }

  public getTotalSleepTime(day: SleepRecordDto): number {
    let diff = 0;
    if (day) {
      diff = moment(day.sleepEndTime).diff(day.sleepStartTime);
    }
    return diff;
  }

  public formatDurationMsToTextWithZeroCheck(diff: number): string {
    let durationString = '--';
    if (diff > 0) {
      durationString = this.formatDurationMsToText(diff);
    }
    return durationString;
  }

  public formatDurationMsToText(diff: number): string {
    let durationString = '--';
    const hours = Math.floor(moment.duration(diff).asHours());
    const minutes = Math.floor(moment.duration(diff).asMinutes() - hours * 60);
    durationString = `${hours}hr ${minutes.toString().padStart(2, '0')}min`;
    return durationString;
  }

  public disabledDates = (date: Date): boolean => {
    const now = moment();
    if (moment(date).isAfter(now)) {
      return true;
    }

    if (moment(date).date() == now.date()) {
      return false;
    }
    const inCurrMonth = this.isDateInCurrentMonth(date);
    const inSelMonth = this.isDateISelectedMonth(date);
    const earlier = moment(date).isBefore(this.startOfMonth);

    let later = false;
    if (inCurrMonth && inSelMonth) {
      later = moment(date).isAfter(now);
    } else if (inCurrMonth && !inSelMonth) {
      later = moment(date).isAfter(this.endOfMonth);
    } else if (!inCurrMonth) {
      later = moment(date).isAfter(this.endOfMonth);
    }

    return earlier || later;
  };

  public resolveDayCellStyles(date: Date): any {
    const classesList = {
      dayCell: true,
      disabledCell: false,
      activeCellWithSleepRecord: false,
      emptyActiveCell: false,
      currentDate: false,
    };
    const now = moment().toDate();
    const inPastMonth = moment(date).isBefore(this.startOfMonth);
    const inFutureMonth = moment(date).isAfter(this.endOfMonth);

    const beforeNow = moment(date).isBefore(now);
    const hasSleepRecord = this.hasSleepRecord(date);

    if (inPastMonth || inFutureMonth) {
      classesList.disabledCell = true;
    }

    if (beforeNow && !hasSleepRecord) {
      classesList.activeCellWithSleepRecord = false;
      classesList.emptyActiveCell = true;
    } else if (beforeNow && hasSleepRecord) {
      classesList.activeCellWithSleepRecord = true;
      classesList.emptyActiveCell = false;
    }

    if (isEqual(startOfDay(now), startOfDay(date))) {
      classesList.currentDate = true;
    }

    return classesList;
  }

  public valueLabel = (e: AxisLabelContentArgs): string => {
    console.log(e);
    return '';
  };

  public getChartItemColor = (p: { value: number }): string => {
    if (p.value > this.monthAverageTime) {
      return '#1d60c4';
    }
    return '#ff0000';
  };

  public categoryLabel = (e: AxisLabelContentArgs): string => {
    return moment(e.value).date().toString();
  };

  public categoryAxisLabels: CategoryAxisLabels = {
    font: 'bold 9px Arial, sans-serif',
    content: this.categoryLabel,
  };

  private hasSleepRecord(date: Date): boolean {
    return (
      this.monthSleepRecords.find((d) =>
        moment(d.recordDate).startOf('day').isSame(moment(date).startOf('day')),
      ) != null
    );
  }
}

export class ChartDay {
  public date: Date;
  public duration: number;
}

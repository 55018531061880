import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'assessment-header-skeleton',
  templateUrl: './assessment-header-skeleton.component.html',
  styleUrls: ['./assessment-header-skeleton.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssessmentHeaderSkeletonComponent {
  @Input() skeletonHeight: number;
}
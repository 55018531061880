import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'default-skeleton',
  templateUrl: './default-skeleton.component.html',
  styleUrls: ['./default-skeleton.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultSkeletonComponent implements OnChanges {
  @Input() skeletonHeight: number;
  
  private readonly firstSkeletonElemHeight = 40;
  private readonly skeletonElemHeight = 30;
  public lines: number[] = [];
  
  ngOnChanges(): void {
    const linesCount = Math.floor((this.skeletonHeight - this.firstSkeletonElemHeight) / this.skeletonElemHeight);
    this.lines = [...Array(linesCount).keys()]
  }
}
import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { StatisticsActivities } from '@common/models/statistic-activities';
import { StatisticsService } from '@common/services/statistics.service';

@Component({
  selector: 'spl-bitmovin-player-dialog',
  templateUrl: './bitmovin-player-dialog.component.html',
  styleUrls: ['./bitmovin-player-dialog.component.scss'],
})
export class BitmovinPlayerDialogComponent {
  public video: string;
  public poster: string;
  public muted: boolean = false;
  public autoClose: boolean = false;
  public relatedObjectId: string;

  constructor(
    public dialogRef: MatDialogRef<BitmovinPlayerDialogComponent>,
    private statisticsService: StatisticsService,
  ) {}

  trackVideoView(): any {
    const props: any = {};
    props.video = this.video;
    if (this.relatedObjectId) {
      props.relatedObjectId = this.relatedObjectId;
    }
    this.statisticsService.logEvent(
      StatisticsActivities.ACTION_VIDEO_START,
      props,
    );
  }

  videoEnded(): void {
    if (!this.autoClose) return;
    this.closeVideo();
  }

  closeVideo(): void {
    this.dialogRef.close();
  }
}

<spl-skeleton class="testing-assessments-wrapper" [showSkeleton]="isLoadingPromise">

  <span class="title">
    {{ 'TESTING_HISTORY.TESTING_HISTORY' | translate }}:
  </span>

  <span class="spl-info-text mt-8">
    {{ 'TESTING_HISTORY.HERE_IS_YOUR_HISTORY' | translate }}:
  </span>

  <div class="testing-history-wrapper">
    <links-menu [links]="historyList"></links-menu>
  </div>

  <buttons-list-menu
    *ngIf="isLoadMoreListShown"
    [list]="moreTestsList"
    (optionSelected)="nextPage()">
  </buttons-list-menu>

  <div fxLayout="row" fxLayoutAlign="center center" class="spinner-wrapper" *ngIf="processing">
    <mat-spinner
      diameter="30"
    ></mat-spinner>
  </div>

</spl-skeleton>

import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'info-block-skeleton',
  templateUrl: './info-block-skeleton.component.html',
  styleUrls: ['./info-block-skeleton.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoBlockSkeletonComponent {
  @Input() skeletonHeight: number;
}
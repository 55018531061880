export class PanelInfoModel {
  title: string;
  blocks: InfoBlock[];

  /**
   * Constructor
   *
   * @param data
   */
  constructor(data: PanelInfoModel) {
    this.title = data.title || '';
    this.blocks = data.blocks || [];
  }
}

export class InfoBlock {
  title: string;
  info: string;
  unitOfMeasure?: string;
  delta?: boolean | null;

  /**
   * Constructor
   *
   * @param data
   */
  constructor(data: InfoBlock) {
    this.title = data.title || '';
    this.info = data.info || '';
    this.unitOfMeasure = data.unitOfMeasure || '';
    this.delta = data.delta;
  }
}

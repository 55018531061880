import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as _ from 'lodash';

import { FuseNavigation, FuseNavigationItem } from '@fuse/types';
import {
  APPLICATIONS_PATHS,
  IApplicationPathsType,
  ReturnUrlType,
} from '@common/co/core/app.constants';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SplNavigationService {
  onItemCollapsed: Subject<FuseNavigationItem>;
  onItemCollapseToggled: Subject<any>;

  // Private
  private _onNavigationChanged: BehaviorSubject<any>;
  private _onNavigationRegistered: BehaviorSubject<any>;
  private _onNavigationUnregistered: BehaviorSubject<any>;
  private _onNavigationItemAdded: BehaviorSubject<any>;
  private _onNavigationItemUpdated: BehaviorSubject<any>;
  private _onNavigationItemRemoved: BehaviorSubject<any>;

  private _currentNavigationKey: string;
  private _registry: { [key: string]: any } = {};

  private _previousUrl: string;
  private _currentUrl: string;
  private _routeHistory: { url: string; urlSegmentCount: number }[] = [];
  private _currentRoute: ActivatedRoute;
  private backButtonLink: string;
  private pathIndexForDisplayBackButton: number = 2;

  /**
   * Constructor
   */
  constructor(
    @Inject(APPLICATIONS_PATHS) private applicationPaths: IApplicationPathsType,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    // Set the defaults
    this.onItemCollapsed = new Subject();
    this.onItemCollapseToggled = new Subject();

    // Set the private defaults
    this._currentNavigationKey = null;
    this._onNavigationChanged = new BehaviorSubject(null);
    this._onNavigationRegistered = new BehaviorSubject(null);
    this._onNavigationUnregistered = new BehaviorSubject(null);
    this._onNavigationItemAdded = new BehaviorSubject(null);
    this._onNavigationItemUpdated = new BehaviorSubject(null);
    this._onNavigationItemRemoved = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get onNavigationChanged
   *
   * @returns {Observable<any>}
   */
  get onNavigationChanged(): Observable<any> {
    return this._onNavigationChanged.asObservable();
  }

  /**
   * Get onNavigationRegistered
   *
   * @returns {Observable<any>}
   */
  get onNavigationRegistered(): Observable<any> {
    return this._onNavigationRegistered.asObservable();
  }

  /**
   * Get onNavigationUnregistered
   *
   * @returns {Observable<any>}
   */
  get onNavigationUnregistered(): Observable<any> {
    return this._onNavigationUnregistered.asObservable();
  }

  /**
   * Get onNavigationItemAdded
   *
   * @returns {Observable<any>}
   */
  get onNavigationItemAdded(): Observable<any> {
    return this._onNavigationItemAdded.asObservable();
  }

  /**
   * Get onNavigationItemUpdated
   *
   * @returns {Observable<any>}
   */
  get onNavigationItemUpdated(): Observable<any> {
    return this._onNavigationItemUpdated.asObservable();
  }

  /**
   * Get onNavigationItemRemoved
   *
   * @returns {Observable<any>}
   */
  get onNavigationItemRemoved(): Observable<any> {
    return this._onNavigationItemRemoved.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register the given navigation
   * with the given key
   *
   * @param key
   * @param navigation
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  register(key: string, navigation: FuseNavigation[]): void {
    // Check if the key already being used
    if (this._registry[key]) {
      console.error(
        `The navigation with the key '${key}' already exists. Either unregister it first or use a unique key.`,
      );

      return;
    }

    // Add to the registry
    this._registry[key] = navigation;

    // Notify the subject
    this._onNavigationRegistered.next([key, navigation]);
  }

  /**
   * Unregister the navigation from the registry
   * @param key
   */
  unregister(key: string): void {
    // Check if the navigation exists
    if (!this._registry[key]) {
      console.warn(
        `The navigation with the key '${key}' doesn't exist in the registry.`,
      );
    }

    // Unregister the sidebar
    delete this._registry[key];

    // Notify the subject
    this._onNavigationUnregistered.next(key);
  }

  /**
   * Get navigation from registry by key
   *
   * @param key
   * @returns {any}
   */
  getNavigation(key: string): any {
    // Check if the navigation exists
    if (!this._registry[key]) {
      console.warn(
        `The navigation with the key '${key}' doesn't exist in the registry.`,
      );

      return;
    }

    // Return the sidebar
    return this._registry[key];
  }

  /**
   * Get flattened navigation array
   *
   * @param navigation
   * @param flatNavigation
   * @returns {any[]}
   */
  getFlatNavigation(
    navigation: FuseNavigation[],
    flatNavigation: FuseNavigationItem[] = [],
  ): any {
    for (const item of navigation) {
      if (item.type === 'item') {
        flatNavigation.push(item);

        continue;
      }

      if (item.type === 'collapsable' || item.type === 'group') {
        if (item.children) {
          this.getFlatNavigation(item.children, flatNavigation);
        }
      }
    }

    return flatNavigation;
  }

  /**
   * Get the current navigation
   *
   * @returns {any}
   */
  getCurrentNavigation(): any {
    if (!this._currentNavigationKey) {
      console.warn(`The current navigation is not set.`);

      return;
    }

    return this.getNavigation(this._currentNavigationKey);
  }

  /**
   * Set the navigation with the key
   * as the current navigation
   *
   * @param key
   */
  setCurrentNavigation(key: string): void {
    // Check if the sidebar exists
    if (!this._registry[key]) {
      console.warn(
        `The navigation with the key '${key}' doesn't exist in the registry.`,
      );

      return;
    }

    // Set the current navigation key
    this._currentNavigationKey = key;

    // Notify the subject
    this._onNavigationChanged.next(key);
  }

  /**
   * Get navigation item by id from the
   * current navigation
   *
   * @param id
   * @param {any} navigation
   * @returns {any | boolean}
   */
  getNavigationItem(id: string, navigation = null): any | boolean {
    if (!navigation) {
      navigation = this.getCurrentNavigation();
    }

    console.log(navigation);
    for (const item of navigation) {
      if (item.id === id) {
        return item;
      }

      if (item.children) {
        const childItem = this.getNavigationItem(id, item.children);

        if (childItem) {
          return childItem;
        }
      }
    }

    return false;
  }

  /**
   * Get the parent of the navigation item
   * with the id
   *
   * @param id
   * @param {any} navigation
   * @param parent
   */
  getNavigationItemParent(id: string, navigation = null, parent = null): any {
    if (!navigation) {
      navigation = this.getCurrentNavigation();
      parent = navigation;
    }

    for (const item of navigation) {
      if (item.id === id) {
        return parent;
      }

      if (item.children) {
        const childItem = this.getNavigationItemParent(id, item.children, item);

        if (childItem) {
          return childItem;
        }
      }
    }

    return false;
  }

  /**
   * Add a navigation item to the specified location
   *
   * @param item
   * @param id
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addNavigationItem(item: any, id: string): void {
    // Get the current navigation
    const navigation: any[] = this.getCurrentNavigation();

    // Add to the end of the navigation
    if (id === 'end') {
      navigation.push(item);

      // Trigger the observable
      this._onNavigationItemAdded.next(true);

      return;
    }

    // Add to the start of the navigation
    if (id === 'start') {
      navigation.unshift(item);

      // Trigger the observable
      this._onNavigationItemAdded.next(true);

      return;
    }

    // Add it to a specific location
    const parent: any = this.getNavigationItem(id);

    if (parent) {
      // Check if parent has a children entry,
      // and add it if it doesn't
      if (!parent.children) {
        parent.children = [];
      }

      // Add the item
      parent.children.push(item);
    }

    // Trigger the observable
    this._onNavigationItemAdded.next(true);
  }

  /**
   * Update navigation item with the given id
   *
   * @param id
   * @param properties
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateNavigationItem(id: string, properties: any): void {
    // Get the navigation item
    const navigationItem = this.getNavigationItem(id);

    // If there is no navigation with the give id, return
    if (!navigationItem) {
      return;
    }

    // Merge the navigation properties
    _.merge(navigationItem, properties);

    // Trigger the observable
    this._onNavigationItemUpdated.next(true);
  }

  /**
   * Remove navigation item with the given id
   *
   * @param id
   */
  removeNavigationItem(id: string): void {
    const item = this.getNavigationItem(id);

    // Return, if there is not such an item
    if (!item) {
      return;
    }

    // Get the parent of the item
    let parent = this.getNavigationItemParent(id);

    // This check is required because of the first level
    // of the navigation, since the first level is not
    // inside the 'children' array
    parent = parent.children || parent;

    // Remove the item
    parent.splice(parent.indexOf(item), 1);

    // Trigger the observable
    this._onNavigationItemRemoved.next(true);
  }

  getReturnUrl(
    activatedRoute: ActivatedRoute,
    state?: INavigationState,
  ): string {
    const fromQuery = (activatedRoute.snapshot.queryParams as INavigationState)
      .returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (
      fromQuery &&
      !(
        fromQuery.startsWith(`${window.location.origin}/`) ||
        // eslint-disable-next-line no-useless-escape
        /\/[^\/].*/.test(fromQuery)
      )
    ) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      this.applicationPaths.DefaultLoginRedirectPath
    );
  }

  setURLs(event: NavigationEnd, currentRoute: ActivatedRoute): void {
    this._currentRoute = currentRoute;
    if (
      this._routeHistory.length === 0 ||
      event.urlAfterRedirects !==
        this._routeHistory[this._routeHistory.length - 1]?.url
    ) {
      const tempUrl = this._currentUrl;
      this._previousUrl = tempUrl;
      this._currentUrl = event.urlAfterRedirects;
      if (this._routeHistory.length > 10) {
        this._routeHistory.shift();
      }
      this._routeHistory.push({
        url: event.urlAfterRedirects,
        urlSegmentCount: event.urlAfterRedirects?.split('/')?.length - 1,
      });
    }
    this.setDisplayBackButton(currentRoute);
  }

  private setDisplayBackButton(currentRoute: ActivatedRoute): void {
    if (
      currentRoute.snapshot.data.displayBackButton !== undefined &&
      currentRoute.snapshot.data.displayBackButton === false
    ) {
      this.backButtonLink = undefined;
      return;
    }
    // set route link to previous page by route history
    if (
      this._routeHistory.length > 1 &&
      this._routeHistory[this._routeHistory.length - 1].urlSegmentCount >=
        this.pathIndexForDisplayBackButton
    ) {
      this.backButtonLink =
        this._routeHistory[this._routeHistory.length - 2].url;
      return;
    }
    // set route link to parent component or dashboard if route is first
    if (
      this._routeHistory.length === 1 &&
      this._routeHistory[0].urlSegmentCount >=
        this.pathIndexForDisplayBackButton
    ) {
      if (currentRoute.snapshot.parent.routeConfig.path !== ':id') {
        this.backButtonLink = `/${currentRoute.snapshot.parent.routeConfig.path}`;
        return;
      } else {
        this.backButtonLink = `/${currentRoute.snapshot.parent.parent.routeConfig.path}`;
        return;
      }
    }
    this.backButtonLink = undefined;
  }

  public get availableBackButton(): boolean {
    return this.backButtonLink !== undefined && this.backButtonLink !== null;
  }

  private removeLastRoute(): void {
    this._routeHistory.pop();
  }

  async goBack(): Promise<void> {
    this.removeLastRoute();
    await this.router.navigateByUrl(this.backButtonLink);
  }

  public setQueryParams(params: { [key: string]: any }): void {
    const queryParams: Params = params;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
}

interface INavigationState {
  [ReturnUrlType]: string;
}

import {
  Component, EventEmitter,
  Input, Output,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonsListMenuModel } from '@common/co/shared/models/buttons-list-menu.model';

@Component({
  selector: 'buttons-list-menu',
  templateUrl: './buttons-list-menu.component.html',
  styleUrls: ['./buttons-list-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonsListMenuComponent {
  @Input() list: ButtonsListMenuModel[];
  @Input() title: string;
  @Output() optionSelected: EventEmitter<{value: ButtonsListMenuModel, index: number}> = new EventEmitter();

  selectOption(option: ButtonsListMenuModel, index: number): void {
    this.optionSelected.emit({value: option, index});
  }
}

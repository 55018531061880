<!--<ng-container *ngIf="variant === 'horizontal-style-1'">-->
<!--  <navbar-horizontal-style-1></navbar-horizontal-style-1>-->
<!--</ng-container>-->

<!--<ng-container *ngIf="variant === 'vertical-style-1'">-->
<!--  <navbar-vertical-style-1></navbar-vertical-style-1>-->
<!--</ng-container>-->

<!--<ng-container *ngIf="variant === 'vertical-style-2'">-->
<!--  <navbar-vertical-style-2></navbar-vertical-style-2>-->
<!--</ng-container>-->

<div class="navbar-top">
  <div class="buttons" fxFlexFill fxLayoutAlign="end center">
    <button
      mat-icon-button
      class="toggle-sidebar-opened"
      (click)="toggleSidebarOpened()"
    >
      <mat-icon class="spl-red-color">close</mat-icon>
    </button>
  </div>
</div>

<div
  class="navbar-scroll-container"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
  <div class="navbar-content">
    <spl-navigation class="material2" layout="vertical"></spl-navigation>
  </div>
</div>

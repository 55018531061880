import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthClient,
  CheckUserByEmailCommand,
  CheckEmailWhiteListCommand,
  CheckUserByPhoneCommand,
  RegisterCommand,
  UserRegistrationFlow,
} from '@common/services/co-api-client';
import { validateAllFormFields } from '@common/co/core/helpers/validate-all-form-fields';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'register-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultComponent implements OnInit {
  @Input() registerFields: RegisterCommand;
  @Input() userSocialLogin: boolean;
  @Output() nextStep: EventEmitter<RegisterCommand> = new EventEmitter();

  registerForm: FormGroup;

  get completed(): boolean {
    return (
      (this.registerForm.get('email').valid || this.userSocialLogin) &&
      this.registerForm.get('phone').valid
    );
  }

  private readonly USAPhoneNumberCode: string = '+1';
  private get phone(): string {
    return this.USAPhoneNumberCode + this.registerForm.get('phone').value;
  }
  private get email(): string {
    return this.registerForm.get('email').value;
  }

  private translations: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _authClient: AuthClient,
    private appBusService: AppBusService,
    private _translationService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.registerForm = this._formBuilder.group({
      email: [
        this.registerFields.email,
        [Validators.required, Validators.email],
      ],
      phone: [
        this.registerFields.phone
          ? this.registerFields.phone.replace(this.USAPhoneNumberCode, '')
          : '',
        [Validators.required],
      ],
    });
    if (this.userSocialLogin) {
      this.registerForm.controls['email'].disable();
    }

    this._translationService
      .get(['COMMON.EMAIL_INVALID', 'COMMON.PHONE_INVALID'])
      .subscribe((translations) => {
        this.translations = translations;
      });
  }

  async next(): Promise<void> {
    if (this.registerForm.valid) {
      if (
        this.registerFields.userRegistrationFlow !==
        UserRegistrationFlow.ByUniqueId
      ) {
        this.nextByEmail();
      } else {
        this.nextByUniqueId();
      }
    } else {
      validateAllFormFields(this.registerForm);
    }
  }

  private nextByEmail(): void {
    forkJoin({
      checkEmailWhiteList: this._authClient.checkEmailWhiteList(
        new CheckEmailWhiteListCommand({
          email: this.email,
        }),
      ),
      checkEmail: this._authClient.checkUserByEmail(
        new CheckUserByEmailCommand({
          email: this.email,
        }),
      ),
      checkPhone: this._authClient.checkUserByPhone(
        new CheckUserByPhoneCommand({
          phone: this.phone,
        }),
      ),
    })
      .toPromise()
      .then(({ checkEmailWhiteList, checkEmail, checkPhone }) => {
        if (
          checkEmailWhiteList.success &&
          checkEmail.success &&
          checkPhone.success
        ) {
          this.nextStep.emit(
            new RegisterCommand({
              email: this.email,
              phone: this.phone,
              userRegistrationFlow: UserRegistrationFlow.ByEmail,
            }),
          );
          return;
        }

        if (!checkEmail.success) {
          this.processInputError(
            'email',
            this.translations['COMMON.EMAIL_INVALID'] || `email is invalid`,
            checkEmail.reason,
          );
          return;
        }

        if (!checkPhone.success) {
          this.processInputError(
            'phone',
            this.translations['COMMON.PHONE_INVALID'] || `phone is invalid`,
            checkPhone.reason,
          );
          return;
        }

        if (!checkEmailWhiteList.success) {
          this.nextStep.emit(
            new RegisterCommand({
              email: this.email,
              phone: this.phone,
              userRegistrationFlow: UserRegistrationFlow.ByUniqueId,
            }),
          );
        }
      });
  }

  private nextByUniqueId(): void {
    forkJoin({
      checkEmail: this._authClient.checkUserByEmail(
        new CheckUserByEmailCommand({
          email: this.email,
        }),
      ),
      checkPhone: this._authClient.checkUserByPhone(
        new CheckUserByPhoneCommand({
          phone: this.phone,
        }),
      ),
    })
      .toPromise()
      .then(({ checkEmail, checkPhone }) => {
        if (checkEmail.success && checkPhone.success) {
          this.nextStep.emit(
            new RegisterCommand({
              email: this.email,
              phone: this.phone,
              userRegistrationFlow: UserRegistrationFlow.ByUniqueId,
            }),
          );
          return;
        }

        if (!checkEmail.success) {
          this.processInputError(
            'email',
            this.translations['COMMON.EMAIL_INVALID'] || `email is invalid`,
            checkEmail.reason,
          );
          return;
        }

        if (!checkPhone.success) {
          this.processInputError(
            'phone',
            this.translations['COMMON.PHONE_INVALID'] || `phone is invalid`,
            checkPhone.reason,
          );
          return;
        }
      });
  }

  private processInputError(
    inputName: string,
    inputErrorText: string,
    reason: string,
  ): void {
    const errors = {};
    errors[inputName] = inputErrorText;
    this.registerForm.get(inputName).setErrors(errors);
    this.appBusService.processValidationMessage(
      getSplNotification('info', reason),
    );
  }
}

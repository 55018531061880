import { Component, Input, OnChanges } from '@angular/core';
import {
  ChartGaugeConfigModel,
  SeriesModel,
} from '@common/co/shared/models/chart-config.model';
import * as _ from 'lodash';

@Component({
  selector: 'spl-chart-gauge',
  templateUrl: './spl-chart-gauge.component.html',
  styleUrls: ['./spl-chart-gauge.component.scss'],
})
export class SplChartGaugeComponent implements OnChanges {
  @Input() config: ChartGaugeConfigModel;
  @Input() series: SeriesModel[];
  @Input() invertSeries: boolean = false;
  public seriesForGauge: SeriesModel[];

  ngOnChanges(): void {
    if (this.invertSeries) {
      this.seriesForGauge = [];
      _.each(this.series, (item: SeriesModel) => {
        this.seriesForGauge.push({
          name: item.name,
          value: this.config.max - Number(item.value),
        });
      });
    } else {
      this.seriesForGauge = this.series;
    }
  }
}

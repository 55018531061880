import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { CustomDialogService } from '@common/shared/components/custom-dialog/custom-dialog.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'spl-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDialogComponent {
  public dialogActionsAlign: 'start' | 'center' | 'end' = 'end';
  @Output() result: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private customDialogService: CustomDialogService) {}

  public confirm(): void {
    this.result.emit(true);
  }

  public cancel(): void {
    this.result.emit(false);
  }

  public get cancellationTime(): Observable<number> {
    return this.customDialogService.cancellationTime;
  }

  public get title(): Observable<string> {
    return this.customDialogService.title;
  }

  public get description(): Observable<string> {
    return this.customDialogService.description;
  }

  public get confirmButtonText(): Observable<string> {
    return this.customDialogService.confirmButtonText;
  }

  public get cancelButtonText(): Observable<string> {
    return this.customDialogService.cancelButtonText;
  }

  public get showCancelButton(): boolean {
    return this.customDialogService.showCancelButton;
  }
}

import { Injectable } from '@angular/core';
import {
  ComponentOption,
  ComponentType,
  ExerciseResultDto,
  TrainingExerciseScoringJsonDataComponent,
} from '@common/services/co-api-client';
import {
  ComponentOptionCustom,
  ExerciseResultCustom,
  TrainingExerciseScoringJsonDataComponentCustom,
} from '../models/station-exercise-custom.model';

@Injectable()
export class StationExerciseResultsMapService {
  public getCustomComponents(
    components: TrainingExerciseScoringJsonDataComponent[],
  ): TrainingExerciseScoringJsonDataComponentCustom[] {
    return components.map((component) => {
      const customComponent =
        new TrainingExerciseScoringJsonDataComponentCustom(component);
      if (customComponent.targetField?.length > 0) {
        customComponent.targetField =
          customComponent.targetField[0].toLowerCase() +
          customComponent.targetField.slice(1);
      }
      if (customComponent.options) {
        customComponent.options = this.getCustomComponentOptions(
          component.component,
          component.options,
        );
      }
      return customComponent;
    });
  }

  public getCustomExerciseResults(
    results: ExerciseResultDto[],
    components: TrainingExerciseScoringJsonDataComponentCustom[],
  ): ExerciseResultCustom[] {
    return results.map((result) => {
      const customResult = new ExerciseResultCustom(result);
      components.forEach((component) => {
        if (component.component === ComponentType.ColorDropDown) {
          const foundOption = component.options.find((option) => {
            return customResult[component.targetField] === option.customValue;
          });
          if (foundOption) {
            customResult.code = foundOption.code;
            customResult.selectedOptionTitle = foundOption.title;
          }
        }
      });
      return customResult;
    });
  }

  private getCustomComponentOptions(
    component: ComponentType,
    options: ComponentOption[],
  ): ComponentOptionCustom[] {
    return options.map((option) => {
      const customOption = new ComponentOptionCustom(option);
      switch (component) {
        case ComponentType.ExerciseRowInput:
          customOption.customValue = +customOption.value;
          break;
        case ComponentType.DropDown:
          customOption.customValue = +customOption.value;
          break;
        case ComponentType.AutoCompleteDropDown:
          customOption.customValue = +customOption.value;
          break;
        case ComponentType.ColorDropDown:
          customOption.customValue = +customOption.value;
          break;
      }
      return customOption;
    });
  }
}

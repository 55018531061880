import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of, OperatorFunction } from 'rxjs';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { catchError, first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedUserGuard  {

  constructor(
    private authorize: AuthorizeService,
    private router: Router,
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authorize
      .isAuthenticated()
      .pipe(
        first(),
        this.handleAuthorization(),
        catchError((e: any) => {
          console.log(`Failed to obtain authentication state. Error: ${e}`);
          return of(false);
        }),
      );
  }

  private handleAuthorization(): OperatorFunction<boolean, boolean | UrlTree> {
    return map((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        return this.router.parseUrl('/')
      }
      return !isAuthenticated;
    });
  }
}

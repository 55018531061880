import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import {
  AuthClient,
  ResetPasswordCommand,
  VerifyPasswordTokenCommand,
} from '@common/services/co-api-client';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';
import { AppBusService } from '@common/co/core/services/app-bus.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public resetPasswordForm: FormGroup;
  public isPasswordReset: boolean = false;
  public displayBackButton: boolean = false;
  public onload: boolean;
  private code: string;
  private emailValue: string;
  private _unsubscribeAll: Subject<void>;
  private readonly emailValidators: Validators[] = [
    Validators.required,
    Validators.email,
  ];
  private readonly passwordValidators: Validators[] = [Validators.required];
  private readonly passwordConfirmValidators: Validators[] = [
    Validators.required,
    confirmPasswordValidator,
  ];

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _authClient: AuthClient,
    private appBusService: AppBusService,
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      email: ['', this.emailValidators],
      password: ['', this.passwordValidators],
      passwordConfirm: ['', this.passwordConfirmValidators],
    });

    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    this.password.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        this.passwordConfirm.updateValueAndValidity.bind(this.passwordConfirm),
      );
    this.code = atob(this._route.snapshot.queryParamMap.get('code'));
    this.emailValue = this._route.snapshot.queryParamMap.get('email');
    if (this.emailValue) {
      this.email.setValue(this.emailValue);
      this.email.disable({ onlySelf: true });
      this.checkCode();
    }
  }

  private checkCode(): void {
    const verifyPasswordTokenCommand = new VerifyPasswordTokenCommand({
      passwordToken: this.code,
      email: this.email.value,
    });
    this.onload = true;
    this._authClient.verifyPasswordToken(verifyPasswordTokenCommand).subscribe(
      (res) => {
        if (res.success === false) {
          this.displayBackButton = true;
        }
        this.onload = false;
      },
      (error) => {
        this.onload = false;
        throw error;
      },
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async onReset(): Promise<void> {
    try {
      const response = await this._authClient
        .resetPassword(
          new ResetPasswordCommand({
            email: this.email.value,
            password: this.password.value,
            confirmPassword: this.passwordConfirm.value,
            code: this.code,
          }),
        )
        .toPromise();
      if (response.success) {
        this.isPasswordReset = true;
      } else {
        this.appBusService.processValidationMessage(
          getSplNotification('info', response?.reason),
        );
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public get email(): AbstractControl | null {
    return this.resetPasswordForm.get('email');
  }

  public get password(): AbstractControl | null {
    return this.resetPasswordForm.get('password');
  }

  public get passwordConfirm(): AbstractControl | null {
    return this.resetPasswordForm.get('passwordConfirm');
  }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};

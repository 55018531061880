import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
} from '@angular/router';
import { filter} from 'rxjs/operators';
import * as _ from 'lodash';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { SplNavigationService } from '@common/co/core/components/navigation/navigation.service';

@Injectable()
export class RouteInterceptorService {
  private iterationLimit: number = 20;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _appBusService: AppBusService,
    private splNavigationService: SplNavigationService
  ) {}

  public initialize(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const currentRoute = this.getCurrentRoute();
        this.splNavigationService.setURLs(event, currentRoute);
        this.updateRouteInfo(currentRoute);
      });
  }

  private updateRouteInfo(currentRoute: ActivatedRoute): void {
    if (_.get(currentRoute, 'outlet') === 'primary') {
      const data: any = _.get(currentRoute, 'snapshot.data') || null;
      this._appBusService.changeRouteInfo(data);
    }
  }
  
  private getCurrentRoute(): ActivatedRoute {
    let currentRoute: ActivatedRoute = this.activatedRoute;
    let whileCount = 0;
    while (currentRoute.firstChild) {
      if (whileCount < this.iterationLimit) {
        currentRoute = currentRoute.firstChild;
        whileCount++;
      } else {
        console.error('While getCurrentRoute exceeded the iteration limit');
        break;
      }
    }
    return currentRoute;
  }
}
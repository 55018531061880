import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizeService } from './services/authorize.service';
import { AuthorizeInterceptor } from './authorize.interceptor';
import { AuthRoutes } from './auth.routes';
import { LoginComponent } from './components/login/login.component';
import { SplCoreModule } from '@common/co/core/core.module';
import { AngularRequestor } from './utils/angular-requestor';
import { FuseSharedModule } from '@fuse/shared.module';
import { FlexModule } from '@angular/flex-layout';
import { RegisterComponent } from './components/register/register.component';
import { DefaultComponent } from './components/register/components/default/default.component';
import { PersonalInfoComponent } from './components/register/components/personal-info/personal-info.component';
import { PasswordComponent } from './components/register/components/password/password.component';
import { NgxMaskModule } from 'ngx-mask';
import { RegisterCompleteComponent } from './components/register/components/complete/register-complete.component';
import { WaiverOfLiabilityComponent } from './components/register/components/waiver-of-liability/waiver-of-liability.component';
import { SelectProfileComponent } from './components/login/components/select-profile/select-profile.component';
import { RouterModule } from '@angular/router';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { InTakeService } from './services/in-take.service';
import { ExternalLoginComponent } from './components/external-login/external-login.component';
import { ChangePasswordComponent } from '@common/co/auth/components/register/components/change-password/change-password.component';
import { ChangeProfileDialogComponent } from '@common/co/auth/components/change-profile-dialog/change-profile-dialog.component';
import { PasswordInputModule } from '@common/components/password-input';
import { ForgotPasswordComponent } from '@common/co/auth/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@common/co/auth/components/reset-password/reset-password.component';
import { UniqueIdInfoComponent } from '@common/co/auth/components/register/components/unique-id-info/unique-id-info.component';
import { CheckUniqueIdComponent } from '@common/co/auth/components/login/components/check-unique-id/check-unique-id.component';
import { UserRegistrationService } from '@common/co/auth/services/user-registration.service';
import { DirectivesModule } from '@common/shared/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    DefaultComponent,
    UniqueIdInfoComponent,
    PersonalInfoComponent,
    PasswordComponent,
    RegisterCompleteComponent,
    WaiverOfLiabilityComponent,
    SelectProfileComponent,
    CheckUniqueIdComponent,
    ExternalLoginComponent,
    ChangePasswordComponent,
    ChangeProfileDialogComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    AuthRoutes,
    SplCoreModule,
    HttpClientModule,
    FuseSharedModule,
    FlexModule,
    NgxMaskModule.forChild(),
    MatRadioModule,
    PasswordInputModule,
    DirectivesModule,
    TranslateModule,
  ],
  exports: [RouterModule],
  providers: [
    AngularRequestor,
    AuthorizeService,
    InTakeService,
    UserRegistrationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
  ],
})
export class AuthModule {}

<div class="events-info-panel-wrapper">
  <spl-skeleton [showSkeleton]="eventsCountResponsePromise" [mode]="skeletonModeType.ByContent" [type]="skeletonType.InfoBlock">
    <info-block [title]="'EVENTS_INFO.TOTAL_TESTS' | translate" 
    [value]="totalCount">
    </info-block>
  </spl-skeleton>
  <spl-skeleton [showSkeleton]="eventsCountResponsePromise" [mode]="skeletonModeType.ByContent" [type]="skeletonType.InfoBlock">
    <ng-template [ngIf]="!isTrainingClassAvailable">
      <info-block
        [title]="(applicationDictionaryService.Training | translate | titlecase) +' '+ ('EVENTS_INFO.SESSIONS' + translate)+ '<br/>'+('EVENTS_INFO.UNTIL_NEXT_TEST' | translate)"
        [value]="trainingSessionsCount">
      </info-block>
    </ng-template>
    <ng-template [ngIf]="isTrainingClassAvailable">
      <info-block
        [title]="'EVENTS_INFO.LAST_TEST_DATE' | translate"
        [value]="dateOfLastTest ? (dateOfLastTest | date: dateFormat) : 'N/A'">
      </info-block>
    </ng-template>
  </spl-skeleton>
</div>
